const POSLUGI = [
  'Консультацію',
  'Зустріч',
  'Укладення договору',
  'Дистанційну консультацію',
  'Підписання документів',
  'Зачинено',
  'Інформаційний центр',
  'Співбесіду',
  'Відео консультацію',
];

const POSLUGI_FOR_EADVOKAT = [
  {
    ua: 'Консультацію',
    ru: 'Консультацию',
    en: 'Consultation',
    de: 'Beratung',
    fr: 'Consultation',
    es: 'Consulta',
    cz: 'Konzultace',
  },
  {
    ua: 'Зустріч',
    ru: 'Встреча',
    en: 'Meeting',
    de: 'Treffen',
    fr: 'Rencontre',
    es: 'Reunión',
    cz: 'Schůzka',
  },
  {
    ua: 'Укладення договору',
    ru: 'Заключение договора',
    en: 'Contract signing',
    de: 'Vertragsabschluss',
    fr: 'Signature du contrat',
    es: 'Firma de contrato',
    cz: 'Uzavření smlouvy',
  },
  {
    ua: 'Дистанційна консультація',
    ru: 'Дистанционная консультация',
    en: 'Remote consultation',
    de: 'Fernberatung',
    fr: 'Consultation à distance',
    es: 'Consulta remota',
    cz: 'Dálková konzultace',
  },
  {
    ua: 'Підписання документів',
    ru: 'Подписание документов',
    en: 'Document signing',
    de: 'Dokumentenunterzeichnung',
    fr: 'Signature de documents',
    es: 'Firma de documentos',
    cz: 'Podpis dokumentů',
  },
  {
    ua: 'Зачинено',
    ru: 'Закрыто',
    en: 'Closed',
    de: 'Geschlossen',
    fr: 'Fermé',
    es: 'Cerrado',
    cz: 'Zavřeno',
  },
  {
    ua: 'Інформаційний центр',
    ru: 'Информационный центр',
    en: 'Information center',
    de: 'Informationszentrum',
    fr: "Centre d'information",
    es: 'Centro de información',
    cz: 'Informační centrum',
  },
  {
    ua: 'Співбесіду',
    ru: 'Собеседование',
    en: 'Interview',
    de: 'Vorstellungsgespräch',
    fr: 'Entretien',
    es: 'Entrevista',
    cz: 'Pohovor',
  },
  {
    ua: 'Відео консультація',
    ru: 'Видео консультация',
    en: 'Video consultation',
    de: 'Videoberatung',
    fr: 'Consultation vidéo',
    es: 'Consulta en vídeo',
    cz: 'Videokonzultace',
  },
];

const POSLUGI_NOMINATIVE_CASE = [
  'Консультація',
  'Зустріч',
  'Укладення договору',
  'Дистанційна консультація',
  'Підписання документів',
  'Зачинено',
  'Інформаційний центр',
  'Співбесіда',
  'Відео консультація',
];

const POSLUGI_NOMINATIVE_CASE_MOBILE_SIZE = [
  'Консультація',
  'Зустріч',
  'Укл. договору',
  'Дист. консультація',
  'Підп. документів',
  'Зачинено',
  'Інфор. центр',
  'Співбесіда',
  'Відео консультація',
];

const CONSULTATION_GROUP = ['0', '3', '6', '8'];
const PAID_CONSULTATION_GROUP = ['0', '3', '8'];

const POSLUGI_NOMINATIVE_CASE_REACH_FILTER = {
  0: 'Консультація',
  3: 'Дистанційна консультація',
};

const POSLUGI_TIME = [4, 2, 2, 3, 1, 1, 1, 1, 4];

const SERVICES_FOR_CLINETS_APP = [
  {
    index: 0,
    label: {
      ua: 'Отримати консультацію в офісі',
      ru: 'Получить консультацию в офисе',
      en: 'Get a consultation at the office',
      de: 'Beratung im Büro erhalten',
      fr: 'Obtenir une consultation au bureau',
      es: 'Obtener una consulta en la oficina',
      cz: 'Získat konzultaci v kanceláři',
    },
  },
  {
    index: 3,
    label: {
      ua: 'Отримати консультацію по телефону',
      ru: 'Получить консультацию по телефону',
      en: 'Get a consultation by phone',
      de: 'Beratung am Telefon erhalten',
      fr: 'Obtenir une consultation par téléphone',
      es: 'Obtener una consulta por teléfono',
      cz: 'Získat konzultaci po telefonu',
    },
  },
  {
    index: 2,
    label: {
      ua: 'Укласти договір',
      ru: 'Заключить договор',
      en: 'Sign a contract',
      de: 'Vertrag unterschreiben',
      fr: 'Signer un contrat',
      es: 'Firmar un contrato',
      cz: 'Uzavřít smlouvu',
    },
  },
  {
    index: 4,
    label: {
      ua: 'Оформлення чи підписати документи',
      ru: 'Оформить или подписать документы',
      en: 'Process or sign documents',
      de: 'Dokumente bearbeiten oder unterschreiben',
      fr: 'Traiter ou signer des documents',
      es: 'Procesar o firmar documentos',
      cz: 'Zpracovat nebo podepsat dokumenty',
    },
  },
  {
    index: 1,
    label: {
      ua: 'Зустрітися з юристом по справі',
      ru: 'Встретиться с юристом по делу',
      en: 'Meet with a lawyer regarding the case',
      de: 'Treffen Sie sich mit einem Anwalt zum Fall',
      fr: "Rencontrer un avocat concernant l'affaire",
      es: 'Reunirse con un abogado sobre el caso',
      cz: 'Setkat se s právníkem ohledně případu',
    },
  },
];

const DATE_OPTIONS = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: false,
};

const ROLES = {
  admin: 0,
  topInspector: 3,
  inspector: 4,
  manager: 7,
  deputyManager: 8,
  blockManager: 8.5,
  rabfil: 9,
  worker: 10,
  psychologist: 11,
};

const LOG_TYPE = {
  REQ: 'req',
  RES: 'res',
  DB: 'db',
  INFO: 'info',
};

const LOG_EVENT_TYPE = {
  SERVER: 'server',
  USER: 'user',
};

const DEFAULT_USER = {
  password: 'pass',
  role: 'admin',
  username: 'email@gmail.com',
  fil: '12',
  name: 'adminUser',
  p: 'sh',
};

const PAY_PURPOSES = {
  1: {
    label: 'Перша оплата при ЗД',
    value: 1,
  },
  2: {
    label: 'Оплата по договору',
    value: 2,
  },
  3: {
    label: 'Судовий збір',
    value: 3,
  },
  4: {
    label: 'Вихід адвоката',
    value: 4,
  },
  5: {
    label: 'Консультація та подяки',
    value: 5,
  },
  6: {
    label: 'Оплата за декілька договорів',
    value: 6,
  },
  7: {
    label: 'Оплата за тривожну кнопку',
    value: 7,
  },
  8: {
    label: 'Оплата за страховку',
    value: 8,
  },
  9: {
    label: 'Оплата клубної карти',
    value: 9,
  },
  10: {
    label: 'Оплата за пошук у реєстрах',
    value: 10,
  },
  11: {
    label: 'Оплата за вебінар',
    value: 11,
  },
  50: {
    label: 'Невідомо',
    value: 50,
  },
  51: {
    label: 'Поповнення балансу',
    value: 51,
  },
  52: {
    label: 'Використання бонусів',
    value: 52,
  },
  53: {
    label: 'Кешбек за автооплату',
    value: 53,
  },
  54: {
    label: 'Бронювання гонорару',
    value: 54,
  },
  other: {
    label: 'Інше',
    value: 'other',
  },
};

const PAY_PURPOSES_FOR_EADVOKAT = {
  1: {
    translations: {
      ua: 'Перша оплата при ЗД',
      ru: 'Первый платеж при ЗД',
      en: 'First payment for service',
      de: 'Erste Zahlung bei Service',
      fr: 'Premier paiement pour le service',
      es: 'Primer pago para el servicio',
      cz: 'První platba za službu',
    },
    value: 1,
  },
  2: {
    translations: {
      ua: 'Оплата по договору',
      ru: 'Оплата по договору',
      en: 'Contract payment',
      de: 'Vertragszahlung',
      fr: 'Paiement du contrat',
      es: 'Pago por contrato',
      cz: 'Platba za smlouvu',
    },
    value: 2,
  },
  3: {
    translations: {
      ua: 'Судовий збір',
      ru: 'Судебный сбор',
      en: 'Court fee',
      de: 'Gerichtsgebühr',
      fr: 'Frais de justice',
      es: 'Tasa judicial',
      cz: 'Soudní poplatek',
    },
    value: 3,
  },
  4: {
    translations: {
      ua: 'Вихід адвоката',
      ru: 'Выход адвоката',
      en: 'Lawyer appearance',
      de: 'Anwaltstermin',
      fr: "Apparition de l'avocat",
      es: 'Aparición del abogado',
      cz: 'Vystoupení právníka',
    },
    value: 4,
  },
  5: {
    translations: {
      ua: 'Консультація та подяки',
      ru: 'Консультация и благодарности',
      en: 'Consultation and gratitude',
      de: 'Beratung und Dank',
      fr: 'Consultation et remerciements',
      es: 'Consulta y agradecimientos',
      cz: 'Konzultace a poděkování',
    },
    value: 5,
  },
  6: {
    translations: {
      ua: 'Оплата за декілька договорів',
      ru: 'Оплата за несколько договоров',
      en: 'Payment for multiple contracts',
      de: 'Zahlung für mehrere Verträge',
      fr: 'Paiement pour plusieurs contrats',
      es: 'Pago por varios contratos',
      cz: 'Platba za několik smluv',
    },
    value: 6,
  },
  7: {
    translations: {
      ua: 'Оплата за тривожну кнопку',
      ru: 'Оплата за тревожную кнопку',
      en: 'Payment for emergency button',
      de: 'Zahlung für Notfallknopf',
      fr: "Paiement pour le bouton d'urgence",
      es: 'Pago por botón de emergencia',
      cz: 'Platba za tísňové tlačítko',
    },
    value: 7,
  },
  8: {
    translations: {
      ua: 'Оплата за страховку',
      ru: 'Оплата за страховку',
      en: 'Insurance payment',
      de: 'Versicherungszahlung',
      fr: "Paiement de l'assurance",
      es: 'Pago del seguro',
      cz: 'Platba za pojištění',
    },
    value: 8,
  },
  9: {
    translations: {
      ua: 'Оплата клубної карти',
      ru: 'Оплата клубной карты',
      en: 'Membership card payment',
      de: 'Mitgliedskarte Zahlung',
      fr: 'Paiement de la carte de membre',
      es: 'Pago de la tarjeta de membresía',
      cz: 'Platba za členskou kartu',
    },
    value: 9,
  },
  10: {
    translations: {
      ua: 'Оплата за пошук у реєстрах',
      ru: 'Оплата за поиск в реестрах',
      en: 'Payment for registry search',
      de: 'Zahlung für Registersuchen',
      fr: 'Paiement pour la recherche dans les registres',
      es: 'Pago por búsqueda en registros',
      cz: 'Platba za hledání v registrech',
    },
    value: 10,
  },
  11: {
    translations: {
      ua: 'Оплата за вебінар',
      ru: 'Оплата за вебинар',
      en: 'Payment for webinar',
      de: 'Zahlung für Webinar',
      fr: 'Paiement pour le webinaire',
      es: 'Pago por el seminario web',
      cz: 'Platba za webinář',
    },
    value: 11,
  },
  50: {
    translations: {
      ua: 'Невідомо',
      ru: 'Неизвестно',
      en: 'Unknown',
      de: 'Unbekannt',
      fr: 'Inconnu',
      es: 'Desconocido',
      cz: 'Neznámý',
    },
    value: 50,
  },
  51: {
    translations: {
      ua: 'Поповнення балансу',
      ru: 'Пополнение баланса',
      en: 'Balance top-up',
      de: 'Saldo aufladen',
      fr: 'Rechargement de solde',
      es: 'Recarga de saldo',
      cz: 'Doplnění zůstatku',
    },
    value: 51,
  },
  52: {
    translations: {
      ua: 'Використання бонусів',
      ru: 'Использование бонусов',
      en: 'Bonus usage',
      de: 'Bonuseinsatz',
      fr: 'Utilisation des bonus',
      es: 'Uso de bonos',
      cz: 'Použití bonusů',
    },
    value: 52,
  },
  53: {
    translations: {
      ua: 'Кешбек за автооплату',
      ru: 'Кешбек за автооплату',
      en: 'Cashback for auto payment',
      de: 'Cashback für automatische Zahlung',
      fr: 'Cashback pour le paiement automatique',
      es: 'Cashback por pago automático',
      cz: 'Cashback za automatickou platbu',
    },
    value: 53,
  },
  other: {
    translations: {
      ua: 'Інше',
      ru: 'Другое',
      en: 'Other',
      de: 'Andere',
      fr: 'Autre',
      es: 'Otro',
      cz: 'Jiné',
    },
    value: 'other',
  },
};

const NOT_INCLUDED_IN_PAYMENT_MANAGEMENT = ['51', '52', '53'];

const PAY_TYPES = {
  1: {
    label: 'Готівка',
    value: 1,
  },
  2: {
    label: 'Карта філії',
    value: 2,
  },
  18: {
    label: 'ФОП філії',
    value: 18,
  },
  3: {
    label: 'Р/р АО 4531',
    value: 3,
  },
  4: {
    label: 'Р/р ТОВ 6922',
    value: 4,
  },
  19: {
    label: 'ФОП ЛВА',
    value: 19,
  },
  20: {
    label: 'ФОП ЮЮ',
    value: 20,
  },
  21: {
    label: 'ФОП КЮВ',
    value: 21,
  },
  22: {
    label: 'ФОП ЕА',
    value: 22,
  },
  23: {
    label: 'ФОП КАА',
    value: 23,
  },
  25: {
    label: 'ФОП НВМ',
    value: 25,
  },
  5: {
    label: 'Карта АВ Моно 7082',
    value: 5,
  },
  6: {
    label: 'Карта АВ Альфа 7173',
    value: 6,
  },
  7: {
    label: 'Карта АВ Приват 0336',
    value: 7,
  },
  8: {
    label: 'Карта ЗЯ Моно 0169',
    value: 8,
  },
  9: {
    label: 'Карта НЯ Приват 8111',
    value: 9,
  },
  10: {
    label: 'Карта ЛВА Моно 3124',
    value: 10,
  },
  11: {
    label: 'Карта ЮЮ Моно 4527',
    value: 11,
  },
  12: {
    label: 'Карта ЮЮ Приват 9634',
    value: 12,
  },
  13: {
    label: 'Карта АЧА Приват 5528',
    value: 13,
  },
  14: {
    label: 'Карта АЧА Моно 7963',
    value: 14,
  },
  15: {
    label: 'Карта ЛВА Приват 0256',
    value: 15,
  },
  16: {
    label: 'Карта АА Приват 6209',
    value: 16,
  },
  17: {
    label: 'Карта ЛАВ Приват 3433',
    value: 17,
  },
  26: {
    label: 'р/р Берлін',
    value: 26,
  },
  50: {
    label: 'Невідомо',
    value: 50,
  },
  51: {
    label: 'Фонді',
    value: 51,
  },
  52: {
    label: 'Фонді 2',
    value: 52,
  },
  other: {
    label: 'Інше',
    value: 'other',
  },
};

const FONDY_PAY_TYPE = '52';

const FONDY_PAY_FOR_TO_COUNT = ['5', '10', '51'];

const BILL_STATUSES = {
  1: {
    label: 'Є',
    value: 1,
  },
  2: {
    label: 'Немає',
    value: 2,
  },
  3: {
    label: 'Готівка',
    value: 3,
  },
  other: {
    label: 'Інше',
    value: 'other',
  },
};

const USER_ACTIONS = {
  ADDED_NEW_CONTRACT: 'Додав новий договір',
  RENEWED_THE_CONTRACT: 'Оновив договір',
  SET_THE_NUMBER_OF_OFFICES: 'Встановив кількість кабінетів',
  SENT_AN_SMS_TO_EVERYONE: 'Розіслав усім СМС',
  ADDED_A_NEW_HOTLINE: 'Додав новий запис',
  CANCELED_THE_LAUNCH_OF_PROJECT_3000: 'Скасував запуск проєкту 3000',
  RESTARTED_PROJECT_3000: 'Відновив запуск проєкту 3000',
  REPORTS_THE_MEETING: 'Звітує про зустріч',
  OFFERED_A_HACKWORK: 'Запропонував халтуру',
  CANCELED_A_HACKWORK: 'Скасував халтуру',
  TOOK_A_HACKWORK: 'Взяв халтуру',
  USED_THE_CERTIFICATE: 'Використав сертифікат',
  DELETED_THE_ENTRY: 'Видалив запис',
  DELETED_THE_REACH: 'Видалив дожим',
  RAISED_THE_LEVEL_OF_HELLISHNESS: 'Оновив рівень пекельності дожиму',
  UPDATED_HELLISHNESS_WAKE_UP_ALARM: 'Оновив пекельний будильник дожиму',
  FINANCIAL_OPERATION: 'Провів фінансову операцію',
  ADD_NEW_SUGAR: 'Додав новий сахарок',
  ADD_NEW_ASSIGNMENT: 'Додав новий вихід',
  ADD_NEW_EXPENSE: 'Додав нову витрату',
  EDIT_SUGAR: 'Редагував сахарок',
  EDIT_EXPENSE: 'Редагував витрату',
  EDIT_ASSIGNMENT: 'Редагував вихід',
  SEARCH_SUGAR: 'Пошук по сахаркам',
  SEARCH_EXPENSE: 'Пошук по витратам',
  SEARCH_ASSIGNMENT: 'Пошук по виходам',
  SEARCH_BY_CLIENTS: 'Пошук по клієнтам',
  GOT_ACCESS_FROM_CLIENT: 'Отримав доступ до договору від клієнта',
  GOT_ACCESS_FROM_ADMINBLOCK: 'Отримав доступ до договору від адмінблока',
};

const TITLE_DB_FOR_CAPS_LOCK = {
  CONTRACTS: ['CONTRACTS', 'contract', 'Contract'],
  HOTLINE: ['HOTLINE', 'hotline', 'Hotline'],
  CONFLICTS: ['CONFLICTS', 'conflict', 'Conflict'],
};

const NAME_ZONE_FILES = {
  CHAT_IN_CONTRACT: 'ChatInContract',
  CORRESPONDENCE_UPLOADED_FILE: 'CorrespondenceUploadedFile',
};

const PHONE_OFFICE_VIEW_CRM_SHORT = '+380800403303';
const PHONE_OFFICE_VIEW_CRM = `tel:${PHONE_OFFICE_VIEW_CRM_SHORT}`;
const PHONE_OFFICE_VIEW_CLIENT = '0800-40-33-03';
const PHONE_OFFICE_VIEW_CLIENT_SHORT = '0800403303';
const FILIAL_START_BALANCE_DATE = new Date('2024-03-01T00:00:00Z');

const TELEGRAM_CHAT_OFFICE_VIEW_CRM = 'https://t.me/Advocat_association_Zahist';
const VIBER_CHAT_OFFICE_VIEW_CRM = 'viber://chat?number=%2B380936042474';

const REVIEWS_OF_THE_BUSINESS_CARD_SITE = 'surl.li/jakdo';

const BASIC_YOUTUBE_TO_REACH = 'https://www.youtube.com/watch?v=cE8BuDMYgvY';

const TEXT_VSMS_TO_REACH_HOTLINE = [
  {
    label: 'Військові',
    value: 0,
    exposition: `Адвокатське об’єднання «Захист» - найбільша юридична компанія України.\nМи спеціалізуємося на військових справах.\n\nПослуги:\n\n- Консультації;\n- Підготовка документів;\n- Захист у кримінальних справах;\n- Оскарження рішення ВЛК;\n- Оформлення статусу УБД;\n- Оскарження постанов та протоколів;\n- Зміна статусу зниклого, загиблого;\n- Оскарження висновків СР;\n- Оформлення виплат та компенсацій;\n- Звільнення з військової служби;\n- Інші послуги.\n\nНомер гарячої лінії:\n${PHONE_OFFICE_VIEW_CLIENT}\n\nhttps://www.youtube.com/watch?v=i8RyYBsv2Lc`,
  },
  {
    label: 'Мікрокредитні',
    value: 1,
    exposition: `Адвокатське об’єднання «Захист» - найбільша юридична компанія України.\nМи спеціалізуємося на мікрокредитних справах.\n\nПослуги:\n\n- Консультації;\n- Розірвання договорів мікропозик;\n- Списання заборгованості по кредиту;\n- Закриття позики по тілу боргу;\n- Припинення колекторського тиску;\n- Зняття арешту з картки та ;\n- Закриття виконавчого провадження;\n- Видалення з реєстру боржників;\n- Ведення судових процесів з МФО;\n- Інших послуги.\n\nНомер гарячої лінії:\n${PHONE_OFFICE_VIEW_CLIENT}\n\nhttps://www.youtube.com/watch?v=pq5YPg5TWug`,
  },
  {
    label: 'Пенсійних',
    value: 2,
    exposition: `Адвокатське об’єднання «Захист» - найбільша юридична компанія України.\nМи спеціалізуємося на пенсійних справах.\n\nПослуги:\n\n- Консультації;\n- Представництво інтересів Клієнта;\n- Оформлення пенсії;\n- Перерахунок пенсії;\n- Перехід на інший вид пенсії;\n- Підтвердження пенсійного стажу;\n- Виправлення неточності у документах\n- Перерахунок пенсії ЗСУ, МВС;\n- Ведення судових процесів;\n- Інші юридичні послуги.\n\nНомер гарячої лінії:\n${PHONE_OFFICE_VIEW_CLIENT}\n\nhttps://www.youtube.com/watch?v=_hU690qPPcw`,
  },
  {
    label: 'Сімейні',
    value: 3,
    exposition: `Адвокатське об’єднання «Захист» - найбільша юридична компанія України.\nМи спеціалізуємося на сімейних справах.\n\nПослуги:\n\n- Консультації;\n- Розірвання шлюбу;\n- Стягнення аліментів;\n- Поділ майна подружжя;\n- Ведення судових процесів;\n- Визначення місце проживання дитини;\n- Примусове відібрання дитини;\n- Повернення дитини з-за кордону;\n- Збільшення або зменшення аліментів;\n- Встановлення батьківства;\n- Оформлення опіки;\n- Усиновлення;\n- Інші юридичні послуги.\n\nНомер гарячої лінії:\n${PHONE_OFFICE_VIEW_CLIENT}\n\nhttps://www.youtube.com/watch?v=DNS40VMzsOo`,
  },
  {
    label: 'ДТП',
    value: 4,
    exposition: `Адвокатське об’єднання «Захист» - найбільша юридична компанія України.\nМи спеціалізуємося на дорожньо-транспортних пригодах.\n\nПослуги:\n\n- Консультації;\n- Підготовка документів;\n- Мирне врегулювання справи по ДТП;\n- Доведення невинуватості Клієнта;\n- Ведення адміністративних справ;\n- Ведення кримінальних справ;\n- Ведення цивільних справ;\n- Оскарження протоколу по ст. 130 КУпАП;\n- Стягнення шкоди внаслідок ДТП;\n- Інші юридичні послуги.\n\nНомер гарячої лінії:\n${PHONE_OFFICE_VIEW_CLIENT}\n\nhttps://www.youtube.com/watch?v=xAWn7sd_5GA`,
  },
  {
    label: 'Кредити (не АНК)',
    value: 5,
    exposition: `Адвокатське об’єднання «Захист» - найбільша юридична компанія України.\nМи спеціалізуємося на кредитних справах.\n\nПослуги:\n\n- Консультації;\n- Ведення судових процесів;\n- Скасування відсотків та пені;\n- Замороження кредиту;\n- Застосування форс-мажору;\n- Визнання договору кредиту недійсним;\n- Вирішення проблем з колекторами;\n- Зняття арешту з картки та майна;\n- Закриття виконавчого провадження;\n- Інші юридичні послуги.\n\nНомер гарячої лінії:\n${PHONE_OFFICE_VIEW_CLIENT}\n\nhttps://www.youtube.com/watch?v=t9naiQgBpkc`,
  },
  {
    label: 'Кримінальні',
    value: 6,
    exposition: `Адвокатське об’єднання «Захист» - найбільша юридична компанія України.\nМи спеціалізуємося на кримінальних справах.\n\nПослуги:\n\n- Надання консультацій;\n- Захист підозрюваного, обвинуваченого;\n- Представництво інтересів потерпілого;\n- Стягнення шкоди завданої злочином;\n- Формування правової позиції у справі;\n- Підготовка процесуальних документів;\n- Супровід примирення сторін;\n- Захист в ході досудового розслідування;\n- Оскарження дій правоохоронців;\n- Оскарження запобіжного заходу;\n- Спростування доказів вини Клієнта;\n- Збір доказів невинуватості Клієнта;\n- Захист Клієнта у судовому процесі;\n- Доведення пом’якшуючих обставин;\n- Вихід на мінімально можливу санкцію;\n- Апеляційне оскарження рішення;\n- Касаційне оскарження рішення;\n- Направлення скарги до ЄСПЛ;\n- Оформлення та супровід УДЗ;\n- Оформлення амністії та помилування;\n- Інші юридичні послуги.\n\nНомер гарячої лінії:\n${PHONE_OFFICE_VIEW_CLIENT}\n\nhttps://www.youtube.com/watch?v=FIkx81YcgcY`,
  },
  {
    label: 'Адміністративні',
    value: 7,
    exposition: `Адвокатське об’єднання «Захист» - найбільша юридична компанія України.\nМи спеціалізуємося на адміністративних справах.\n\nПослуги:\n\n- Консультації;\n- Оскарження дій посадових осіб;\n- Підготовка позовної заяви;\n- Ведення адміністративних процесів;\n- Апеляційне та касаційне оскарження;\n- Оскарження рішень податкових органів;\n- Введення в експлуатацію нерухомості;\n- Оскарження адмін. протоколів;\n- Інші юридичні послуги.\n\nНомер гарячої лінії:\n${PHONE_OFFICE_VIEW_CLIENT}\n\n${BASIC_YOUTUBE_TO_REACH}`,
  },
  {
    label: 'Цивільні',
    value: 8,
    exposition: `Адвокатське об’єднання «Захист» - найбільша юридична компанія України.\nМи спеціалізуємося на цивільних справах.\n\nПослуги:\n\n- Консультації;\n- Представництво інтересів;\n- Підготовка позовної заяви;\n- Ведення судового процесу;\n- Встановлення юридичного факту;\n- Стягнення боргу;\n- Оформлення права власності;\n- Спадкові та житлові справи;\n- Захист прав споживача;\n- Авторське та міграційне право;\n- Супровід виконавчого провадження;\n- Інші юридичні послуги.\n\nНомер гарячої лінії:\n${PHONE_OFFICE_VIEW_CLIENT}\n\n${BASIC_YOUTUBE_TO_REACH}`,
  },
  {
    label: 'Господарські',
    value: 9,
    exposition: `Адвокатське об’єднання «Захист» - найбільша юридична компанія України.\nМи спеціалізуємося на господарських справах.\n\nПослуги:\n\n- Консультації;\n- Представництво інтересів;\n- Досудове врегулювання спору;\n- Підготовка позовної заяви;\n- Ведення господарських судових справ;\n- Юридичний аутсорсинг СПД;\n- Стягнення боргів;\n- Оформлення дозвільних документів;\n- Податкові та бухгалтерські обліки;\n- Супровід процедури банкрутства;\n- Інші юридичні послуги.\n\nНомер гарячої лінії:\n${PHONE_OFFICE_VIEW_CLIENT}\n\n${BASIC_YOUTUBE_TO_REACH}`,
  },
  {
    label: 'Виконавчі',
    value: 10,
    exposition: `Адвокатське об’єднання «Захист» - найбільша юридична компанія України.\nМи спеціалізуємося на супроводі виконавчих проваджень.\n\nПослуги:\n\n- Консультації;\n- Представництво інтересів Клієнта;\n- Підготовка процесуальних документів;\n- Мирне врегулювання справи;\n- Закриття виконавчого провадження;\n- Зняття арешту з майна та рахунків;\n- Оскарження дій виконавця;\n- Зняття заборони виїзду за межі країни;\n- Оскарження рішення суду;\n- Активізація чи замороження справи;\n- Оскарження виконавчого напису;\n- Ведення судових процесів;\n- Інші юридичні послуги.\n\nНомер гарячої лінії:\n${PHONE_OFFICE_VIEW_CLIENT}\n\nhttps://www.youtube.com/watch?v=L19RROOOXhM`,
  },
  {
    label: 'Земельні',
    value: 11,
    exposition: `Адвокатське об’єднання «Захист» - найбільша юридична компанія України.\nМи спеціалізуємося на земельних справах.\n\nПослуги:\n\n- Консультації;\n- Представництво інтересів;\n- Оформлення права на землю;\n- Досудове вирішення спору;\n- Оскарження відмови у наданні землі;\n- Зняття та постановка ділянки на ДКО;\n- Визначення порядку землекористування;\n- Розірвання договору оренди землі;\n- Реалізація права на отримання землі;\n- Ведення земельних судових процесів;\n- Інші юридичні послуги.\n\nНомер гарячої лінії:\n${PHONE_OFFICE_VIEW_CLIENT}\n\nhttps://www.youtube.com/watch?v=ZN-phXG9vgk`,
  },
];

const PSYCHOLOGIST_WORK_TIME = [
  '09:00',
  '09:15',
  '09:30',
  '09:45',
  '10:00',
  '10:15',
  '10:30',
  '10:45',
  '11:00',
  '11:15',
  '11:30',
  '11:45',
  '12:00',
  '12:15',
  '12:30',
  '12:45',
  '13:00',
  '13:15',
  '13:30',
  '13:45',
  '14:00',
  '14:15',
  '14:30',
  '14:45',
  '15:00',
  '15:15',
  '15:30',
  '15:45',
  '16:00',
  '16:15',
  '16:30',
  '16:45',
  '17:00',
  '17:15',
  '17:30',
  '17:45',
  '18:00',
  '18:15',
  '18:30',
  '18:45',
  '19:00',
  '19:15',
  '19:30',
  '19:45',
  '20:00',
  '20:15',
  '20:30',
  '20:45',
  '21:00',
  '21:15',
  '21:30',
  '21:45',
  '22:00',
];
const WORK_TIME = [
  '08:00',
  '08:15',
  '08:30',
  '08:45',
  '09:00',
  '09:15',
  '09:30',
  '09:45',
  '10:00',
  '10:15',
  '10:30',
  '10:45',
  '11:00',
  '11:15',
  '11:30',
  '11:45',
  '12:00',
  '12:15',
  '12:30',
  '12:45',
  '13:00',
  '13:15',
  '13:30',
  '13:45',
  '14:00',
  '14:15',
  '14:30',
  '14:45',
  '15:00',
  '15:15',
  '15:30',
  '15:45',
  '16:00',
  '16:15',
  '16:30',
  '16:45',
  '17:00',
  '17:15',
  '17:30',
  '17:45',
  '18:00',
  '18:15',
  '18:30',
  '18:45',
  '19:00',
  '19:15',
  '19:30',
  '19:45',
  '20:00',
  '20:15',
  '20:30',
  '20:45',
  '21:00',
  '21:15',
  '21:30',
  '21:45',
  '22:00',
];

const WORK_DAYS = [
  { key: 1, label: { ua: 'Понеділок', cz: 'Pondělí', en: 'Monday', de: 'Montag', ru: 'Понедельник', fr: 'Lundi', es: 'Lunes' } },
  { key: 2, label: { ua: 'Вівторок', cz: 'Úterý', en: 'Tuesday', de: 'Dienstag', ru: 'Вторник', fr: 'Mardi', es: 'Martes' } },
  { key: 3, label: { ua: 'Середа', cz: 'Středa', en: 'Wednesday', de: 'Mittwoch', ru: 'Среда', fr: 'Mercredi', es: 'Miércoles' } },
  { key: 4, label: { ua: 'Четвер', cz: 'Čtvrtek', en: 'Thursday', de: 'Donnerstag', ru: 'Четверг', fr: 'Jeudi', es: 'Jueves' } },
  { key: 5, label: { ua: 'П’ятниця', cz: 'Pátek', en: 'Friday', de: 'Freitag', ru: 'Пятница', fr: 'Vendredi', es: 'Viernes' } },
  { key: 6, label: { ua: 'Субота', cz: 'Sobota', en: 'Saturday', de: 'Samstag', ru: 'Суббота', fr: 'Samedi', es: 'Sábado' } },
  { key: 7, label: { ua: 'Неділя', cz: 'Neděle', en: 'Sunday', de: 'Sonntag', ru: 'Воскресенье', fr: 'Dimanche', es: 'Domingo' } },
];

const UKRAINE_STATES = {
  0: 'Вінницька обл.',
  1: 'Волинська обл.',
  2: 'Дніпропетровська обл.',
  3: 'Донецька обл.',
  4: 'Житомирська обл.',
  5: 'Закарпатська обл.',
  6: 'Запорізька обл.',
  7: 'Івано-Франківська обл.',
  8: 'Київська обл.',
  9: 'Кіровоградська обл.',
  10: 'Луганська обл.',
  11: 'Львівська обл.',
  12: 'Миколаївська обл.',
  13: 'Одеська обл.',
  14: 'Полтавська обл.',
  15: 'Рівненська обл.',
  16: 'Сумська обл.',
  17: 'Тернопільська обл.',
  18: 'Харківська обл.',
  19: 'Херсонська обл.',
  20: 'Хмельницька обл.',
  21: 'Черкаська обл.',
  22: 'Чернівецька обл.',
  23: 'Чернігівська обл.',
  24: 'АР Крим',
  25: 'Берлінська земля',
  26: 'Центрально-Чешська обл.',
};

const FIL_OLD = [
  'Київ', // 0
  'Харків',
  'Дніпро',
  'Одеса',
  'Львів',
  'Одеса, пос. Котовського', // 5
  'Кривий Ріг',
  'Запоріжжя',
  'Миколаїв',
  'Вінниця',
  'Полтава', // 10
  'Рівне',
  'Маріуполь',
  'Черкаси',
  'Чернігів',
  'Суми', // 15
  'Кропивницький',
  'Ізмаїл',
  'Одеса, мікрорайон Таїрова',
  'Хмельницький',
  'Ужгород', // 20
  'Івано-Франківськ',
  'Біла Церква',
  'Тернопіль',
  'Херсон',
  'Київ, Печерський район', // 25
  'Чернівці',
  'Житомир',
  'Київ, Оболонський район',
  'Умань',
  'Київ, Дарницький район', // 30
  'Кременчук', // 31
  'Бровари', // 32
  'Київ, Голосіївський район', // 33
  'Луцьк', // 34
  'Ірпінь', // 35
];
// dont forget FIL_AVARAGE_INCOME

const SUBCATEGORIES = [
  {
    name: 'Кредитні справи',
    subcategories: [
      'Врегулювання спору',
      'Замороження кредиту',
      'Оскарження нарахувань',
      'Списання боргу',
      'Суди з банками та МФО',
      'Зняття арешту',
      'Застави',
      'Протидія колекторам',
      'Банкрутство',
    ],
  },
  {
    name: 'Сімейні справи',
    subcategories: [
      'Розірвання шлюбу',
      'Стягнення аліментів',
      'Поділ майна',
      'Питання по дітям',
      'Опіка та піклування',
      'Встановлення батьківства',
      'Позбавлення батьківства',
      'Супровід усиновлення',
    ],
  },
  {
    name: 'Військові справи',
    subcategories: [
      'Кримінальний захист',
      'Адміністративний захист',
      'Оскарження дій ТЦК',
      'Оскарження ВЛК',
      'Питання по мобілізації',
      'Звільнення з ЗСУ',
      'Оформлення УБД',
      'Отримання виплат',
    ],
  },
  {
    name: 'ДТП та інші питання по авто',
    subcategories: [
      'Кримінальні ДТП',
      'Адміністративні ДТП',
      'Стягнення шкоди',
      'Ст. 130 КУпАП',
      'Порушення ПДД',
      'Зняття арешту',
      'Пасажири при аварії',
      'Інші питання по авто',
    ],
  },
  {
    name: 'Трудові та пенсійні справи',
    subcategories: [
      'Незаконне звільнення',
      'Виробнича травма',
      'Шкідливе виробництво',
      'Смерть на виробництві',
      'Не виплата ЗП',
      'Оформлення пенсії',
      'Перерахунок пенсії',
      'Пенсії спори',
    ],
  },
  {
    name: 'Нерухомість',
    subcategories: [
      'Узаконення нерухомості',
      'Земельні справи',
      'Приватизація',
      'Суди за нерухомість',
      'Житлові спори',
      'Перевірка нерухомості',
      'Оформлення документів',
      'Поділ нерухомості',
    ],
  },
  {
    name: 'Цивільні справи',
    subcategories: [
      'Відшкодування шкоди',
      'Стягнення боргу',
      'Медичні справи',
      'Міжнародне право',
      'Страховки',
      'Спадкові справи',
      'Авторське право',
      // 'Інші справи'
    ],
  },
  {
    name: 'Адміністративні справи',
    subcategories: [
      'Виконавчі справи',
      'Податкові справи',
      'Міграційні справи',
      'Отримання дозволів',
      'Виборче право',
      'Зняття арешту',
      'Оскарження рішень',
      // 'Інші справи'
    ],
  },
  {
    name: 'Кримінальні справи',
    subcategories: [
      'Захист підозрюваного',
      'Досудове розслідування',
      'Судові процеси',
      'Оскарження вироку',
      'Захист потерпілого',
      'Стягнення шкоди',
      'Амністія',
      'Помилування',
      // 'Інші справи'
    ],
  },
  {
    name: 'Господарські справи',
    subcategories: [
      'Реєстрація СПД',
      'Юридичний аутсорсинг',
      'Досудове врегулювання',
      'Господарські спори',
      'Стягнення боргів',
      'Банкротство',
      'Припинення діяльності',
      // 'Інші справи',
    ],
  },
];

const CATEGORIES = {
  'kredytni-spravy-news': 'Кредитні справи',
  'simeyni-spravy-news': 'Сімейні справи',
  'viyskovy-spravy-news': 'Військові справи',
  'dtp-spravy-news': 'ДТП та інші питання по авто',
  'trydovi-pensiynih-spravy-news': 'Трудові та пенсійні справи',
  'neryhomist-spravy-news': 'Нерухомість',
  'cyvilni-spravy-news': 'Цивільні справи',
  'administrativni-spravy-news': 'Адміністративні справи',
  'kryminalni-spravy-news': 'Кримінальні справи',
  'hospodarski-spravy-news': 'Господарські справи',
};

const FIL_ADRESS = [
  'м. Київ,  вул. Володимирська 51/53, офіс 19', // 0
  "м. Харків, провулок Мар'яненка, 4, офіс № 12",
  'м. Дніпро, вул. Олеся Гончара 4, офіс № 1-А.',
  'м. Одеса, вул. Мала Арнаутська, будинок 71, офіс № 6',
  'м. Львів, площа Євгена Петрушевича, 3, офіс № 233',
  'м. Одеса, вул. Академіка Сахарова, 1-Г, офіс № 415-А', // 5
  'м. Кривий Ріг, проспект Миру, 29 Б, офіс № 3',
  'м. Запоріжжя, просп. Маяковського, 11, вхід А, офіс № 719',
  'м. Миколаїв, проспект Центральний, 93 В, офіс № 511',
  'м. Вінниця, вул. Соборна, 38, офіс № 8',
  'м. Полтава, вул. Котляревського 3, офіс № 610', // 10
  'м. Рівне, вул. Словацького 4-6, офіс № 227',
  'м. Маріуполь, проспект Миру 69, офіс № 517',
  'м. Черкаси, вул. Байди Вишневецького 36/1, офіс № 33',
  'м. Чернігів, проспект Миру 28, офіс № 321',
  'м. Суми, вул. Козацький Вал, 2-Б, офіс №305', // 15
  'м. Кропивницький, вул. Віктора Чміленка 82/40, офіс 402 ',
  'м. Ізмаїл, проспект Миру 25 Б, офіс 511',
  'м. Одеса, вул. Академіка Вільямса, буд. 73/1, офіс 8',
  'м. Хмельницький, вул. Соборна 12, офіс 40',
  'м. Ужгород, вул. Швабська 22, офіс № 5', // 20
  'м. Івано-Франківськ, вул. Галицька, 59а, офіс 201',
  'м. Біла Церква, вул. Театральна, 9, офіс 26',
  'м. Тернопіль, вул. Анатолія Живова, 9 М, офіс 6',
  'м. Херсон, вул. Театральна 8, офіс 23',
  'м. Київ, вул. Хрещатик 7/11, офіс № 626', // 25
  'м. Чернівці, вул. Героїв Майдану 31, офіс 9',
  'м. Житомир, вул.Ольжича 9, офіс № 2',
  'м. Київ, вул. Маршала Тимошенка 29-Б, офіс № 408',
  'м. Умань, вул. Незалежності, 12-А, офіс №1',
  'м. Київ, вул. Олександра Мішуги, 12, офіс № 358, каб. № 3', // 30
  'м. Кременчук, вул. Соборна 18/14, офіс № 229.',
  'м. Бровари, вул. Героїв України, 21-А, офіс № 401/2',
  'м. Київ, проспект Голосіївський, 132, офіс № 6',
  'м. Луцьк, вул. Винниченка 26, офіс № 217',
  'м. Ірпінь, вул. Садова 31/33, офіс № 39', // 35
];

const FIL_HEAD = [
  {
    name: 'Цієр Раїса Миколаївна',
    phone: '(093) 162-65-38',
    descr: 'Керівник Адвокатського об\'єднання "Захист" Київського округу', // 0
  },
  {
    name: 'Мушка Наталія Михайлівна',
    phone: '(063) 288-31-76',
    descr: 'Керівник Адвокатського об\'єднання "Захист" Харківського округу',
  },
  {
    name: 'Литвиненко Вікторія Андріївна',
    phone: '(063) 068-39-22',
    descr: 'Керівник Адвокатського об\'єднання "Захист" в Дніпровському окрузі',
  },
  {
    name: 'Мазурик Зоряна Ярославівна',
    phone: '(050) 800-09-77',
    descr: 'Керівник Адвокатського об\'єднання "Захист" Одеського округу',
  },
  {
    name: 'Вихівська Тетяна Володимирівна',
    phone: '(063) 620-63-47',
    descr: 'Керівник Адвокатського об\'єднання "Захист" Львівського округу',
  },
  {
    name: 'Тодорова Олена Миколаївна',
    phone: '(063) 108-09-52',
    descr: 'Керівник відділення Адвокатського об\'єднання "Захист" у Суворовському районі м.Одеси', // 5
  },
  {
    name: 'Чорна Катерина Миколаївна',
    phone: '(063) 942-44-34',
    descr: 'Керівник відділення Адвокатського об\'єднання "Захист" у Криворізькому окрузі',
  },
  {
    name: 'Гасимова Аліна Олександрівна',
    phone: '(063) 491-42-61',
    descr: 'Керівник Адвокатського об\'єднання "Захист" Запорізького округу',
  },
  {
    name: 'Коржова Вікторія Ігорівна',
    phone: '(063) 170-62-98',
    descr: 'Керівник відділення Адвокатського об\'єднання "Захист" у Миколаївському окрузі',
  },
  {
    name: 'Яцковина Юлія Володимирівна',
    phone: '(063) 676-04-47',
    descr: 'Керівник Адвокатського об\'єднання "Захист" Вінницького округу',
  },
  {
    name: 'Сабадирь Валентина Олексіївна',
    phone: '(093) 311-90-56', // 10
    descr: 'Керівник відділення Адвокатського об\'єднання "Захист" у Полтавському окрузі',
  },
  {
    name: 'Синтопська Анастасія Володимирівна',
    phone: '(095) 765-53-47',
    descr: 'Керівник відділення Адвокатського об\'єднання "Захист" у Рівненському окрузі',
  },
  {
    name: 'Остапенко Анна Василівна',
    phone: '(093) 241-32-69',
    descr: 'Керівник відділення Адвокатського об\'єднання "Захист" у Маріупольському окрузі',
  },
  {
    name: 'Комишна Вікторія Сергіївна',
    phone: 'Керівник відділення Адвокатського об\'єднання "Захист" у Черкаському окрузі',
    descr: '(093) 759-20-44',
  },
  {
    name: 'Коломієць Катерина Володимирівна',
    phone: '(063) 890-72-53',
    descr: 'Керівник відділення Адвокатського об\'єднання "Захист" у Чернігівському окрузі',
  },
  {
    name: 'Гайдамака Катерина Олексіївна',
    phone: '(063) 682-05-58',
    descr: 'Керівник відділення Адвокатського об\'єднання "Захист" у Сумському окрузі', // 15
  },
  {
    name: 'Карпова Катерина Сергіївна',
    phone: '(063) 860-12-97',
    descr: 'Керівник відділення Адвокатського об\'єднання "Захист" у Кропивницькому окрузі',
  },
  {
    name: '',
    phone: '',
    descr: '',
  },
  {
    name: '',
    phone: '',
    descr: '',
  },
  {
    name: 'Бабійчук Юлія Юріївна',
    phone: '(063) 601-41-73',
    descr: 'Керівник відділення Адвокатського об\'єднання "Захист" у Хмельницькому окрузі',
  },
  {
    name: 'Черниш Андріана Андріївна',
    phone: '(093) 421-52-49',
    descr: 'Керівник відділення Адвокатського об\'єднання "Захист" в Ужгородському окрузі', // 20
  },
  {
    name: 'Адрусенко Альона Вадимівна',
    phone: '(050) 976-18-02',
    descr: 'Керівник відділення Адвокатського об\'єднання "Захист" в Івано- Франківському окрузі',
  },
  {
    name: '',
    phone: '',
    descr: '',
  },
  {
    name: 'Мушка Наталія Михайлівна',
    phone: '(063) 288-31-76',
    descr: 'Керівник Адвокатського об\'єднання "Захист" Харківського округу',
  },
  {
    name: '',
    phone: '',
    descr: '',
  },
  {
    name: 'Єременко Карина Віталіївна',
    phone: '(093) 461-42-04',
    descr: 'Керівник відділення Адвокатського об\'єднання "Захист" у м.Київ, станція "Площа Льва Толстого"', // 25
  },
  {
    name: 'Ізотова Діана Андріївна',
    phone: '(093) 268-92-75',
    descr: 'Керівник відділення Адвокатського об\'єднання "Захист" у Чернівецькому окрузі',
  },
  {
    name: 'Антонюк Олександра Петрівна',
    phone: '(093) 65-77-849',
    descr: 'Керівник Адвокатського об\'єднання "Захист" Житомирського округу',
  },
  {
    name: 'Малахова Оксана Миколаївна',
    phone: '(066) 483-52-84',
    descr: 'Керівник відділення Адвокатського об\'єднання "Захист" у м.Київ, станція "Мінська"',
  },
  {
    name: 'Панасюк Юлія Валентинівна',
    phone: '(093) 352-06-34',
    descr: 'Керівник Адвокатського об\'єднання "Захист" в Уманському окрузі',
  },
  {
    name: 'Веремчук Каріна Юріївна',
    phone: '(093) 753-82-60',
    descr: 'Керівник відділення Адвокатського об\'єднання "Захист" у м.Київ, станція "Позняки"', // 30
  },
  {
    name: 'Троян Валерія Сергіївна',
    phone: '(093) 697-25-83',
    descr: 'Керівник відділення Адвокатського об\'єднання "Захист" у Кременчуцькому окрузі',
  },
  {
    name: 'Кройтор Ольга Ігорівна',
    phone: '(093) 753-70-16',
    descr: 'Керівник Адвокатського об\'єднання "Захист" Броварського округу',
  },
  {
    name: 'Животівська Вікторія Василівна',
    phone: '(099) 482-24-84',
    descr: 'Керівник відділення Адвокатського об\'єднання "Захист" у м.Київ, станція "Виставковий Центр"(ВДНГ) ',
  },
  {
    name: '',
    phone: '',
    descr: '',
  },
  {
    name: '',
    phone: '',
    descr: '',
  },
  {
    name: '',
    phone: '',
    descr: '', // 35
  },
];

const FIL_ADRESS_DESCRIBE = [
  'офісна будівля напроти Оперного театру, 5 поверх (ліфт праворуч), станція метро Золоті ворота, вхід під вивіскою кафе «Salateira”.', // 0
  'офісна будівля біля станції метро "Історичний музей", 1 поверх, в районі магазину АТБ.',
  'трьохповерхова будівля, 1 поверх, зупинка "Історичний Музей", на домофоні натиснути код 77, та букву «К-виклик».',
  'офісна будівля на перехресті вулиць Мала Арнаутська і Рішельєвська, 2 поверх.',
  'офісна будівля зеленого кольору 2 поверх, перехрестя площі Євгена Петрушевича та вул. Зелена.',
  'офісна будівля ТЦ «Ковчег» 4 поверх, поруч з ринком «Початок»', // 5
  'офісна двоповерхова будівля, 2 поверх, зупинка Ремпобуттехніка, паралельно зупинка Перший міський телеканал.',
  'будівля БЦ "Маяковський" 7 поверх, перехрестя проспекту Маяковського і вул. Перемоги.',
  'будівля БЦ "Дормашина" 5 поверх, ресторан "Якіторія".',
  'офісна будівля біля тролейбусної зупинки "Майдан Незалежності", 3 поверх, вхід з вулиці.',
  'будівля БЦ "Ера" 6 поверх, поруч ТЦ "ЦУМ".', // 10
  'будівля БЦ "Словацький" 2 поверх, поруч з Рівненським театром ляльок.',
  'будівля БЦ "Україна" 5 поверх.',
  'Двоповерхова офісна будівля зеленого кольору з великим годинником (над дверми висить табличка "Apple Service"), 2 поверх, на площі між Міською радою та банком ПУМБ.',
  'будівля "Главпоштамп" 3 поверх, вхід зі сторони проспекту Перемоги, навпроти готелю "Україна".',
  'будівля БЦ "Емпаєр Моторс", 3 поверх, зупинка площа Покровська, поруч готель "Україна".', // 15
  'будівля БЦ "Барва" 4 поверх, площа Героїв Майдану, перехрестя вул. Великої Перспективної та Віктора Чміленка.',
  'будівля БЦ «Дельта» 5 поверх, поряд кінотеатр «Україна».',
  'синя будівля БЦ "Вертикаль" 2 поверх (останні двері по коридору), перехрестя вул. Ак. Корольова і вул. Ак. Вільямса, напроти Таврії В.',
  'будівля БЦ "Домінант" 5 поверх, перехрестя вулиць Соборна і Староміська, поруч з Автостанцією № 3.',
  'офісна будівля коричневого кольору 3 поверх, вхід зі сторони терміналу "ПриватБанк", напроти ресторану "Веранда"', // 20
  'восьмиповерхова офісна будівля чорно-оранжевого коліру, 2 поверх, поруч знаходиться супермаркет АТБ, недалеко від парку "Піонерський", код домофону - 5959#',
  'будівля банку "Укргазбанк" 2 поверх, навпроти пам\'ятника "Дзеркальна - Куля", поруч з ТЦ "ВЕГА".',
  'офісний центр "Rich Town", 4 поверх, зупинка "Центральний ринок", вхід зі сторони аптеки БАМ.',
  'колишня будівля "Промінвестбанк", 2 поверх, зупинка "Драмтеатр", навпроти музея природи.',
  'будівля БЦ "Хрещатик 7/11" 6 поверх, станції метро "Майдан Незалежності", біля площі "Майдан Незалежності", перехрестя вулиць Хрещатик та Алеї Героїв Небесної Сотні', // 25
  'зупинка «Медучилище», Офісний центр «О.V.КАПІТАЛ», 2 поверх',
  'будівля БЦ «Діамін» 1 поверх, поряд з ГУ Пенсійного Фонду України в Житомирській області, зупинка сквер ім. Олега Ольжича',
  'офісна будівля 4 поверх, станція метро «Мінська», в будівлі також знахохиться Школа англійської мови "Speak Up" та кафе «Перець».',
  'офісна будівля навпроти Агротехнічного коледжа, 1 поверх, (перші двері зліва), поряд Медичний коледж, колишня назва вулиці: вул. Жовтневої революції',
  'багатоповерховий ЖК з офісними приміщеннями на перших двох поверхах, 2-й поверх, станція метро "Позняки", біля БЦ «Паралель» та ТЦ "Піраміда", вхід з вул. Олександра Мішуги', // 30
  'будівля БЦ “Престиж Хол” 2 поверх, напроти скверу імені Олега Бабаєва, на дверях другого поверху натиснути код 02 25 18#',
  'офісна будівля зі скляним фасадом, 4 поверх, біля зупинки «Школа №7», стара назва вулиці «Гагаріна»',
  'будівля БЦ "РЕЛЕ", 10 поверх, станція метро «Виставковий Центр" (ВДНГ)',
  'будівля БЦ «Директорія», 1 поверх, поряд з корпусом № 1 Волинського НУ ім. Лесі Українки, навпроти Волинського обласного відділення антимонопольного комітету України',
  'будівля ТЦ "GARDEN PLAZA", 4 поверх, навпроти Центральної міської лікарні, поруч з Дитячою міською поліклінікою', // 35
];

const old_map = 'https://cutt.ly/mHPSc3G';

const FIL_MAP = [
  'https://goo.gl/maps/RjiNNABByLhgeix4A', // 0
  'https://goo.gl/maps/Xk4VBZSf7HxirAjk9',
  old_map,
  'https://goo.gl/maps/BKE6xY3ofoueKPUS8',
  'https://goo.gl/maps/oSqtfWUwRtDUBtHi6',
  'https://goo.gl/maps/5Njox6o4RW2QjtFP7', // 5
  old_map,
  'https://goo.gl/maps/cpS9e1EUWxNu8mSF6',
  'https://goo.gl/maps/FW7v7sSW4AqA9VQK9',
  'https://goo.gl/maps/8zSJqw4PoiuVcMUX9',
  'https://goo.gl/maps/KMc4CgkCahTCLPAV8', // 10
  'https://goo.gl/maps/6fSmpZBogd9XwFAR7',
  old_map,
  old_map,
  old_map,
  old_map, // 15
  old_map,
  old_map,
  old_map,
  old_map,
  old_map, // 20
  old_map,
  old_map,
  old_map,
  old_map,
  old_map, // 25
  old_map,
  old_map,
  old_map,
  old_map,
  old_map, // 30
  old_map,
  old_map,
  old_map,
  old_map,
  old_map, // 35
];

const FIL_VIDEO = [
  'https://www.youtube.com/embed/NnVhFOZlBsk', // Київ
  '', // Харків
  '', // Дніпро
  'https://www.youtube.com/embed/IkEk1iLyYSc', // Одеса
  'https://www.youtube.com/embed/TaKUDVDZDHs', // Львів
  'https://www.youtube.com/embed/g6r84LCZwTY', // Одеса, пос. Котовського
  'https://www.youtube.com/embed/LZtoNZWyNAQ', // Кривий Ріг
  'https://www.youtube.com/embed/6u3FQCHX5ZU', // Запоріжжя
  'https://www.youtube.com/embed/JRqYoMoHSHA', // Миколаїв
  'https://www.youtube.com/embed/svIrZHhTtJs', // Вінниця
  'https://www.youtube.com/embed/i38b6bLWpiY', // Полтава
  'https://www.youtube.com/embed/7BM581G-1NM', // Рівне
  '', // Маріуполь
  'https://www.youtube.com/embed/rPoVsuvZCgE', // Черкаси
  'https://www.youtube.com/embed/yKj6SclqcdQ', // Чернігів
  'https://www.youtube.com/embed/Kp3idZWXjtI', // Суми
  'https://www.youtube.com/embed/aItx9eKjac0', // Кропивницький
  '', // Ізмаїл
  '', // Одеса, мікрорайон Таїрова
  'https://www.youtube.com/embed/MMNaF6uENfw', // Хмельницький
  'https://www.youtube.com/embed/GFeOg0BtJyk', // Ужгород
  'https://www.youtube.com/embed/crXsUIVzfWU', // Івано-Франківськ
  '', // Біла Церква
  'https://www.youtube.com/embed/qJnlxAGuq98', // Тернопіль
  '', // Херсон
  'https://www.youtube.com/embed/e30Qu9xoRn4', // Київ, Печерський район
  'https://www.youtube.com/embed/TCUHPoYIt_E', // Чернівці
  'https://www.youtube.com/embed/w9uok8vIklk', // Житомир
  '', // Київ, Оболонський район
  'https://www.youtube.com/embed/bDYQxaox1a8', // Умань
  'https://www.youtube.com/embed/dukyLw2J0XU', // Київ, Дарницький район
  'https://www.youtube.com/embed/gIUS-gsx-2w?si=ihJ4or0j9xZnrXAj', // Кременчук
  'https://www.youtube.com/embed/Mge6rU-E2oc', // Бровари
  'https://www.youtube.com/embed/sEgxWGz93U8', // Київ, Голосіївський район
  '', // Луцьк
  '', // Ірпінь
];

const FIL_ZONES = [
  'Правий берег м. Київа і Київської обл., (крім м. Біла Церква)', // 0
  'Харківська обл., Луганська обл.',
  'Дніпропетровська обл. крім м. Кривий Ріг.',
  'м. Одеса (окрім с. Котовського та мікрорайона Таїрова)',
  'Львівська обл.',
  'с. Котовського м. Одеси, а також Одеська область.', // 5
  'м. Кривий Ріг',
  'Запорізька обл.',
  'Миколаївська обл.',
  'Вінницька обл., Житомирська обл',
  'Полтавська обл., крім Кременчуцького району та м. Кременчук.', // 10
  'Рівненська обл., Волинська обл.',
  'Донецька обл.',
  'Черкаська обл., крім Уманського району',
  'Чернігівська обл.',
  'Сумська обл.', // 15
  'Кіровоградська обл.',
  'с. Котовського м. Одеси, а також Одеська область.',
  'Таїровські квартали м. Одеси, Овідіопольський р-н Одеської області, м. Білгород-Дністровський, м. Чорноморськ.',
  'Хмельницька обл.',
  'Закарпатська обл.', // 20
  'Івано-Франківська обл.',
  'м. Біла Церква',
  'Тернопільська обл.',
  'Херсонська обл., АРК',
  'Лівий берег м. Києва та Київської області, крім Дарницького району м. Києва', // 25
  'Чернівецька обл.',
  'Житомирська обл.',
  'Оболонський район м. Києва',
  'Уманський район, Черкаської області',
  'Дарницький район м. Києва', // 30
  'м. Кременчук та Кременчуцький район Полтавської області.',
  'Броварський район Київської області',
  'Голосіївській район м. Києва',
  'Волинська обл.',
  'Бучанський район Київської області', // 35
];

const PRAVO_TYPES_LIBRARY = ['Цивільні', 'Кримінальні', 'Господарські', 'Адміністративні', 'Міжгалузеві'];

const EXACTLY_WHAT_LIBRARY = [
  'Інше',
  'Кредит застава не АНК',
  'Кредит без застави не АНК',
  'АНК',
  'Розлучення',
  'Аліменти',
  'Розподіл майна',
  'Суперечка за дітей',
  'ДТП Адмін',
  'ДТП Крим',
  '130 КУпАП',
  'Не направляти!',
  'ККЛ',
  'Пенсія',
  'Пенсія військових',
  'Земля',
  'Нерухомість',
  'Комуналка',
  'Спадщина',
  'Трудові',
  'ОСББ',
  'Виконавчі',
  'Житлові інші',
  'COVID',
  'Військові',
  'Битовуха',
  'Субсидії',
  'Інтелект власної',
  'Шахрайство',
  'Депозит',
  'Шахрайство по кредитах',
  'Затоплення',
  'Виселення',
  'Приватизація',
  'Міграційні',
  'Крим загальнокримінальні',
  'Соц виплати',
  'Сусід взяв кредит',
  'Правовий аналіз',
  'Податки',
  'Встановлення юр факту',
  'Військкомат відкоси',
  'Авто інші питання',
  'Захист прав потреб',
  'Опіка та піклування',
  'Відшкодування за навчання',
  'Медична помилка',
  'Позбавлення батьківських прав',
  'Сімейні інші',
  'Реєстрація спд',
  'Ліквідація спд',
  'Аутсорсінг спд',
  'Господарській спір',
  'Юр страх консультація',
  'Юр страх активація',
  '-',
  '-',
  '-',
  'Клубна карта',
  'Адмін інше',
  'Адмін оскарження дій',
  'Громадянство',
  'Невиплата зп',
  'Звільнення',
  'Трудові стягнення шкоди',
  'Аварії та нещасні випадки',
  'Війна компенсація',
  'Авторське право',
  'СЗЧ',
  'Тривожна кнопка',
  '-',
  'Бронювання',
  'Оскарження ВЛК',
  'Повістки поштою',
];

const FILE_TYPE_LIBRARY = {
  0: 'Документ',
  1: 'Посилання',
  2: 'Справа',
};

const TYPE_AND_FORM_OF_THE_DOCUMENT_LIBRARY_ELEMENT_1 = {
    1: { label: 'Закон', value: 1 },
    2: { label: 'Підзаконний нормативно-правовий акт', value: 2 },
  },
  TYPE_AND_FORM_OF_THE_DOCUMENT_LIBRARY_ELEMENT_2 = {
    1: { label: 'Судове рішення', value: 1 },
    2: { label: 'Постанова пленуму ВСУ', value: 2 },
    3: { label: 'Роз’яснення суду', value: 3 },
  },
  TYPE_AND_FORM_OF_THE_DOCUMENT_LIBRARY_ELEMENT_3 = {
    1: { label: 'Адвокатський запит', value: 1 },
    2: { label: 'Акт', value: 2 },
    3: { label: 'Апеляційна скарга', value: 3 },
    4: { label: 'Висновок', value: 4 },
    5: { label: 'Відгуг', value: 5 },
    6: { label: 'Відповідь на претензію', value: 6 },
    7: { label: 'Довідка', value: 7 },
    8: { label: 'Довіреність', value: 8 },
    9: { label: 'Договір', value: 9 },
    10: { label: 'Додаткова угода', value: 10 },
    11: { label: 'Заперечення', value: 11 },
    12: { label: 'Заперечення та відзиви', value: 12 },
    13: { label: 'Запит', value: 13 },
    14: { label: 'Заява', value: 14 },
    15: { label: 'Зустрічний позов', value: 15 },
    16: { label: 'Інструкція', value: 16 },
    17: { label: 'Касаційна скарга', value: 17 },
    18: { label: 'Клопотання', value: 18 },
    19: { label: 'Контракт', value: 19 },
    20: { label: 'Лист', value: 20 },
    21: { label: 'Мирова угода', value: 21 },
    22: { label: 'Наказ', value: 22 },
    23: { label: 'Письмові пояснення', value: 23 },
    24: { label: 'Позовна заява', value: 24 },
    25: { label: 'Положення', value: 25 },
    26: { label: 'Претензія', value: 26 },
    27: { label: 'Протокол', value: 27 },
    28: { label: 'Розписка', value: 28 },
    29: { label: 'Скарга', value: 29 },
    30: { label: 'Скарга до ЄСПЛ', value: 30 },
    31: { label: 'Статут', value: 31 },
    50: { label: 'Інші', value: 50 },
  },
  TYPE_AND_FORM_OF_THE_DOCUMENT_LIBRARY_ELEMENT_4 = {
    1: { label: 'Акт', value: 1 },
    2: { label: 'Висновок', value: 2 },
    3: { label: 'Довідка', value: 3 },
    4: { label: 'Довіреність', value: 4 },
    5: { label: 'Договір', value: 5 },
    6: { label: 'Додаткова угода', value: 6 },
    7: { label: 'Інструкція', value: 7 },
    8: { label: 'Наказ', value: 8 },
    9: { label: 'Положення', value: 9 },
    10: { label: 'Протокол', value: 10 },
    11: { label: 'Статут', value: 11 },
    50: { label: 'Інші', value: 50 },
  },
  TYPE_AND_FORM_OF_THE_DOCUMENT_LIBRARY_ELEMENT_5 = {
    1: { label: 'Акт', value: 1 },
    2: { label: 'Довідка', value: 2 },
    3: { label: 'Довіреність', value: 3 },
    4: { label: 'Договір', value: 4 },
    5: { label: 'Додаткова угода', value: 5 },
    6: { label: 'Заява', value: 6 },
    7: { label: 'Контракт', value: 7 },
    8: { label: 'Лист', value: 8 },
    9: { label: 'Наказ', value: 9 },
    10: { label: 'Протокол', value: 10 },
    11: { label: 'Розписка', value: 11 },
    12: { label: 'Скарга', value: 12 },
    13: { label: 'Статут', value: 13 },
    50: { label: 'Інші', value: 50 },
  },
  TYPE_AND_FORM_OF_THE_DOCUMENT_LIBRARY = {
    1: {
      label: 'Нормативні акти',
      value: 1,
      form: TYPE_AND_FORM_OF_THE_DOCUMENT_LIBRARY_ELEMENT_1,
    },
    2: {
      label: 'Судова практика',
      value: 2,
      form: TYPE_AND_FORM_OF_THE_DOCUMENT_LIBRARY_ELEMENT_2,
    },
    3: {
      label: 'Зразки документів',
      value: 3,
      form: TYPE_AND_FORM_OF_THE_DOCUMENT_LIBRARY_ELEMENT_3,
    },
    4: {
      label: 'Фаховий документ',
      value: 4,
      form: TYPE_AND_FORM_OF_THE_DOCUMENT_LIBRARY_ELEMENT_4,
    },
    5: {
      label: 'Форми',
      value: 5,
      form: TYPE_AND_FORM_OF_THE_DOCUMENT_LIBRARY_ELEMENT_5,
    },
    6: { label: 'Юридичні статті', value: 6, form: {} },
    7: { label: 'Наші консультації', value: 7, form: {} },
    8: { label: 'Посилання', value: 8, form: {} },
    50: { label: 'Інше', value: 50, form: {} },
  };

const TYPES_OF_MAIL = [
  { value: 1, label: 'Укрпошта', on: 'на Укрпошту', from: 'з Укрпошти' },
  { value: 2, label: 'Нова пошта', on: 'на Нову пошту', from: 'з Нової пошти' },
  { value: 3, label: 'Електрона пошта', on: 'на електрону пошту', from: 'з електронної пошти' },
  { value: 4, label: 'Телеграм', on: 'на телеграм', from: 'з телеграму' },
  { value: 5, label: 'Вайбер', on: 'на вайбер', from: 'з вайберу' },
  { value: 6, label: 'Сайт', on: 'на сайт', from: 'з сайту' },
  { value: 7, label: 'Кур\'єр АО "Захисту"', on: 'через кур\'єра АО "Захисту"', from: 'через кур\'єра АО "Захисту"' },
  { value: 8, label: "Кур'єр іншої компанії", on: "через кур'єра іншої компанії", from: "через кур'єра іншої компанії" },
  { value: 50, label: 'Інший поштовий сервіс', on: 'через інший поштовий сервіс', from: 'через інший поштовий сервіс' },
];

const PRAVO_TYPES = ['Цивільні', 'Кримінальні', 'Господарські', 'Адміністративні'];

const PRAVO_TYPES_FOR_EADVOKAT = {
  ua: ['Цивільні', 'Кримінальні', 'Господарські', 'Адміністративні'],
  ru: ['Гражданские', 'Уголовные', 'Хозяйственные', 'Административные'],
  en: ['Civil', 'Criminal', 'Commercial', 'Administrative'],
  de: ['Zivil', 'Strafrechtliche', 'Handelsrechtliche', 'Verwaltungsrechtliche'],
  fr: ['Civiles', 'Pénales', 'Commerciales', 'Administratives'],
  es: ['Civiles', 'Penales', 'Comerciales', 'Administrativas'],
  cz: ['Občanské', 'Trestní', 'Obchodní', 'Správní'],
};

const UNUSED_PRAVO = ['70'];

const PRAVO = [
  'Не консультація', // 0
  'Кредит застава не АНК', // 1
  'Кредит без застави не АНК', // 2
  'АНК', // 3
  'Розлучення', // 4
  'Аліменти', // 5
  'Розподіл майна', // 6
  'Суперечка за дітей', // 7
  'ДТП Адмін', // 8
  'ДТП Крим', // 9
  '130 КУпАП', // 10
  'Не направляти!', // 11
  'ККЛ', // 12
  'Пенсія', // 13
  'Пенсія військових', // 14
  'Земля', // 15
  'Нерухомість', // 16
  'Комуналка', // 17
  'Спадщина', // 18
  'Трудові', // 19
  'ОСББ', // 20
  'Виконавчі', // 21
  'Житлові інші', // 22
  'COVID', // 23
  'Військові', // 24
  'Битовуха', // 25
  'Субсидії', // 26
  'Інтелект власної', // 27
  'Шахрайство', // 28
  'Депозит', // 29
  'Шахрайство по кредитах', // 30
  'Затоплення', // 31
  'Виселення', // 32
  'Приватизація', // 33
  'Міграційні', // 34
  'Крим загальнокримінальні', // 35
  'Соц виплати', // 36
  'Сусід взяв кредит', // 37
  'Правовий аналіз', // 38
  'Податки', // 39
  'Встановлення юр факту', // 40
  'Військкомат відкоси', // 41
  'Авто інші питання', // 42
  'Захист прав потреб', // 43
  'Опіка та піклування', // 44
  'Відшкодування за навчання', // 45
  'Медична помилка', // 46
  'Позбавлення батьківських прав', // 47
  'Сімейні інші', // 48
  'Реєстрація спд', // 49
  'Ліквідація спд', // 50
  'Аутсорсінг спд', // 51
  'Господарській спір', // 52
  'Юр страх консультація', // 53
  'Юр страх активація', // 54
  '-', // 55
  '-', // 56
  '-', // 57
  'Клубна карта', // 58
  'Адмін інше', // 59
  'Адмін оскарження дій', // 60
  'Громадянство', // 61
  'Невиплата зп', // 62
  'Звільнення', // 63
  'Трудові стягнення шкоди', // 64
  'Аварії та нещасні випадки', // 65
  'Війна компенсація', // 66
  'Авторське право', // 67
  'СЗЧ', // 68
  'Тривожна кнопка', // 69
  '-', // 70
  'Бронювання', // 71
  'Оскарження ВЛК', // 72
  'Повістки поштою', // 73
  'Відстрочка', // 74
];

const PRAVO_FOR_EADVOKAT = [
  {
    ua: 'Не консультація',
    ru: 'Не консультация',
    en: 'Not a consultation',
    de: 'Keine Beratung',
    fr: 'Pas de consultation',
    es: 'No es consulta',
    cz: 'Není konzultace',
  },
  {
    ua: 'Кредит застава не АНК',
    ru: 'Кредит залог не АНК',
    en: 'Secured loan, not ANC',
    de: 'Gesicherter Kredit, kein ANC',
    fr: 'Prêt avec garantie, pas ANC',
    es: 'Préstamo con garantía, no ANC',
    cz: 'Úvěr se zástavou, ne ANC',
  },
  {
    ua: 'Кредит без застави не АНК',
    ru: 'Кредит без залога не АНК',
    en: 'Unsecured loan, not ANC',
    de: 'Unbesicherter Kredit, kein ANC',
    fr: 'Prêt sans garantie, pas ANC',
    es: 'Préstamo sin garantía, no ANC',
    cz: 'Půjčka bez zástavy, ne ANC',
  },
  {
    ua: 'АНК',
    ru: 'АНК',
    en: 'ANC',
    de: 'ANC',
    fr: 'ANC',
    es: 'ANC',
    cz: 'ANC',
  },
  {
    ua: 'Розлучення',
    ru: 'Развод',
    en: 'Divorce',
    de: 'Scheidung',
    fr: 'Divorce',
    es: 'Divorcio',
    cz: 'Rozvod',
  },
  {
    ua: 'Аліменти',
    ru: 'Алименты',
    en: 'Alimony',
    de: 'Unterhalt',
    fr: 'Pension alimentaire',
    es: 'Pensión alimenticia',
    cz: 'Výživné',
  },
  {
    ua: 'Розподіл майна',
    ru: 'Раздел имущества',
    en: 'Property division',
    de: 'Vermögensteilung',
    fr: 'Partage des biens',
    es: 'División de bienes',
    cz: 'Rozdělení majetku',
  },
  {
    ua: 'Суперечка за дітей',
    ru: 'Спор о детях',
    en: 'Child custody dispute',
    de: 'Sorgerechtsstreit',
    fr: 'Litige pour la garde des enfants',
    es: 'Disputa por la custodia de los hijos',
    cz: 'Spor o děti',
  },
  {
    ua: 'ДТП Адмін',
    ru: 'ДТП Админ',
    en: 'Traffic accident (administrative)',
    de: 'Verkehrsunfall (administrativ)',
    fr: 'Accident de la route (administratif)',
    es: 'Accidente de tráfico (administrativo)',
    cz: 'Dopravní nehoda (správní)',
  },
  {
    ua: 'ДТП Крим',
    ru: 'ДТП Крим',
    en: 'Traffic accident (criminal)',
    de: 'Verkehrsunfall (strafrechtlich)',
    fr: 'Accident de la route (criminel)',
    es: 'Accidente de tráfico (criminal)',
    cz: 'Dopravní nehoda (trestní)',
  },
  {
    ua: '130 КУпАП',
    ru: '130 КУоАП',
    en: 'Article 130 of the Code of Administrative Offenses',
    de: 'Artikel 130 des Verwaltungsgesetzbuches',
    fr: 'Article 130 du Code des infractions administratives',
    es: 'Artículo 130 del Código de infracciones administrativas',
    cz: 'Článek 130 správního řádu',
  },
  {
    ua: 'Не направляти!',
    ru: 'Не направлять!',
    en: 'Do not forward!',
    de: 'Nicht weiterleiten!',
    fr: 'Ne pas transmettre!',
    es: 'No reenviar!',
    cz: 'Neposílat!',
  },
  {
    ua: 'ККЛ',
    ru: 'ККЛ',
    en: 'CCL',
    de: 'KKL',
    fr: 'CCL',
    es: 'CCL',
    cz: 'KKL',
  },
  {
    ua: 'Пенсія',
    ru: 'Пенсия',
    en: 'Pension',
    de: 'Rente',
    fr: 'Pension',
    es: 'Pensión',
    cz: 'Důchod',
  },
  {
    ua: 'Пенсія військових',
    ru: 'Пенсия военных',
    en: 'Military pension',
    de: 'Militärpension',
    fr: 'Pension militaire',
    es: 'Pensión militar',
    cz: 'Vojenský důchod',
  },
  {
    ua: 'Земля',
    ru: 'Земля',
    en: 'Land',
    de: 'Grundstück',
    fr: 'Terrain',
    es: 'Terreno',
    cz: 'Pozemek',
  },
  {
    ua: 'Нерухомість',
    ru: 'Недвижимость',
    en: 'Real estate',
    de: 'Immobilien',
    fr: 'Immobilier',
    es: 'Bienes raíces',
    cz: 'Nemovitost',
  },
  {
    ua: 'Комуналка',
    ru: 'Коммунальные услуги',
    en: 'Utilities',
    de: 'Nebenkosten',
    fr: 'Services publicz',
    es: 'Servicios públicos',
    cz: 'Služby',
  },
  {
    ua: 'Спадщина',
    ru: 'Наследство',
    en: 'Inheritance',
    de: 'Erbschaft',
    fr: 'Héritage',
    es: 'Herencia',
    cz: 'Dědictví',
  },
  {
    ua: 'Трудові',
    ru: 'Трудовые вопросы',
    en: 'Employment issues',
    de: 'Arbeitsangelegenheiten',
    fr: 'Questions d’emploi',
    es: 'Asuntos laborales',
    cz: 'Pracovní záležitosti',
  },
  {
    ua: 'ОСББ',
    ru: 'ОСМД',
    en: 'HOA (Homeowners Association)',
    de: 'Eigentümergemeinschaft',
    fr: 'Association de copropriétaires',
    es: 'Asociación de propietarios',
    cz: 'SVJ (Sdružení vlastníků jednotek)',
  },
  {
    ua: 'Виконавчі',
    ru: 'Исполнительные производства',
    en: 'Enforcement proceedings',
    de: 'Vollstreckungsverfahren',
    fr: 'Procédures d’exécution',
    es: 'Procedimientos de ejecución',
    cz: 'Exekuční řízení',
  },
  {
    ua: 'Житлові інші',
    ru: 'Жилищные другие',
    en: 'Other housing issues',
    de: 'Sonstige Wohnungsangelegenheiten',
    fr: 'Autres questions de logement',
    es: 'Otros asuntos de vivienda',
    cz: 'Ostatní bytové záležitosti',
  },
  {
    ua: 'COVID',
    ru: 'COVID',
    en: 'COVID',
    de: 'COVID',
    fr: 'COVID',
    es: 'COVID',
    cz: 'COVID',
  },
  {
    ua: 'Військові',
    ru: 'Военные вопросы',
    en: 'Military issues',
    de: 'Militärische Angelegenheiten',
    fr: 'Questions militaires',
    es: 'Asuntos militares',
    cz: 'Vojenské záležitosti',
  },
  {
    ua: 'Битовуха',
    ru: 'Бытовуха',
    en: 'Domestic issues',
    de: 'Haushaltsprobleme',
    fr: 'Problèmes domestiques',
    es: 'Problemas domésticos',
    cz: 'Domácí problémy',
  },
  {
    ua: 'Субсидії',
    ru: 'Субсидии',
    en: 'Subsidies',
    de: 'Subventionen',
    fr: 'Subventions',
    es: 'Subsidios',
    cz: 'Dotace',
  },
  {
    ua: 'Інтелект власної',
    ru: 'Интеллектуальная собственность',
    en: 'Intellectual property',
    de: 'Geistiges Eigentum',
    fr: 'Propriété intellectuelle',
    es: 'Propiedad intelectual',
    cz: 'Duševní vlastnictví',
  },
  {
    ua: 'Шахрайство',
    ru: 'Мошенничество',
    en: 'Fraud',
    de: 'Betrug',
    fr: 'Fraude',
    es: 'Fraude',
    cz: 'Podvod',
  },
  {
    ua: 'Депозит',
    ru: 'Депозит',
    en: 'Deposit',
    de: 'Einlage',
    fr: 'Dépôt',
    es: 'Depósito',
    cz: 'Vklad',
  },
  {
    ua: 'Шахрайство по кредитах',
    ru: 'Мошенничество по кредитам',
    en: 'Credit fraud',
    de: 'Kreditbetrug',
    fr: 'Fraude de crédit',
    es: 'Fraude de crédito',
    cz: 'Úvěrový podvod',
  },
  {
    ua: 'Затоплення',
    ru: 'Затопление',
    en: 'Flooding',
    de: 'Überschwemmung',
    fr: 'Inondation',
    es: 'Inundación',
    cz: 'Zatopení',
  },
  {
    ua: 'Виселення',
    ru: 'Выселение',
    en: 'Eviction',
    de: 'Zwangsräumung',
    fr: 'Expulsion',
    es: 'Desalojo',
    cz: 'Vystěhování',
  },
  {
    ua: 'Приватизація',
    ru: 'Приватизация',
    en: 'Privatization',
    de: 'Privatisierung',
    fr: 'Privatisation',
    es: 'Privatización',
    cz: 'Privatizace',
  },
  {
    ua: 'Міграційні',
    ru: 'Миграционные',
    en: 'Migration issues',
    de: 'Migrationsfragen',
    fr: 'Questions de migration',
    es: 'Asuntos migratorios',
    cz: 'Migrační záležitosti',
  },
  {
    ua: 'Крим загальнокримінальні',
    ru: 'Крим общекриминальные',
    en: 'General criminal cases',
    de: 'Allgemeine Straffälle',
    fr: 'Affaires criminelles générales',
    es: 'Casos criminales generales',
    cz: 'Obecné trestní případy',
  },
  {
    ua: 'Соц виплати',
    ru: 'Соц выплаты',
    en: 'Social payments',
    de: 'Sozialleistungen',
    fr: 'Allocations sociales',
    es: 'Pagos sociales',
    cz: 'Sociální dávky',
  },
  {
    ua: 'Сусід взяв кредит',
    ru: 'Сосед взял кредит',
    en: 'Neighbor took a loan',
    de: 'Nachbar nahm ein Darlehen auf',
    fr: 'Voisin a pris un prêt',
    es: 'Vecino tomó un préstamo',
    cz: 'Soused si vzal půjčku',
  },
  {
    ua: 'Правовий аналіз',
    ru: 'Правовой анализ',
    en: 'Legal analysis',
    de: 'Rechtsanalyse',
    fr: 'Analyse juridique',
    es: 'Análisis legal',
    cz: 'Právní analýza',
  },
  {
    ua: 'Податки',
    ru: 'Налоги',
    en: 'Taxes',
    de: 'Steuern',
    fr: 'Impôts',
    es: 'Impuestos',
    cz: 'Daně',
  },
  {
    ua: 'Встановлення юр факту',
    ru: 'Установление юр факта',
    en: 'Establishment of a legal fact',
    de: 'Feststellung eines rechtlichen Tatbestands',
    fr: 'Établissement de fait juridique',
    es: 'Establecimiento de hecho legal',
    cz: 'Stanovení právního faktu',
  },
  {
    ua: 'Військкомат відкоси',
    ru: 'Военкомат откосы',
    en: 'Military enlistment office deferrals',
    de: 'Wehrersatzamt Aufschübe',
    fr: 'Report de service militaire',
    es: 'Prórroga en la oficina de reclutamiento',
    cz: 'Odklady vojenské služby',
  },
  {
    ua: 'Авто інші питання',
    ru: 'Авто другие вопросы',
    en: 'Auto-related issues',
    de: 'Autobezogene Fragen',
    fr: "Questions liées à l'auto",
    es: 'Cuestiones relacionadas con el auto',
    cz: 'Automobilové záležitosti',
  },
  {
    ua: 'Захист прав потреб',
    ru: 'Защита прав потребителей',
    en: 'Consumer rights protection',
    de: 'Verbraucherschutz',
    fr: 'Protection des droits des consommateurs',
    es: 'Protección de derechos del consumidor',
    cz: 'Ochrana práv spotřebitelů',
  },
  {
    ua: 'Опіка та піклування',
    ru: 'Опека и попечительство',
    en: 'Guardianship and custody',
    de: 'Vormundschaft und Pflege',
    fr: 'Tutelle et curatelle',
    es: 'Tutela y custodia',
    cz: 'Ochrana a péče',
  },
  {
    ua: 'Відшкодування за навчання',
    ru: 'Возмещение за обучение',
    en: 'Reimbursement for education',
    de: 'Rückerstattung für Bildung',
    fr: "Remboursement pour l'éducation",
    es: 'Reembolso por educación',
    cz: 'Náhrada za vzdělání',
  },
  {
    ua: 'Медична помилка',
    ru: 'Медицинская ошибка',
    en: 'Medical error',
    de: 'Medizinischer Fehler',
    fr: 'Erreur médicale',
    es: 'Error médico',
    cz: 'Lékařská chyba',
  },
  {
    ua: 'Позбавлення батьківських прав',
    ru: 'Лишение родительских прав',
    en: 'Termination of parental rights',
    de: 'Entzug des Sorgerechts',
    fr: 'Retrait de droits parentaux',
    es: 'Pérdida de derechos parentales',
    cz: 'Zbavení rodičovských práv',
  },
  {
    ua: 'Сімейні інші',
    ru: 'Семейные прочие',
    en: 'Other family issues',
    de: 'Andere Familienangelegenheiten',
    fr: 'Autres questions familiales',
    es: 'Otros asuntos familiares',
    cz: 'Další rodinné záležitosti',
  },
  {
    ua: 'Реєстрація спд',
    ru: 'Регистрация спд',
    en: 'Registration of an individual entrepreneur',
    de: 'Registrierung eines Einzelunternehmers',
    fr: "Enregistrement d'un entrepreneur individuel",
    es: 'Registro de empresario individual',
    cz: 'Registrace OSVČ',
  },
  {
    ua: 'Ліквідація спд',
    ru: 'Ликвидация спд',
    en: 'Dissolution of an individual entrepreneur',
    de: 'Auflösung eines Einzelunternehmers',
    fr: "Dissolution d'un entrepreneur individuel",
    es: 'Disolución de empresario individual',
    cz: 'Likvidace OSVČ',
  },
  {
    ua: 'Аутсорсінг спд',
    ru: 'Аутсорсинг спд',
    en: 'Outsourcing of individual entrepreneur',
    de: 'Outsourcing eines Einzelunternehmers',
    fr: "Externalisation d'entrepreneur individuel",
    es: 'Subcontratación de empresario individual',
    cz: 'Outsourcing OSVČ',
  },
  {
    ua: 'Господарській спір',
    ru: 'Хозяйственный спор',
    en: 'Commercial dispute',
    de: 'Wirtschaftsstreit',
    fr: 'Conflit commercial',
    es: 'Disputa comercial',
    cz: 'Hospodářský spor',
  },
  {
    ua: 'Юр страх консультація',
    ru: 'Юр страх консультация',
    en: 'Legal insurance consultation',
    de: 'Rechtsberatung Versicherung',
    fr: 'Consultation assurance juridique',
    es: 'Consulta de seguro legal',
    cz: 'Konzultace právního pojištění',
  },
  {
    ua: 'Юр страх активація',
    ru: 'Юр страх активация',
    en: 'Legal insurance activation',
    de: 'Rechtsversicherung Aktivierung',
    fr: "Activation d'assurance juridique",
    es: 'Activación de seguro legal',
    cz: 'Aktivace právního pojištění',
  }, // 54
  '-', // 55
  '-', // 56
  '-', // 57
  {
    ua: 'Клубна карта',
    ru: 'Клубная карта',
    en: 'Club card',
    de: 'Clubkarte',
    fr: 'Carte de club',
    es: 'Tarjeta de club',
    cz: 'Klubová karta',
  },
  {
    ua: 'Адмін інше',
    ru: 'Админ прочее',
    en: 'Administrative other',
    de: 'Verwaltung Sonstiges',
    fr: 'Administratif autre',
    es: 'Administrativo otros',
    cz: 'Administrativa jiné',
  },
  {
    ua: 'Адмін оскарження дій',
    ru: 'Админ обжалование действий',
    en: 'Administrative action appeal',
    de: 'Verwaltungsbeschwerde',
    fr: 'Recours administratif',
    es: 'Apelación de acción administrativa',
    cz: 'Správní odvolání',
  },
  {
    ua: 'Громадянство',
    ru: 'Гражданство',
    en: 'Citizenship',
    de: 'Staatsbürgerschaft',
    fr: 'Citoyenneté',
    es: 'Ciudadanía',
    cz: 'Občanství',
  },
  {
    ua: 'Невиплата зп',
    ru: 'Невыплата зп',
    en: 'Unpaid salary',
    de: 'Nicht gezahlter Lohn',
    fr: 'Salaire impayé',
    es: 'Salario impago',
    cz: 'Nezaplacená mzda',
  },
  {
    ua: 'Звільнення',
    ru: 'Увольнение',
    en: 'Dismissal',
    de: 'Entlassung',
    fr: 'Licenciement',
    es: 'Despido',
    cz: 'Propuštění',
  },
  {
    ua: 'Трудові стягнення шкоди',
    ru: 'Трудовые взыскания ущерба',
    en: 'Labor damages recovery',
    de: 'Arbeitsrechtliche Schadensersatzforderungen',
    fr: 'Recouvrement de dommages au travail',
    es: 'Recuperación de daños laborales',
    cz: 'Vymáhání škody z pracovního poměru',
  },
  {
    ua: 'Аварії та нещасні випадки',
    ru: 'Аварии и несчастные случаи',
    en: 'Accidents and incidents',
    de: 'Unfälle und Zwischenfälle',
    fr: 'Accidents et incidents',
    es: 'Accidentes e incidentes',
    cz: 'Nehody a úrazy',
  },
  {
    ua: 'Війна компенсація',
    ru: 'Война компенсация',
    en: 'War compensation',
    de: 'Kriegsentschädigung',
    fr: 'Indemnisation de guerre',
    es: 'Compensación por guerra',
    cz: 'Válečná náhrada',
  },
  {
    ua: 'Авторське право',
    ru: 'Авторское право',
    en: 'Copyright',
    de: 'Urheberrecht',
    fr: "Droit d'auteur",
    es: 'Derechos de autor',
    cz: 'Autorské právo',
  },
  {
    ua: 'СЗЧ',
    ru: 'СОЧ',
    en: 'Unauthorized Leave of Unit',
    de: 'Unerlaubtes Verlassen der Einheit',
    fr: "Abandon non autorisé de l'unité",
    es: 'Abandono no autorizado de la unidad',
    cz: 'Neoprávněné opuštění jednotky',
  },
  {
    ua: 'Тривожна кнопка',
    ru: 'Тревожная кнопка',
    en: 'Panic button',
    de: 'Panikknopf',
    fr: 'Bouton de panique',
    es: 'Botón de pánico',
    cz: 'Tísňové tlačítko',
  },
  '-', // 69
  '-', // 70
  {
    ua: 'Бронювання',
    ru: 'Бронирование',
    en: 'Reservation',
    de: 'Reservierung',
    fr: 'Réservation',
    es: 'Reserva',
    cz: 'Rezervace',
  },
  {
    ua: 'Оскарження ВЛК',
    ru: 'Обжалование ВЛК',
    en: 'Military Medical Commission appeal',
    de: 'Berufung der Militärärztlichen Kommission',
    fr: 'Appel de la commission médicale militaire',
    es: 'Apelación de la Comisión Médica Militar',
    cz: 'Odvolání vojenské lékařské komise',
  },
  {
    ua: 'Повістки поштою',
    ru: 'Повестки почтой',
    en: 'Summons by mail',
    de: 'Vorladungen per Post',
    fr: 'Assignations par courrier',
    es: 'Citaciones por correo',
    cz: 'Předvolání poštou',
  },
  {
    ua: 'Відстрочка',
    ru: 'Отсрочка',
    en: 'Deferment',
    de: 'Aufschub',
    fr: 'Report',
    es: 'Prórroga',
    cz: 'Odklad',
  }, // 74
];

const PRAVO_FOR_CLIENT = [
  '', // Не консультація
  {
    ua: 'Кредитні справи (з заставою)',
    ru: 'Кредитные дела (с залогом)',
    en: 'Credit cases (with collateral)',
    de: 'Kreditfälle (mit Sicherheiten)',
    fr: 'Affaires de crédit (avec garantie)',
    es: 'Casos de crédito (con garantía)',
    cz: 'Úvěrové případy (se zástavou)',
  },
  {
    ua: 'Кредитні справи (без застави)',
    ru: 'Кредитные дела (без залога)',
    en: 'Credit cases (without collateral)',
    de: 'Kreditfälle (ohne Sicherheiten)',
    fr: 'Affaires de crédit (sans garantie)',
    es: 'Casos de crédito (sin garantía)',
    cz: 'Úvěrové případy (bez zástavy)',
  },
  {
    ua: 'Мікропозики в МФО',
    ru: 'Микрозаймы в МФО',
    en: 'Microloans in MFIs',
    de: 'Mikrokredite in MFI',
    fr: 'Microcrédits dans les MFI',
    es: 'Microcréditos en IFM',
    cz: 'Mikroúvěry v MFI',
  },
  {
    ua: 'Розірвання шлюбу',
    ru: 'Развод',
    en: 'Divorce',
    de: 'Scheidung',
    fr: 'Divorce',
    es: 'Divorcio',
    cz: 'Rozvod',
  },
  {
    ua: 'Стягнення аліментів',
    ru: 'Взыскание алиментов',
    en: 'Child support collection',
    de: 'Eintreibung von Unterhalt',
    fr: 'Recouvrement de pension alimentaire',
    es: 'Cobro de pensión alimenticia',
    cz: 'Vymáhání výživného',
  },
  {
    ua: 'Поділ майна подружжя',
    ru: 'Раздел имущества супругов',
    en: 'Division of marital property',
    de: 'Teilung des Ehevermögens',
    fr: 'Partage des biens conjugaux',
    es: 'División de bienes conyugales',
    cz: 'Dělení manželského majetku',
  },
  {
    ua: 'Суперечка за дітей',
    ru: 'Спор за детей',
    en: 'Child custody dispute',
    de: 'Sorgerechtsstreit',
    fr: 'Litige pour la garde des enfants',
    es: 'Disputa por custodia de hijos',
    cz: 'Spor o děti',
  },
  {
    ua: 'Не кримінальні ДТП',
    ru: 'Некриминальные ДТП',
    en: 'Non-criminal road accidents',
    de: 'Nicht-kriminelle Verkehrsunfälle',
    fr: 'Accidents de la route non criminels',
    es: 'Accidentes de tráfico no criminales',
    cz: 'Nekriminální dopravní nehody',
  },
  {
    ua: 'Кримінальні ДТП',
    ru: 'Криминальные ДТП',
    en: 'Criminal road accidents',
    de: 'Kriminelle Verkehrsunfälle',
    fr: 'Accidents de la route criminels',
    es: 'Accidentes de tráfico criminales',
    cz: 'Kriminální dopravní nehody',
  },
  {
    ua: '130 КУпАП',
    ru: '130 КУпАП',
    en: 'Article 130 of the Administrative Code',
    de: 'Artikel 130 des Verwaltungsgesetzbuches',
    fr: 'Article 130 du Code administratif',
    es: 'Artículo 130 del Código Administrativo',
    cz: 'Článek 130 Správního řádu',
  },
  '', // Не направляти
  {
    ua: 'Суд з банком',
    ru: 'Суд с банком',
    en: 'Court with bank',
    de: 'Gericht mit der Bank',
    fr: 'Litige avec la banque',
    es: 'Juicio con el banco',
    cz: 'Soud s bankou',
  },
  {
    ua: 'Пенсійні справи',
    ru: 'Пенсионные дела',
    en: 'Pension cases',
    de: 'Rentensachen',
    fr: 'Affaires de pension',
    es: 'Asuntos de pensión',
    cz: 'Důchodové případy',
  },
  {
    ua: 'Пенсії силовиків',
    ru: 'Пенсии силовиков',
    en: 'Pensions for law enforcement',
    de: 'Rentner im Strafvollzug',
    fr: 'Pensions des forces de l’ordre',
    es: 'Pensiones de fuerzas de seguridad',
    cz: 'Důchody bezpečnostních složek',
  },
  {
    ua: 'Земельні справи',
    ru: 'Земельные дела',
    en: 'Land cases',
    de: 'Landrecht',
    fr: 'Affaires foncières',
    es: 'Asuntos de tierras',
    cz: 'Pozemkové případy',
  },
  {
    ua: 'Питання по нерухомості',
    ru: 'Вопросы недвижимости',
    en: 'Real estate issues',
    de: 'Immobilienfragen',
    fr: 'Questions immobilières',
    es: 'Cuestiones de bienes raíces',
    cz: 'Otázky nemovitostí',
  },
  {
    ua: 'Спори щодо комун. послуг',
    ru: 'Споры по коммунальным услугам',
    en: 'Disputes regarding utility services',
    de: 'Streitigkeiten über Versorgungsleistungen',
    fr: 'Litiges concernant les services publicz',
    es: 'Disputas sobre servicios públicos',
    cz: 'Spory o komunální služby',
  },
  {
    ua: 'Спадкові справи',
    ru: 'Наследственные дела',
    en: 'Inheritance cases',
    de: 'Erbschaftsfälle',
    fr: 'Affaires successorales',
    es: 'Casos de herencia',
    cz: 'Dědické případy',
  },
  {
    ua: 'Трудові справи',
    ru: 'Трудовые дела',
    en: 'Labor cases',
    de: 'Arbeitsrecht',
    fr: 'Affaires de travail',
    es: 'Casos laborales',
    cz: 'Pracovní případy',
  },
  {
    ua: 'Проблеми з ОСББ',
    ru: 'Проблемы с ОСМД',
    en: 'Issues with HOA',
    de: 'Probleme mit HOA',
    fr: 'Problèmes avec l’association des copropriétaires',
    es: 'Problemas con la HOA',
    cz: 'Problémy se SVJ',
  },
  {
    ua: 'Виконавчі справи',
    ru: 'Исполнительные дела',
    en: 'Enforcement cases',
    de: 'Vollstreckungsverfahren',
    fr: 'Affaires exécutoires',
    es: 'Casos de ejecución',
    cz: 'Exekuční případy',
  },
  {
    ua: 'Житлові справи',
    ru: 'Жилищные дела',
    en: 'Housing cases',
    de: 'Wohnungssachen',
    fr: 'Affaires de logement',
    es: 'Casos de vivienda',
    cz: 'Bytové případy',
  },
  {
    ua: "Проблеми пов'язані з COVID",
    ru: 'Проблемы, связанные с COVID',
    en: 'COVID-related issues',
    de: 'COVID-bezogene Probleme',
    fr: 'Problèmes liés au COVID',
    es: 'Problemas relacionados con COVID',
    cz: 'COVID-související problémy',
  },
  {
    ua: 'Військові справи',
    ru: 'Военные дела',
    en: 'Military cases',
    de: 'Militärangelegenheiten',
    fr: 'Affaires militaires',
    es: 'Casos militares',
    cz: 'Vojenské případy',
  },
  {
    ua: 'Побутові конфлікти',
    ru: 'Бытовые конфликты',
    en: 'Domestic conflicts',
    de: 'Hausliche Konflikte',
    fr: 'Conflits domestiques',
    es: 'Conflictos domésticos',
    cz: 'Domácí konflikty',
  },
  {
    ua: 'Оформлення субсидій',
    ru: 'Оформление субсидий',
    en: 'Subsidy application',
    de: 'Subventionsantrag',
    fr: 'Demande de subvention',
    es: 'Solicitud de subsidio',
    cz: 'Žádost o dotaci',
  },
  {
    ua: 'Інтелектуальна власність',
    ru: 'Интеллектуальная собственность',
    en: 'Intellectual property',
    de: 'Geistiges Eigentum',
    fr: 'Propriété intellectuelle',
    es: 'Propiedad intelectual',
    cz: 'Duševní vlastnictví',
  },
  {
    ua: 'Шахрайство',
    ru: 'Мошенничество',
    en: 'Fraud',
    de: 'Betrug',
    fr: 'Fraude',
    es: 'Fraude',
    cz: 'Podvod',
  },
  {
    ua: 'Проблеми з депозитом',
    ru: 'Проблемы с депозитом',
    en: 'Deposit issues',
    de: 'Einzahlungsprobleme',
    fr: 'Problèmes de dépôt',
    es: 'Problemas con depósitos',
    cz: 'Problémy s vkladem',
  },
  {
    ua: 'Шахрайство по кредиту',
    ru: 'Кредитное мошенничество',
    en: 'Credit fraud',
    de: 'Kreditbetrug',
    fr: 'Fraude au crédit',
    es: 'Fraude crediticia',
    cz: 'Úvěrový podvod',
  },
  {
    ua: 'Затоплення',
    ru: 'Затопление',
    en: 'Flooding',
    de: 'Überschwemmung',
    fr: 'Inondation',
    es: 'Inundación',
    cz: 'Záplava',
  },
  {
    ua: 'Виселення з житла',
    ru: 'Выселение из жилья',
    en: 'Eviction',
    de: 'Zwangsräumung',
    fr: 'Expulsion',
    es: 'Desalojo',
    cz: 'Vystěhování',
  },
  {
    ua: 'Приватизація нерухомості',
    ru: 'Приватизация недвижимости',
    en: 'Real estate privatization',
    de: 'Immobilienprivatisierung',
    fr: 'Privatisation immobilière',
    es: 'Privatización de bienes inmuebles',
    cz: 'Privatizace nemovitostí',
  },
  {
    ua: 'Міграційні справи',
    ru: 'Миграционные дела',
    en: 'Migration cases',
    de: 'Migrationsangelegenheiten',
    fr: 'Affaires migratoires',
    es: 'Casos de migración',
    cz: 'Migrační případy',
  },
  {
    ua: 'Кримінальні справи',
    ru: 'Уголовные дела',
    en: 'Criminal cases',
    de: 'Strafsachen',
    fr: 'Affaires pénales',
    es: 'Casos criminales',
    cz: 'Trestní případy',
  },
  {
    ua: 'Питання по соц. виплатам',
    ru: 'Вопросы по соц. выплатам',
    en: 'Social benefits issues',
    de: 'Sozialleistungsfragen',
    fr: 'Questions relatives aux prestations sociales',
    es: 'Cuestiones sobre prestaciones sociales',
    cz: 'Otázky ohledně sociálních dávek',
  },
  {
    ua: 'Проблеми з кредитною порукою',
    ru: 'Проблемы с кредитным поручительством',
    en: 'Credit surety issues',
    de: 'Probleme mit der Kreditsicherheit',
    fr: 'Problèmes de caution de crédit',
    es: 'Problemas con la garantía de crédito',
    cz: 'Problémy s kreditní zárukou',
  },
  {
    ua: 'Впорядкування документів',
    ru: 'Упорядочение документов',
    en: 'Document organization',
    de: 'Dokumentenorganisation',
    fr: 'Organisation des documents',
    es: 'Organización de documentos',
    cz: 'Uspořádání dokumentů',
  },
  {
    ua: 'Податкові справи',
    ru: 'Налоговые дела',
    en: 'Tax cases',
    de: 'Steuerfälle',
    fr: 'Affaires fiscales',
    es: 'Casos fiscales',
    cz: 'Daňové případy',
  },
  {
    ua: 'Встановлення юр. факту',
    ru: 'Установление юр. факта',
    en: 'Establishment of legal fact',
    de: 'Feststellung rechtlicher Tatsachen',
    fr: 'Établissement d’un fait juridique',
    es: 'Establecimiento de hecho jurídico',
    cz: 'Stanovení právní skutečnosti',
  },
  {
    ua: 'Питання по мобілізації',
    ru: 'Вопросы по мобилизации',
    en: 'Mobilization issues',
    de: 'Mobilisierungsfragen',
    fr: 'Questions sur la mobilisation',
    es: 'Cuestiones de movilización',
    cz: 'Otázky ohledně mobilizace',
  },
  {
    ua: 'Юридичні питання по авто',
    ru: 'Юридические вопросы по авто',
    en: 'Legal issues related to cars',
    de: 'Rechtsfragen zu Autos',
    fr: 'Questions juridiques liées aux voitures',
    es: 'Cuestiones legales relacionadas con automóviles',
    cz: 'Právní otázky týkající se automobilů',
  },
  {
    ua: 'Захист прав споживачів',
    ru: 'Защита прав потребителей',
    en: 'Consumer rights protection',
    de: 'Verbraucherrechtsschutz',
    fr: 'Protection des droits des consommateurs',
    es: 'Protección de derechos del consumidor',
    cz: 'Ochrana práv spotřebitelů',
  },
  {
    ua: 'Опіка чи піклування',
    ru: 'Опека или попечительство',
    en: 'Guardianship or custody',
    de: 'Vormundschaft oder Pflege',
    fr: 'Tutelle ou curatelle',
    es: 'Tutela o custodia',
    cz: 'Opatrovnictví nebo péče',
  },
  {
    ua: 'Відшкодування коштів за навчання',
    ru: 'Возмещение средств за обучение',
    en: 'Tuition reimbursement',
    de: 'Erstattung der Studiengebühren',
    fr: 'Remboursement des frais de scolarité',
    es: 'Reembolso de matrícula',
    cz: 'Náhrada školného',
  },
  {
    ua: 'Медична помилка',
    ru: 'Медицинская ошибка',
    en: 'Medical malpractice',
    de: 'Ärztlicher Fehler',
    fr: 'Erreur médicale',
    es: 'Error médico',
    cz: 'Lékařská chyba',
  },
  {
    ua: 'Позбавлення батьківських прав',
    ru: 'Лишение родительских прав',
    en: 'Termination of parental rights',
    de: 'Entzug des Sorgerechts',
    fr: 'Retrait de l’autorité parentale',
    es: 'Pérdida de derechos parentales',
    cz: 'Zbavení rodičovských práv',
  },
  {
    ua: 'Сімейні питання',
    ru: 'Семейные вопросы',
    en: 'Family matters',
    de: 'Familienangelegenheiten',
    fr: 'Affaires familiales',
    es: 'Asuntos familiares',
    cz: 'Rodinné záležitosti',
  },
  {
    ua: 'Реєстрація СПД',
    ru: 'Регистрация ФЛП',
    en: 'Business registration',
    de: 'Gewerbeanmeldung',
    fr: 'Enregistrement d’entreprise',
    es: 'Registro de empresa',
    cz: 'Registrace podnikatele',
  },
  {
    ua: 'Ліквідація СПД',
    ru: 'Ликвидация ФЛП',
    en: 'Business liquidation',
    de: 'Gewerbeabmeldung',
    fr: 'Liquidation d’entreprise',
    es: 'Liquidación de empresa',
    cz: 'Likvidace podnikatele',
  },
  {
    ua: 'Юридичний аутсорсінг СПД',
    ru: 'Юридический аутсорсинг ФЛП',
    en: 'Legal outsourcing for businesses',
    de: 'Rechtsoutsourcing für Unternehmen',
    fr: 'Externalisation juridique pour entreprises',
    es: 'Outsourcing legal para empresas',
    cz: 'Právní outsourcing pro firmy',
  },
  {
    ua: 'Господарській спір',
    ru: 'Хозяйственный спор',
    en: 'Commercial dispute',
    de: 'Wirtschaftlicher Streit',
    fr: 'Litige commercial',
    es: 'Disputa comercial',
    cz: 'Obchodní spor',
  },
  {
    ua: 'Оформлення юридичної страховки',
    ru: 'Оформление юридической страховки',
    en: 'Legal insurance arrangement',
    de: 'Rechtsversicherungsabschluss',
    fr: 'Souscription d’assurance juridique',
    es: 'Contratación de seguro jurídico',
    cz: 'Zřízení právního pojištění',
  },
  {
    ua: 'Оформлення юридичної страховки',
    ru: 'Оформление юридической страховки',
    en: 'Legal insurance arrangement',
    de: 'Rechtsversicherungsabschluss',
    fr: 'Souscription d’assurance juridique',
    es: 'Contratación de seguro jurídico',
    cz: 'Zřízení právního pojištění',
  },
  '-',
  '-',
  '-',
  {
    ua: 'Оформлення Клубної карти',
    ru: 'Оформление клубной карты',
    en: 'Club card issuance',
    de: 'Ausstellung der Clubkarte',
    fr: 'Émission de carte de club',
    es: 'Emisión de tarjeta de club',
    cz: 'Vystavení klubové karty',
  },
  {
    ua: 'Адміністративні справи',
    ru: 'Административные дела',
    en: 'Administrative cases',
    de: 'Verwaltungsfälle',
    fr: 'Affaires administratives',
    es: 'Casos administrativos',
    cz: 'Správní případy',
  },
  {
    ua: 'Оскарження дій посадових осіб',
    ru: 'Обжалование действий должностных лиц',
    en: 'Appeal against officials’ actions',
    de: 'Anfechtung von Amtshandlungen',
    fr: 'Contestations des actions des fonctionnaires',
    es: 'Impugnación de actos de funcionarios',
    cz: 'Odvolání proti činnosti úředníků',
  },
  {
    ua: 'Питання стосовно громадянства',
    ru: 'Вопросы гражданства',
    en: 'Citizenship issues',
    de: 'Staatsbürgerschaftsfragen',
    fr: 'Questions de citoyenneté',
    es: 'Cuestiones de ciudadanía',
    cz: 'Otázky ohledně státního občanství',
  },
  {
    ua: 'Невиплата ЗП',
    ru: 'Невыплата зарплаты',
    en: 'Unpaid salary',
    de: 'Unbezahltes Gehalt',
    fr: 'Salaire impayé',
    es: 'Salario no pagado',
    cz: 'Nezaplacená mzda',
  },
  {
    ua: 'Незаконне звільнення',
    ru: 'Незаконное увольнение',
    en: 'Wrongful dismissal',
    de: 'Unrechtmäßige Kündigung',
    fr: 'Licenciement abusif',
    es: 'Despido injustificado',
    cz: 'Nezákonné propuštění',
  },
  {
    ua: 'Травма на виробництві',
    ru: 'Травма на производстве',
    en: 'Workplace injury',
    de: 'Arbeitsunfall',
    fr: 'Blessure au travail',
    es: 'Lesión laboral',
    cz: 'Úraz na pracovišti',
  },
  {
    ua: 'Аварії та нещасні випадки',
    ru: 'Аварии и несчастные случаи',
    en: 'Accidents and incidents',
    de: 'Unfälle und Zwischenfälle',
    fr: 'Accidents et incidents',
    es: 'Accidentes y percances',
    cz: 'Nehody a incidenty',
  },
  {
    ua: 'Питання щодо компенсації та відшкодування',
    ru: 'Вопросы компенсации и возмещения',
    en: 'Compensation and reimbursement issues',
    de: 'Entschädigungsfragen',
    fr: 'Questions de compensation et remboursement',
    es: 'Cuestiones de compensación y reembolso',
    cz: 'Otázky ohledně kompenzace',
  },
  {
    ua: 'Авторське право',
    ru: 'Авторское право',
    en: 'Copyright',
    de: 'Urheberrecht',
    fr: 'Droits d’auteur',
    es: 'Derechos de autor',
    cz: 'Autorská práva',
  },
  {
    ua: 'Справи по СЗЧ та дезертирству',
    ru: 'Дела по СЗЧ и дезертирству',
    en: 'AWOL and desertion cases',
    de: 'Fälle von Abwesenheit und Desertion',
    fr: 'Affaires d’absence illégale et désertion',
    es: 'Casos de ausencia sin permiso y deserción',
    cz: 'Případy AWOL a dezerce',
  },
  {
    ua: 'Тривожна кнопка',
    ru: 'Тревожная кнопка',
    en: 'Panic button',
    de: 'Notrufknopf',
    fr: 'Bouton de panique',
    es: 'Botón de pánico',
    cz: 'Tísňové tlačítko',
  },
  '-',
  {
    ua: 'Бронювання',
    ru: 'Бронирование',
    en: 'Booking',
    de: 'Buchung',
    fr: 'Réservation',
    es: 'Reserva',
    cz: 'Rezervace',
  },
  {
    ua: 'Оскарження ВЛК',
    ru: 'Обжалование ВЛК',
    en: 'Appeal against military medical board',
    de: 'Einspruch gegen die militärische Ärztekammer',
    fr: 'Contestations de la commission médicale militaire',
    es: 'Impugnación de la junta médica militar',
    cz: 'Odvolání proti vojenské lékařské komisi',
  },
  {
    ua: 'Повістки поштою',
    ru: 'Повестки по почте',
    en: 'Draft notices by mail',
    de: 'Einberufungsbescheide per Post',
    fr: 'Convocations par la poste',
    es: 'Notificaciones de reclutamiento por correo',
    cz: 'Předvolání poštou',
  },
  {
    ua: 'Відстрочка',
    ru: 'Отсрочка',
    en: 'Deferment',
    de: 'Aufschub',
    fr: 'Ajournement',
    es: 'Aplazamiento',
    cz: 'Odklad',
  }, // 74
];

const HOTLINE_STATUS = ['Без статусу', 'Зустріч Проведено', 'Зустріч не проведено', 'Без Вайберу'];
const HOTLINE_RESULT = ['Не укладено', 'Укладено', 'Укладено та оплачено'];

const HOTLINE_RESULT_REACH_FILTER = ['Не укладено', 'Укладено'];

const QuE_STATUS = ['Без статусу', 'Анкетування проведено', 'Анкетування не проведено', 'Без Вайберу'];
const HOTLINE_MEET = ['-', 'Була', 'Не була', 'Не вірний номер телефону'];

const WHY_NOT_USE_APP = ['Проблема з телефоном', 'Немає зв’язку', 'Номер не UA, DE, CZ'];
const HOTLINE_ADV_WHERE = [
  'Не вказано',
  'Інтернет',
  'Транспорт',
  'Зовнішня реклама',
  'СМС повідомлення',
  'Національні ЗМІ',
  'Регіональні ЗМІ',
  'Група, блог, відома особа',
  'Сарафанне радіо',
  'Своя реклама',
  'Метрополітен',
  "Інтерв'ю",
  'Інше',
];

const HOTLINE_ADV_WHERE_MORE = [
  'Не вказано',
  'Фейсбук',
  'Інстаграм',
  'Гугл пошук',
  'Ютуб',
  'Телеграм',
  'ТікТок',
  'Твітер',
  'Вконтакті',
  'Однокласники',
  'Інша соц мережа або сайт',
  'Додаток',
  'Яндекс',
  'Поїзд',
  'Метро вагон',
  'Трамвай',
  'Тролейтус',
  'Маршрутка',
  'Автобус',
  'Таксі',
  'Човен',
  'Літак',
  'Наклейка',
  'Вивіска',
  'Щит-мимохід',
  'Банер-розтяжка',
  'Лайтбокс',
  'Табличка на вулиці',
  'Оголошення на вулиці',
  'Візитівка на вулиці',
  'Бігборд',
  'Рекламна дошка',
  'Зовнішнє зображення на транспорті',
  'Дорожній знак',
  'Проектор',
  'Напис під ногами',
  'Ледекран',
  'Палатка',
  'Промоакція',
  'Сувеніри, подарунки',
  'Аудіореклама',
  'Айбокс, термінал',
  'На інших товарах',
  'На платіжках',
  'Лекція',
  'Тату',
  'СМС Інший оператор',
  'СМС Водофон',
  'СМС Київстар',
  'СМС Лайф',
  'Лист у E-mail',
  'СМС у Вайбер',
  'СМС у Телеграм',
  'Телебачення',
  'Радіо',
  'Газета',
  'Інтернет ЗМІ',
  'Звернувся повторно',
  'Порекомендував малознайомий',
  'Ваш дзвінок',
  'Дізнався від знайомого',
  'Відома особа',
  'Благодійність',
  'Колоборація',
  'Агент за гроші',
];

const TOWNS = [
  'Інше місто',
  'Київ',
  'Бар',
  'Біла Церква',
  'Бердянськ',
  'Борислав',
  'Вінниця',
  'Горішні Плавні',
  'Глухів',
  'Дніпро',
  'Донецьк',
  'Запоріжжя',
  'Івано-Франківськ',
  'Кагарлик',
  "Кам'янець-Подільський",
  'Кременчук',
  'Кривий Ріг',
  'Кропивницький',
  'Луганськ',
  'Луцьк',
  'Львів',
  'Маріуполь',
  'Миколаїв',
  'Нетішин',
  'Одеса',
  'Олександрія',
  'Олешки',
  'Перемишляни',
  'Пирятин',
  'Полтава',
  'Пологи',
  'Ржищів',
  'Рівне',
  'Сєвєродонецьк',
  'Сімферополь',
  "Слов'янськ",
  'Стрий',
  'Теребовля',
  'Тернопіль',
  'Харків',
  'Хмельницький',
  'Херсон',
  'Хуст',
  'Черкаси',
  'Чернівці',
  'Чернігів',
  'Чорноморськ',
  'Чортків',
  'Умань',
];

const REGIONS = [
  'Автономна Республіка Крим',
  'Вінницька',
  'Волинська',
  'Дніпропетровська',
  'Донецька',
  'Житомирська',
  'Закарпатська',
  'Запорізька',
  'Івано-Франківська',
  'Київська',
  'Кіровоградська',
  'Луганська',
  'Львівська',
  'Миколаївська',
  'Одеська',
  'Полтавська',
  'Рівненська',
  'Сумська',
  'Тернопільська',
  'Харківська',
  'Хмельницька',
  'Черкаська',
  'Чернівецька',
  'Чернігівська',
  'Інший регіон',
];

const FORM_OF_BUSINESS = ['ФО', 'ФОП', 'ЮО'];

const TYPES_OF_BANK_ACCOUNTS = [
  { label: 'Банківська картка', color: 'blue' },
  { label: 'Розрахунковий рахунок', color: 'purple' },
  { label: 'ФОП', color: 'cyan' },
];

const JOBS = {
  0: 'авіадиспетчер',
  1: 'автомобіліст',
  2: 'адвокат',
  3: 'акушерка',
  4: 'альпініст',
  5: 'анастезіолог',
  6: 'артилерист',
  7: 'археолог',
  8: 'архіваріус',
  9: 'архітектор',
  11: 'астроном',
  12: 'банківський працівник',
  13: 'бармен',
  14: 'бібліотекар',
  15: 'бортпровідник',
  16: 'будівельник',
  17: 'бухгалтер',
  19: 'веб-майстер',
  20: 'ветеринар',
  21: 'виконавець',
  22: 'винахідник',
  24: 'вихователь',
  25: 'військовий',
  28: 'військовий інженер',
  29: 'військовий ППО ЗСУ',
  30: 'вчитель',
  32: 'геолог',
  33: 'гідрометеоролог',
  34: 'гірник',
  35: 'далекобійник',
  36: 'десантник',
  37: 'дизайнер-графіст',
  38: 'дипломат',
  39: 'ді-джей',
  40: 'дільничний',
  41: 'донор',
  42: 'енергетик',
  44: 'етнограф',
  45: 'журналіст',
  47: 'залізничник',
  48: 'землевпорядник',
  49: 'зовнішній розвідник',
  50: 'інкасатор',
  51: 'інша професія',
  52: 'ІТ-працівник',
  53: 'кадровик',
  55: 'кінематографіст',
  56: 'кондитер',
  57: 'космонавт',
  58: 'лікар',
  59: 'лісничий',
  62: 'логіст',
  63: 'логопед',
  64: 'лялькар',
  65: 'маркетолог',
  66: 'машинобудівник',
  67: 'медик',
  69: 'медсестра',
  70: 'металург',
  71: 'метролог',
  73: 'миротворець',
  74: 'миротворець ООН',
  75: 'митний брокер',
  76: 'митник',
  79: 'морський піхотинець',
  80: 'моряк',
  81: 'музикант',
  82: 'найгвардієць',
  83: 'нотаріус',
  84: 'охоронець',
  85: 'парашутист',
  86: 'пасічник',
  87: 'пенсіонер',
  88: 'перекладач',
  89: 'перукар',
  90: 'письменник',
  92: 'підприємець',
  93: 'пілот',
  97: 'поет',
  98: 'пожежник',
  100: 'поліцейський',
  101: 'поштар',
  102: 'працівник видавництв, поліграфії тa книгорозповсюдження',
  103: 'працівник ВМС Україним',
  104: 'працівник водного господарства',
  105: 'працівник господарського суду',
  106: 'працівник ДАІ',
  107: 'працівник ДПС',
  108: 'працівник Дeржавної санітарно-епідеміологічної служби',
  109: 'працівник ЖЕКу',
  110: 'працівник кримінального розшуку',
  111: 'працівник культури',
  115: 'працівник легкої промисловості',
  116: 'працівник МВС',
  117: 'працівник місцевої промисловості',
  118: 'працівник музею',
  119: 'працівник нафтогазпрому',
  120: 'працівник освіти',
  121: 'працівник податкової служби',
  122: 'працівник ППС',
  123: 'працівник радіо,телебачення та зв`язку',
  124: 'працівник ракетних військ',
  125: 'працівник РАЦС',
  126: 'працівник СБУ',
  127: 'працівник соціальної сфери',
  128: 'працівник суду',
  129: 'працівник торгівлі',
  131: 'працівник флоту',
  132: 'працівник фондового ринку',
  133: 'працівник харчової промисловості',
  134: 'працівник целюлозно-паперової промисловості',
  135: 'прикордонник',
  136: 'програміст',
  137: 'проектний менеджер',
  138: 'прокурор',
  139: 'психолог',
  140: 'радист',
  141: 'радіоведучий',
  143: 'раціоналізатор',
  144: 'ревізор',
  145: 'рекламщик',
  146: 'рекрутер',
  147: 'рибалка',
  149: 'ріелтор',
  150: 'розвідник',
  151: 'рятувальник',
  153: 'сантехнік',
  154: 'секретар',
  155: 'системний адміністратор',
  156: 'скловиробник',
  157: 'слідчий',
  158: 'спортивний журналіст',
  159: 'спортсмен',
  160: 'стандартизатор',
  161: 'статист',
  162: 'стоматолог',
  163: 'студент',
  165: 'таксист',
  166: 'танкіст',
  167: 'танцюрист',
  168: 'теле, радіоведучий',
  169: 'телеведучий',
  170: 'тестувальник',
  171: 'травматолог',
  172: 'фармацевт',
  173: 'фермер',
  175: 'філолог',
  176: 'філософ',
  177: 'фотограф',
  178: 'хімік',
  179: 'шахіст',
  180: 'шахтар',
  181: 'ювелір',
  182: 'юморист',
  183: 'юрист',
  184: 'HR-менеджер',
  185: 'PR-спеціаліст',
  186: 'безробітний',
};

const SOC_STATUS = [
  'повна зайнятість',
  'часткова зайнятість',
  'робота за контрактом/сезонна',
  'зайнятий пенсіонер',
  'пенсіонер',
  'тимчасово не працює',
  'декретна відпустка',
  'студент',
  'домогосподар',
];

const WORK_STATES = [
  'робітник/менеждер/спеціалист',
  'керівник підприємства',
  'приватний підприємець',
  'нотаріус/адвокат/тощо',
  'військовослужбовець',
];

const HOBBIES = [
  'туризм, мандрівки, природа (полювання, риболовля, кемпінг, стрільба тощо)',
  'мистецтво (музика, література, живопис, архітектура, скульптура, театр, кіно, фото)',
  'техніка, космос, механіка, винаходи, історія, археологія, політика, новини',
  "комп'ютерні, настольні та інтелектуальні ігри, колекціонування",
  'інтернет, блогерство, соціальні мережі',
  'азартні ігри, алкоголь, наркотики',
  'спорт, здоровий спосіб життя',
  'кулінарія, рукоділля, дім, сад та город',
  'тварини, комахи, рослини',
  'потойбічне, релігія, теорії змов',
  'авто, ремонт, гараж, сантехніка];',
];

const RELATIONS = [
  'мати',
  'батько',
  'син',
  'донька',
  'чоловік',
  'дружина',
  'брат',
  'сестра',
  'дід',
  'бабка',
  'онук',
  'онука',
  'роботодавець',
  'колега',
  'знайомий',
  'сусід',
  'інший родич',
];

const CHARACTERISTIC = ['інтелект', 'критичність мислення', 'ініціативність', 'поступливість', 'дружелюбність', 'емоційність'];

const REPEATS = ['2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22'];
const PAYMENT_FORMAT = ['Повний гонорар', 'Гонорар в розстрочку', 'Щомісячна оплата', 'П Г + Щ О', 'Г в Р + Щ О'];
const PASSPORT_TYPE = ['ID-карта', 'Паспорт-книжечка'];

const CLIENT_COLOR = {
  0: {
    name: 'Звичайний',
    bg: 'inherit',
    hex: '#000',
  },
  1: {
    name: 'Золотий',
    bg: '#FFD700',
    hex: '#000',
  },
  2: {
    name: 'Коричневий',
    bg: '#8B4513',
    hex: '#fff',
  },
};

// Орієнтовне розподілення на блоки
const SERVIS_OPTIONS = ['Блок АНК', 'Блок Кредити', 'Блок Інші', 'Моя філія', 'Інша філія', 'Блок Пенсії', 'Блок Сімейні', 'Блок Військові'];

// Підтвержене розподілення на блоки
const LAW_BLOCKS = {
  1: 'Блок АНК',
  // 2: 'Блок АНК 2',
  // 3: 'Блок АНК 3',
  // 4: 'Блок АНК 4',
  // 5: 'Блок АНК 5',
  6: 'Блок Кредити',
  7: 'Блок Інші',
  8: 'Моя філія',
  9: 'Блок Пенсії',
  10: 'Блок Сімейні',
  11: 'Блок Військові',
};

const ARCHIVE_FEELS = ['п/х', 'п/с', 'п/п'];

const DECLINE_REASONS = [
  'Причини відмови',
  '1. Вже вирішив своє питання',
  '2. Звернувся до іншого юриста',
  '3. Негативні відгуки про АО Захист',
  '4. Думав що консультація безкоштовна',
  '5. Думав що послуги безкоштовні',
  '6. Недостатня зацікавленість у вирішення свого питання',
  '7. Особиста дезорганізація',
  '8. Наша помилка при записі',
  '9. Дистанційна консультація не бере трубку',
  '10. Офісна консультація не бере трубку',
  "11. Відсутній зв'язок на дистанційну консультацію",
  "12. Відсутній зв'язок на офісну консультацію",
  "13. Поганий зв'язок",
  '14. Проблеми з транспортом',
  '15. Проблеми в особистому житті та сім’ї',
  '16. Військові дії (тривоги, обстріли)',
  '17. Відсутність комунікацій (світло,вода і тд)',
  '18. Каже, що не записувався',
  '19. Накладка у консультанта',
  '20. Відмова консультанта у консультації',
];

const MEET_STATUS = {
  WAS: '1',
  CANCELED: '2',
  NO_ANSWER: '3',
};

const NOTIFICATION = {
  CLIENTS: 'clients',
  CORRESPONDENCE: 'correspondence',
  TASKS: 'tasks',
  EVENTS: 'events',
};

const ACCESS_TOKEN = 'accessToken';
const CLIENT_TOKEN = 'clientAccessToken';

const MONTHS = ['Січня', 'Лютого', 'Березня', 'Квітня', 'Травня', 'Червня', 'Липня', 'Серпня', 'Вересня', 'Жовтня', 'Листопада', 'Грудня'];

const MONTHS_SMALL = ['січня', 'лютого', 'березня', 'квітня', 'травня', 'червня', 'липня', 'серпня', 'вересня', 'жовтня', 'листопада', 'грудня'];

const MONTHS_CHANGE_OF_WORDS = [
  ['січень', 'січня', 'січневі', 'січню', 'січнем', 'січню'],
  ['лютий', 'лютого', 'лютому', 'лютим', 'лютому', 'лютім'],
  ['березень', 'березня', 'березневі', 'березню', 'березнем', 'березні'],
  ['квітень', 'квітня', 'квітневі', 'квітню', 'квітнем', 'квітні'],
  ['травень', 'травня', 'травневі', 'травню', 'травнем', 'травні'],
  ['червень', 'червня', 'червневі', 'червню', 'червнем', 'червні'],
  ['липень', 'липня', 'липневі', 'липню', 'липнем', 'липні'],
  ['серпень', 'серпня', 'серпневі', 'серпню', 'серпнем', 'серпні'],
  ['вересень', 'вересня', 'вересневі', 'вересню', 'вереснем', 'вересні'],
  ['жовтень', 'жовтня', 'жовтневі', 'жовтню', 'жовтнем', 'жовтні'],
  ['листопад', 'листопада', 'листопадові', 'листопаду', 'листопадом', 'листопаді'],
  ['грудень', 'грудня', 'грудневі', 'грудню', 'груднем', 'грудні'],
];

const JOURNAL_BADGE_COLORS = {
  GREEN: 'green',
  ORANGE: 'orange',
  MAGENTA: 'magenta',
  RED: 'red',
  GEEKBLUE: 'geekblue',
  PURPLE: 'purple',
};

const INSURANCE_PAYMENTS = {
  1: 'Оплата через клієнта',
  2: 'Оплата через юриста',
};

const INSURANCE_PERIOD = {
  6: '6 міс',
  12: '1 рік',
  36: '3 роки',
};

const INSURANCE_PERIOD_FOR_EADVOKAT = {
  ua: {
    6: '6 міс',
    12: '1 рік',
    36: '3 роки',
  },
  ru: {
    6: '6 мес',
    12: '1 год',
    36: '3 года',
  },
  de: {
    6: '6 Monate',
    12: '1 Jahr',
    36: '3 Jahre',
  },
  en: {
    6: '6 months',
    12: '1 year',
    36: '3 years',
  },
  fr: {
    6: '6 mois',
    12: '1 an',
    36: '3 ans',
  },
  es: {
    6: '6 meses',
    12: '1 año',
    36: '3 años',
  },
  cz: {
    6: '6 měsíců',
    12: '1 rok',
    36: '3 roky',
  },
};

const INSURANCE_MIN_PRICE = {
  6: 2100,
  12: 3600,
  36: 9000,
};

const INSURANCE_BALANCE_MULTIPLIER = 10; // INSURENCE_PRICE * 10

const INSURANCE_CAR_ACCIDENT_BONUSES = {
  6: 15000,
  12: 25000,
  36: 60000,
};

const INSURANCE_CAR_ACCIDENT = {
  0: {
    title: 'Консультація адвоката по ДТП',
    price: [300],
    details: '',
  },
  1: {
    title: 'Вивчення документів та формування правової позиції по справі',
    price: [1000],
    details: '',
  },
  2: {
    title: 'Ознайомлення з матеріалами справи',
    price: [1500],
    details: '',
  },
  3: {
    title: 'Досудове врегулювання спору',
    price: [1000, 5000],
    details: 'в залежності від складності справи;',
  },
  4: {
    title: 'Фіксований гонорар за ведення адміністративної справи по ДТП',
    price: [5000, 300],
    details: 'на місяць на поштово-канцелярські витрати;',
  },
  5: {
    title: 'Фіксований гонорар за ведення кримінальної справи по ДТП',
    price: [10000, 500],
    details: 'на місяць на поштово-канцелярські витрати;',
  },
  6: {
    title: 'Фіксований гонорар за ведення адміністративної справи щодо оскарження протоколу по факту іншого порушення ПДР',
    price: [4000, 250],
    details: 'на місяць на поштово-канцелярські витрати;',
  },
  7: {
    title: 'Виїзд адвоката на місце пригоди',
    price: [2000],
    details: '+ витрати на транспорт',
  },
  8: {
    title: 'Підготовка юридичного документу',
    price: [1000, 3000],
    details: 'в залежності від складності;',
  },
  9: {
    title: 'Підготовка позовної заяви',
    price: [4000],
    details: '',
  },
  10: {
    title: 'Підготовка апеляційної скарги',
    price: [3000],
    details: '',
  },
  11: {
    title: 'Підготовка касаційної скарги',
    price: [2000],
    details: '',
  },
  12: {
    title: 'Вихід адвоката (до поліції, в суд, тощо)',
    price: [2000],
    details: '+ витрати на транспорт;',
  },
  13: {
    title: 'Проведення претензійної роботи з опонентом',
    price: [1500],
    details: '',
  },
  14: {
    title: 'Участь адвоката в слідчих діях',
    price: [1500],
    details: '/ у годину;',
  },
  15: {
    title: 'Інші послуги надаються та тарифікуються за згодою сторін.',
    price: [0],
    details: '',
  },
};

const DECIMAL_RATING_TO_CLIENT = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

const JOB_SATISFACTION_TO_CLIENT = [
  'Результат задовольняє повністю',
  'Результат задовольняє частково',
  'Результат негативний, але не з вини юриста',
  'Результат не задовольняє повністю',
];

const JOB_SATISFACTION_TO_CLIENT_FOR_EADVOKAT = {
  ua: [
    'Результат задовольняє повністю',
    'Результат задовольняє частково',
    'Результат негативний, але не з вини юриста',
    'Результат не задовольняє повністю',
  ],
  ru: [
    'Результат удовлетворяет полностью',
    'Результат удовлетворяет частично',
    'Результат негативный, но не по вине юриста',
    'Результат не удовлетворяет полностью',
  ],
  de: [
    'Das Ergebnis erfüllt vollständig',
    'Das Ergebnis erfüllt teilweise',
    'Das Ergebnis ist negativ, aber nicht durch das Verschulden des Anwalts',
    'Das Ergebnis erfüllt nicht vollständig',
  ],
  en: [
    'The result is fully satisfactory',
    'The result is partially satisfactory',
    'The result is negative, but not due to the lawyer’s fault',
    'The result is not fully satisfactory',
  ],
  fr: [
    'Le résultat satisfait complètement',
    'Le résultat satisfait partiellement',
    'Le résultat est négatif, mais pas à cause de l’avocat',
    'Le résultat ne satisfait pas complètement',
  ],
  es: [
    'El resultado satisface completamente',
    'El resultado satisface parcialmente',
    'El resultado es negativo, pero no por culpa del abogado',
    'El resultado no satisface completamente',
  ],
  cz: [
    'Výsledek je zcela uspokojivý',
    'Výsledek je částečně uspokojivý',
    'Výsledek je negativní, ale ne z viny právníka',
    'Výsledek není zcela uspokojivý',
  ],
};

const LOGICAL_STANDARD_QUALITY_LEVEL_5_OPTIONS = ['жахливо', 'погано', 'нормально', 'добре', 'чудово'];

const LOGICAL_STANDARD_CHOISE_4_OPTIONS = ['Так', 'Скоріше так, чим ні', 'Скоріше ні, чим так', 'Ні'];

const LOGICAL_STANDARD_CHOISE_2_OPTIONS = ['Так', 'Ні'];

const LOGICAL_STARTED_ON_TIME_CHOISE_3_OPTIONS = ['Так', 'Ні, не по провині консультанта', 'Ні, по провині консультанта'];

const LOGICAL_WOULD_YOU_AGREE_CHOISE_3_OPTIONS = ['Так', 'Ні', 'Ні, проте планую укласти у майбутньому'];

const LOGICAL_ARCHIVE_CANDY_CHOISE_3_OPTIONS = ['Так', 'Ні', 'Не отримав(-ла) 😔'];

const LOGICAL_STANDARD_QUALITY_LEVEL_5_OPTIONS_FOR_EADVOKAT = {
  ua: ['жахливо', 'погано', 'нормально', 'добре', 'чудово'],
  ru: ['ужасно', 'плохо', 'нормально', 'хорошо', 'отлично'],
  de: ['schrecklich', 'schlecht', 'normal', 'gut', 'ausgezeichnet'],
  en: ['terrible', 'bad', 'okay', 'good', 'excellent'],
  fr: ['horrible', 'mauvais', 'normal', 'bien', 'excellent'],
  es: ['horrible', 'malo', 'normal', 'bueno', 'excelente'],
  cz: ['hrozné', 'špatné', 'normální', 'dobré', 'vynikající'],
};

const LOGICAL_STANDARD_CHOISE_4_OPTIONS_FOR_EADVOKAT = {
  ua: ['Так', 'Скоріше так, чим ні', 'Скоріше ні, чим так', 'Ні'],
  ru: ['Да', 'Скорее да, чем нет', 'Скорее нет, чем да', 'Нет'],
  de: ['Ja', 'Eher ja als nein', 'Eher nein als ja', 'Nein'],
  en: ['Yes', 'More yes than no', 'More no than yes', 'No'],
  fr: ['Oui', 'Plutôt oui que non', 'Plutôt non que oui', 'Non'],
  es: ['Sí', 'Más sí que no', 'Más no que sí', 'No'],
  cz: ['Ano', 'Spíše ano než ne', 'Spíše ne než ano', 'Ne'],
};

const LOGICAL_STANDARD_CHOISE_2_OPTIONS_FOR_EADVOKAT = {
  ua: ['Так', 'Ні'],
  ru: ['Да', 'Нет'],
  de: ['Ja', 'Nein'],
  en: ['Yes', 'No'],
  fr: ['Oui', 'Non'],
  es: ['Sí', 'No'],
  cz: ['Ano', 'Ne'],
};

const LOGICAL_STARTED_ON_TIME_CHOISE_3_OPTIONS_FOR_EADVOKAT = {
  ua: ['Так', 'Ні, не по провині консультанта', 'Ні, по провині консультанта'],
  ru: ['Да', 'Нет, не по вине консультанта', 'Нет, по вине консультанта'],
  de: ['Ja', 'Nein, nicht wegen des Beraters', 'Nein, wegen des Beraters'],
  en: ['Yes', 'No, not the consultant’s fault', 'No, consultant’s fault'],
  fr: ['Oui', 'Non, pas de la faute du consultant', 'Non, de la faute du consultant'],
  es: ['Sí', 'No, no por culpa del consultor', 'No, por culpa del consultor'],
  cz: ['Ano', 'Ne, ne vinou konzultanta', 'Ne, vinou konzultanta'],
};

const LOGICAL_WOULD_YOU_AGREE_CHOISE_3_OPTIONS_FOR_EADVOKAT = {
  ua: ['Так', 'Ні', 'Ні, проте планую укласти у майбутньому'],
  ru: ['Да', 'Нет', 'Нет, но планирую заключить в будущем'],
  de: ['Ja', 'Nein', 'Nein, plane aber in Zukunft abzuschließen'],
  en: ['Yes', 'No', 'No, but plan to agree in the future'],
  fr: ['Oui', 'Non', 'Non, mais prévois de conclure à l’avenir'],
  es: ['Sí', 'No', 'No, pero planeo acordar en el futuro'],
  cz: ['Ano', 'Ne', 'Ne, ale plánují uzavřít v budoucnu'],
};

const LOGICAL_ARCHIVE_CANDY_CHOISE_3_OPTIONS_FOR_EADVOKAT = {
  ua: ['Так', 'Ні', 'Не отримав(-ла) 😔'],
  ru: ['Да', 'Нет', 'Не получил(-а) 😔'],
  de: ['Ja', 'Nein', 'Nicht erhalten 😔'],
  en: ['Yes', 'No', 'Did not receive 😔'],
  fr: ['Oui', 'Non', 'Non reçu 😔'],
  es: ['Sí', 'No', 'No recibido 😔'],
  cz: ['Ano', 'Ne', 'Neobdržel(-a) 😔'],
};

const LEVEL_STANDARD_4_OPTIONS = {
  4: 'Високий',
  3: 'Вище середнього',
  2: 'Нижче середнього',
  1: 'Низький',
};

const PARTNER_CLIENT_REWARD = 300;

const DAILY_PARTNER_INVITE_LIMIT = 5;

const PARTNER_PAYMENT_CARD_LENGTH = 16;

const RIBBON_VALUE_REACH = [
  {
    text: 'Холодний',
    color: 'blue',
  },
  {
    text: 'Звичайний',
    color: 'cyan',
  },
  {
    text: 'Теплий',
    color: 'orange',
  },
  {
    text: 'Гарячий',
    color: 'red',
  },
];

const DATE_PERIOD_REACH = [
  {
    text: 'Від 0 - до ∞ днів',
    value: [0, 315360000000],
  },
  {
    text: 'Від 10 - до ∞ днів',
    value: [864000000, 315360000000],
  },
  {
    text: 'Від 20 - до ∞ днів',
    value: [1728000000, 315360000000],
  },
  {
    text: 'Від 30 - до ∞ днів',
    value: [2592000000, 315360000000],
  },
  {
    text: 'Від 90 - до ∞ днів',
    value: [7776000000, 315360000000],
  },
  {
    text: 'Від 180 - до ∞ днів',
    value: [15552000000, 315360000000],
  },
]; // * max 10 years

const PROPOSED_AMOUNT_PERIOD_REACH = [
  {
    text: 'Від 0 - до 1.000 гривень',
    value: [0, 1000],
  },
  {
    text: 'Від 1.000 - до 2.500 гривень',
    value: [1000, 2500],
  },
  {
    text: 'Від 2.500 - до 5.000 гривень',
    value: [2500, 5000],
  },
  {
    text: 'Від 5.000 - до 10.000 гривень',
    value: [5000, 10000],
  },
  {
    text: 'Від 10.000 - до 25.000 гривень',
    value: [10000, 25000],
  },
  {
    text: 'Від 25.000 - до 50.000 гривень',
    value: [25000, 50000],
  },
  {
    text: 'Від 50.000 - до 100.000 гривень',
    value: [50000, 100000],
  },
  {
    text: 'Від 100.000 - до 250.000 гривень',
    value: [100000, 250000],
  },
  {
    text: 'Від 250.000 - до 500.000 гривень',
    value: [250000, 500000],
  },
  {
    text: 'Від 500.000 - до 1.000.000 гривень',
    value: [500000, 1000000],
  },
  {
    text: 'Від 1.000.000 - до ∞ гривень',
    value: [1000000, 2742630000000000],
  },
]; // * max amount UAH in the world

const CLIENT_HOTLINES_FILTER = {
  PROCESSED: 'processed',
  UNPROCESSED: 'unprocessed',
  ALL: 'all',
};

const OPTIONS_WITH_DISABLED_VIA_CLIENT_HOTLINES_FILTER = [
  { label: 'Не опрацьовані', value: CLIENT_HOTLINES_FILTER.UNPROCESSED },
  { label: 'Опрацьовані', value: CLIENT_HOTLINES_FILTER.PROCESSED },
  { label: 'Всі', value: CLIENT_HOTLINES_FILTER.ALL },
];

const ASSIGNMENTS_SERVICES_TYPE = {
  0: 'Вихід в суд',
  1: 'Інше',
};

const ASSIGNMENT_SERVICE = {
  GOING_TO_COURT: '0',
  OTHER: '1',
};

const ASSIGNMENT_PAYMENT_STATUS = {
  NOT_PAYED: '0',
  PAYED: '1',
};

const ASSIGNMENT_STATUS = {
  DID_NOT_HAPPEN: '0',
  HAPPEN: '1',
};

const ASSIGNMENTS_PLACE_TYPE = {
  1: 'Голосіївський Районний Суд Міста Києва',
  2: 'Оболонський Районний Суд Міста Києва',
  3: 'Печерський Районний Суд Міста Києва',
  4: 'Святошинський Районний Суд Міста Києва',
  5: 'Окружний Адміністративний Суд Міста Києва',
  6: 'Господарський Суд м. Києва',
  7: 'Деснянський Районний Суд Міста Києва',
  8: "Солом'янський Районний Суд Міста Києва",
  9: 'Шевченківський Районний Суд Міста Києва',
  10: 'Дарницький Районний Суд Міста Києва',
  11: 'Апеляційний Суд Міста Києва',
  12: 'Подільський Районний Суд Міста Києва',
  13: 'Київський Апеляційний Господарський Суд',
  14: 'Касаційний Цивільний Суд У Складі Верховного Суду',
  15: 'Касаційний Адміністративний Суд У Складі Верховного Суду',
  16: 'Касаційний Кримінальний Суд У Складі Верховного Суду',
  17: 'Касаційний Господарський Суд У Складі Верховного Суду',
  18: 'Баришівський Районний Суд Київської області',
  19: 'Березанський Міський Суд Київської області',
  20: 'Білоцерківський Міськрайонний Суд Київської області',
  21: 'Богуславський Районний Суд Київської області',
  22: 'Бориспільський Міськрайонний Суд Київської області',
  23: 'Бородянський Районний Суд Київської області',
  24: 'Броварський Міськрайонний Суд Київської області',
  25: 'Васильківський Міськрайонний Суд Київської області',
  26: 'Вишгородський Районнйи Суд Київської області',
  27: 'Володарський Районний Суд Київської області',
  28: 'Згурівський Районний Суд Київської області',
  29: 'Іванківський Районний Суд Київської області',
  30: 'Ірпінський Міський Суд Київської області',
  31: 'Кагарлицький Районний Суд Київської області',
  32: 'Києво-Святошинський Районний Суд Київської області',
  33: 'Макарівський Районний Суд Київської області',
  34: 'Миронівський Районний Суд Київської області',
  35: 'Обухівський Районний Суд Київської області',
  36: 'Переяслав-Хмельницький Міськрайонний Суд Київської області',
  37: 'Ржищівський Міськрайонний Суд Київської області',
  38: 'Рокитнянський Районний Суд Київської області',
  39: 'Сквирський Районний Суд Київської області',
  40: 'Славутицький Міський Суд Київської області',
  41: 'Ставищенський Районний Суд Київської області',
  42: 'Таращанський Районний Суд Київської області',
  43: 'Тетіївський Районний Суд Київської області',
  44: 'Фстівський Міськрайонний Суд Київської області',
  45: 'Яготинський Районний Суд Київської області',
  46: 'Барвінківський Районний Суд Харківської області',
  47: 'Великобурлуцький Районний Суд Харківської області',
  48: 'Дворічанський Районний Суд Харківської області',
  49: 'Дзержинський Районний Суд м. Харкова',
  50: 'Зміївський Районний Суд Харківської області',
  51: 'Золочівський Районний Суд Харківської області',
  52: 'Київський Районний Суд м. Харкова',
  53: 'Красноградський Районний Суд Харківської області',
  54: 'Краснокутський Районний Суд Харківської області',
  55: 'Ленінський Районний Суд м. Харкова',
  56: 'Лозівський Міськрайонний Суд Харківської області',
  57: 'Люботинський Міський Суд Харківської області',
  58: 'Нововодолазький Районний Суд Харківської області',
  59: 'Орджонікідзевський Районний Суд м. Харкова',
  60: 'Фрунзенський Районний Суд м. Харкова',
  61: 'Чугуївський Міський Суд Харківської області',
  62: 'Апеляційний Суд Харківської області',
  63: 'Господарський Суд Харківської області',
  64: 'Харківський Апеляційний Господарський Суд',
  65: 'Комінтернівський Районний Суд м. Харкова',
  66: 'Балаклійський Районний Суд Харківської області',
  67: 'Близнюківський Районний Суд Харківської області',
  68: 'Жовтневий Районний Суд м. Харкова',
  69: 'Ізюмський Міськрайонний Суд Харківської області',
  70: 'Кегичівський Районний Суд Харківської області',
  71: 'Коломацький Районний Суд Харківської області',
  72: "Куп'янський Міськрайонний Суд Харківської області",
  73: 'Московський Районний Суд м. Харкова',
  74: 'Харківський Районний Суд Харківської області',
  75: 'Харківський Апеляційний Адміністративний Суд',
  76: 'Борівський Районний Суд Харківської області',
  77: 'Вовчанський Районний Суд Харківської області',
  78: 'Зачепилівський Районний Суд Харківської області',
  79: 'Червонозаводський Районний Суд м. Харкова',
  80: 'Харківський Окружний Адміністративний Суд',
  81: 'Сахновщинський Районний Суд Харківської області',
  82: 'Шевченківський Районний Суд Харківської області',
  83: 'Валківський Районний Суд Харківської області',
  84: 'Печенізький Районний Суд Харківської області',
  85: 'Первомайський Міськрайонний Суд Харківської області',
  86: 'Богодухівський Районний Суд Харківської області',
  87: 'Дергачівський Районний Суд Харківської області',
  88: 'Бродівський Районний Суд Львівської області',
  89: 'Буський Районний Суд Львівської області',
  90: 'Жидачівський Районний Суд Львівської області',
  91: 'Залізничний Районний Суд м. Львова',
  92: "Кам'янка-Бузький Районний Суд Львівської області",
  93: 'Личаківський Районний Суд м. Львова',
  94: 'Миколаївський Районний Суд Львівської області',
  95: 'Перемишлянський Районний Суд Львівської області',
  96: 'Пустомитівський Районний Суд Львівської області',
  97: 'Радехівський Районний Суд Львівської області',
  98: 'Сихівський Районний Суд м. Львова',
  99: 'Сколівський Районний Суд Львівської області',
  100: 'Старосамбірський Районний Суд Львівської області',
  101: 'Сокальський Районний Суд Львівської області',
  102: 'Трускавецький Міський Суд Львівської області',
  103: 'Франківський Районний Суд м. Львова',
  104: 'Шевченківський Районний Суд м. Львова',
  105: 'Апеляційний Суд Львівської області',
  106: 'Львівський Апеляційний Господарський Суд',
  107: 'Львівський Апеляційний Адміністративний Суд',
  108: 'Бориславський Міський Суд Львівської області',
  109: 'Дрогобицький Міськрайонний Суд Львівської області',
  110: 'Городоцький Районний Суд Львівської області',
  111: 'Золочівський Районний Суд Львівської області',
  112: 'Мостиський Районний Суд Львівської області',
  113: 'Самбірський Міськрайонний Суд Львівської області',
  114: 'Стрийський Міськрайонний Суд Львівської області',
  115: 'Яворівський Районний Суд Львівської області',
  116: 'Господарський Суд Львівської області',
  117: 'Турківський Районний Суд Львівської області',
  118: 'Червоноградський Міський Суд Львівської області',
  119: 'Львівський Окружний Адміністративний Суд',
  120: 'Галицький Районний Суд м. Львова',
  121: 'Жовківський Районний Суд Львівської області',
  122: 'Болехівський Міський Суд Івано-Франківської області',
  123: 'Городенківський Районний Суд Івано-Франківської області',
  124: 'Івано-Франківський Міський Суд Івано-Франківської області',
  125: 'Рожнятівський Районний Суд Івано-Франківської області',
  126: 'Тлумацький Районний Суд Івано-Франківської області',
  127: 'Богородчанський Районний Суд Івано-Франківської області',
  128: 'Верховинський Районний Суд Івано-Франківської області',
  129: 'Галицький Районний Суд Івано-Франківської області',
  130: 'Калуський Міськрайонний Суд Івано-Франківської області',
  131: 'Коломийський Міськрайонний Суд Івано-Франківської області',
  132: 'Надвірнянський Районний Суд Івано-Франківської області',
  133: 'Снятинський Районний Суд Івано-Франківської області',
  134: 'Тисменицький Районний Суд Івано-Франківської області',
  135: 'Яремчанський Міський Суд Івано-Франківської області',
  136: 'Івано-Франківський Окружний Адміністративний Суд',
  137: 'Господарський Суд Івано-Франківської області',
  138: 'Косівський Районний Суд Івано-Франківської області',
  139: 'Рогатинський Районний Суд Івано-Франківської області',
  140: 'Апеляційний Суд Івано-Франківської області',
  141: 'Долинський Районний Суд Івано-Франківської області',
  142: 'Саксаганський Районний Суд м. Кривого Рогу',
  143: 'Солонянський Районний Суд Дніпропетровської області',
  144: 'Тернівський Міський Суд Дніпропетровської області',
  145: 'Томаківський Районний Суд Дніпропетровської області',
  146: 'Широківський Районний Суд Дніпропетровської області',
  147: 'Апеляційний Суд Дніпропетровської області',
  148: 'Апеляційний Суд Дніпропетровської області',
  149: 'Вільногірський Міський Суд Дніпропетровської області',
  150: 'Дніпропетровський Апеляційний Господарський Суд',
  151: 'Дніпропетровський Апеляційний Адміністративний Суд',
  152: 'Амур-Нижньодніпровський Районний Суд м. Дніпропетровська',
  153: 'Баглійський Районний Суд м. Дніпродзержинська',
  154: 'Дзержинський Районний Суд м. Кривого Рогу',
  155: 'Дніпропетровський Районний Суд Дніпропетровської області',
  156: 'Ленінський Районний Суд м. Дніпропетровська',
  157: 'Марганецький Міський Суд Дніпропетровської області',
  158: 'Петриківський Районний Суд Дніпропетровської області',
  159: 'Покровський Районний Суд Дніпропетровської області',
  160: 'Самарський Районний Суд м. Дніпропетровська',
  161: 'Тернівський Районний Суд м. Кривого Рогу',
  162: 'Центрально-Міський Районний Суд м. Кривого Рогу',
  163: "Юр'ївський Районний Суд Дніпропетровської області",
  164: 'Нікопольський Міськрайонний Суд Дніпропетровської області',
  165: 'Верхньодніпровський Районний Суд Дніпропетровської області',
  166: 'Бабушкінський Районний Суд м. Дніпропетровська',
  167: 'Дніпровський Районний Суд м. Дніпродзержинська',
  168: 'Заводський Районний Суд м. Дніпродзержинська',
  169: 'Інгулецький Районний Суд м. Кривого Рогу',
  170: 'Криничанський Районний Суд Дніпропетровської області',
  171: 'Орджонікідзевський Міський Суд Дніпропетровської області',
  172: 'Першотравеньський Міський Суд Дніпропетровської області',
  173: 'Синельниківський Міськрайонний Суд Дніпропетровської області',
  174: 'Софіївський Районний Суд Дніпропетровської області',
  175: 'Царичанський Районний Суд Дніпропетровської області',
  176: 'Господарський Суд Дніпропетровської області',
  177: 'Красногвардійський Районний Суд м. Дніпропетровська',
  178: 'Дніпропетровський Окружний Адміністративний Суд',
  179: 'Павлоградський Міськрайонний Суд Дніпропетровської області',
  180: 'Жовтневий Районний Суд м. Кривого Рогу',
  181: 'Індустріальний Районний Суд м. Дніпропетровська',
  182: 'Миколаївський Районний Суд Одеської області',
  183: 'Приморський Районний Суд м. Одеси',
  184: 'Ренійський Районний Суд Одеської області',
  185: 'Роздільнянський Районний Суд Одеської області',
  186: 'Татарбунарський Районний Суд Одеської області',
  187: 'Тарутинський Районний Суд Одеської області',
  188: 'Фрунзівський Районний Суд Одеської області',
  189: 'Южний Міський Суд Одеської області',
  190: 'Арцизький Районний Суд Одеської області',
  191: 'Березівський Районний Суд Одеської області',
  192: 'Біляївський Районний Суд Одеської області',
  193: 'Іванівський Районний Суд Одеської області',
  194: 'Іллічівський Міський Суд Одеської області',
  195: 'Котовський Міськрайонний Суд Одеської області',
  196: 'Любашівський Районний Суд Одеської області',
  197: 'Ізмаїльський Міськрайонний Суд Одеської області',
  198: 'Господарський Суд Одеської області',
  199: 'Одеський Апеляційний Господарський Суд',
  200: 'Одеський Апеляційний Адміністративний Суд',
  201: 'Овідіопольський Районний Суд Одеської області',
  202: 'Савранський Районний Суд Одеської області',
  203: 'Саратський Районний Суд Одеської області',
  204: 'Ширяївський Районний Суд Одеської області',
  205: 'Болградський Районний Суд Одеської області',
  206: 'Комінтернівський Районний Суд Одеської області',
  207: 'Апеляційний Суд Одеської області',
  208: 'Балтський Районний Суд Одеської області',
  209: 'Білгород-Дністровський Міськрайонний Суд Одеської області',
  210: 'Великомихайлівський Районний Суд Одеської області',
  211: 'Київський Районний Суд м. Одеси',
  212: 'Кодимський Районний Суд Одеської області',
  213: 'Ананьївський Районний Суд Одеської області',
  214: 'Кілійський Районний Суд Одеської області',
  215: 'Красноокнянський Районний Суд Одеської області',
  216: 'Малиновський Районний Суд м. Одеси',
  217: 'Одеський Окружний Адміністративний Суд',
  218: 'Суворовський Районний Суд м. Одеси',
  219: 'Теплодарський Міський Суд Одеської області',
  220: 'Ленінський Районний Суд м. Запоріжжя',
  221: 'Оріхівський Районний Суд Запорізької області',
  222: 'Приморський Районний Суд Запорізької області',
  223: 'Токмацький Районний Суд Запорізької області',
  224: 'Апеляційний Суд Запорізької області',
  225: 'Веселівський Районний Суд Запорізької області',
  226: 'Гуляйпільський Районний Суд Запорізької області',
  227: 'Енергодарський Міський Суд Запорізької області',
  228: 'Жовтневий Районний Суд м. Запоріжжя',
  229: "Кам'янсько-Дніпровський Районний Суд",
  230: 'Новомиколаївський Районний Суд Запорізької області',
  231: 'Орджонікідзевський Районний Суд м. Запоріжжя',
  232: 'Пологівський Районний Суд Запорізької області',
  233: 'Розівський Районний Суд Запорізької області',
  234: 'Чернігівський Районний Суд Запорізької області',
  235: 'Запорізький Окружний Адміністративний Суд',
  236: 'Господарський Суд Запорізької області',
  237: 'Комунарський Районний Суд м. Запоріжжя',
  238: 'Заводський Районний Суд м. Запоріжжя',
  239: 'Мелітопольський Міськрайонний Суд Запорізької області',
  240: 'Михайлівський Районний Суд Запорізької області',
  241: 'Якимівський Районний Суд Запорізької області',
  242: 'Бердянський Міськрайонний Суд Запорізької області',
  243: 'Вільнянський Районний Суд Запорізької області',
  244: 'Куйбишевський Районний Суд Запорізької області',
  245: 'Хортицький Районний Суд м. Запоріжжя',
  246: 'Великобілозерський Районний Суд Запорізької області',
  247: 'Шевченківський Районний Суд м. Запоріжжя',
  248: 'Запорізький Районний Суд Запорізької області',
  249: 'Приазовський Районний Суд Запорізької області',
  250: 'Василівський Районний Суд Запорізької області',
  251: 'Бершадський Районний Суд Вінницької області',
  252: 'Гайсинський Районний Суд Вінницької області',
  253: 'Іллінецький Районний Суд Вінницької області',
  254: 'Козятинський Міськрайонний Суд Вінницької області',
  255: 'Крижопільський Районний Суд Вінницької області',
  256: 'Могилів-Подільський Міськрайонний Суд Вінницької області',
  257: 'Піщанський Районний Суд Вінницької області',
  258: 'Теплицький Районний Суд Вінницької області',
  259: 'Томашпільський Районний Суд Вінницької області',
  260: 'Тульчинський Районний Суд Вінницької області',
  261: 'Чечельницький Районний Суд Вінницької області',
  262: 'Шаргородський Районний Суд Вінницької області',
  263: 'Апеляційний Суд Вінницької області',
  264: 'Барський Районний Суд Вінницької області',
  265: 'Вінницький Районний Суд Вінницької області',
  266: 'Ладижинський Міський Суд Вінницької області',
  267: 'Липовецький Районний Суд Вінницької області',
  268: 'Мурованокуриловецький Районний Суд Вінницької області',
  269: 'Оратівський Районний Суд Вінницької області',
  270: 'Господарський Суд Вінницької області',
  271: 'Калинівський Районний Суд Вінницької області',
  272: 'Погребищенський Районний Суд Вінницької області',
  273: 'Жмеринський Міськрайонний Суд Вінницької області',
  274: 'Немирівський Районний Суд Вінницької області',
  275: 'Тиврівський Районний Суд Вінницької області',
  276: 'Хмільницький Міськрайонний Суд Вінницької області',
  277: 'Ямпільський Районний Суд Вінницької області',
  278: 'Вінницький Апеляційний Адміністративний Суд',
  279: 'Літинський Районний Суд Вінницької області',
  280: 'Чернівецький Районний Суд Вінницької області',
  281: 'Вінницький Міський Суд Вінницької області',
  282: 'Тростянецький Районний Суд Вінницької області',
  283: 'Вінницький Окружний Адміністративний Суд',
  284: 'Володимир-Волинський Міський Суд Волинської області',
  285: 'Іваничівський Районний Суд Волинської області',
  286: 'Камінь-Каширський Районний Суд Волинської області',
  287: 'Локачинський Районний Суд Волинської області',
  288: 'Луцький Міськрайонний Суд Волинської області',
  289: 'Любомльський Районний Суд Волинської області',
  290: 'Нововолинський Міський Суд Волинської області',
  291: 'Старовижівський Районний Суд Волинської області',
  292: 'Шацький Районний Суд Волинської області',
  293: 'Волинський Окружний Адміністративний Суд',
  294: 'Апеляційний Суд Волинської області',
  295: 'Господарський Суд Волинської області',
  296: 'Ковельський Міськрайонний Суд Волинської області',
  297: 'Маневицький Районний Суд Волинської області',
  298: 'Горохівський Районний Суд Волинської області',
  299: 'Ківерцівський Районний Суд Волинської області',
  300: 'Ратнівський Районний Суд Волинської області',
  301: 'Любешівський Районний Суд Волинської області',
  302: 'Рожищенський Районний Суд Волинської області',
  303: 'Турійський Районний Суд Волинської області',
  304: 'Артемівський Міськрайонний Суд Донецької області',
  305: 'Великоновосілківський Районний Суд Донецької області',
  306: 'Волноваський Районний Суд Донецької області',
  307: 'Ворошиловський Районний Суд м. Донецька',
  308: 'Гірницький Районний Суд м. Макіївки',
  309: 'Дзержинський Міський Суд Донецької області',
  310: 'Димитровський Міський Суд Донецької області',
  311: 'Добропільський Міськрайонний Суд Донецької області',
  312: 'Єнакіївський Міський Суд Донецької області',
  313: 'Жданівський Міський Суд Донецької області',
  314: 'Іллічівський Районний Суд м. Маріуполя',
  315: 'Калінінський Районний Суд м. Горлівки',
  316: 'Київський Районний Суд м. Донецька',
  317: 'Кіровський Районний Суд м. Макіївки',
  318: 'Краматорський Міський Суд Донецької області',
  319: 'Красноармійський Міськрайонний Суд Донецької області',
  320: 'Мар’їнський Районний Суд Донецької області',
  321: 'Микитівський Районний Суд м. Горлівки',
  322: 'Орджонікідзевський Районний Суд м. Маріуполя',
  323: 'Приморський Районний Суд м. Маріуполя',
  324: 'Селидівський Міський Суд Донецької області',
  325: 'Совєтський Районний Суд м. Макіївки',
  326: 'Тельманівський Районний Суд Донецької області',
  327: 'Шахтарський Міськрайонний Суд Донецької області',
  328: 'Ясинуватський Міськрайонний Суд Донецької області',
  329: 'Апеляційний Суд Донецької області',
  330: 'Апеляційний Суд Донецької області',
  331: 'Червоногвардійський Районний Суд м. Макіївки',
  332: 'Господарський Суд Донецької області',
  333: 'Донецький Апеляційний Адміністративний Суд',
  334: 'Калінінський Районний Суд м. Донецька',
  335: 'Кіровський Районний Суд м. Донецька',
  336: 'Костянтинівський Міськрайонний Суд Донецької області',
  337: 'Ленінський Районний Суд м. Донецька',
  338: 'Новоазовський Районний Суд Донецької області',
  339: 'Олександрівський Районний Суд Донецької області',
  340: 'Сніжнянський Міський Суд Донецької області',
  341: 'Старобешівський Районний Суд Донецької області',
  342: 'Центрально-Міський Районний Суд м. Макіївки',
  343: 'Центрально-Міський Районний Суд м. Горлівки',
  344: 'Донецький Апеляційний Господарський Суд',
  345: 'Першотравневий Районний Суд Донецької області',
  346: 'Авдіївський Міський Суд Донецької області',
  347: 'Амвросієвський Районний Суд Донецької області',
  348: 'Володарський Районний Суд Донецької області',
  349: 'Дебальцевський Міський Суд Донецької області',
  350: 'Докучаєвський Міський Суд Донецької області',
  351: 'Жовтневий Районний Суд м. Маріуполя',
  352: 'Кіровський Міський Суд Донецької області',
  353: 'Краснолиманський Міський Суд Донецької області',
  354: 'Куйбишевський Районний Суд м. Донецька',
  355: 'Новогродівський Міський Суд Донецької області',
  356: 'Петровський Районний Суд м. Донецька',
  357: "Слов'янський Міськрайонний Суд Донецької області",
  358: 'Харцизький Міський Суд Донецької області',
  359: 'Дружківський Міський Суд Донецької області',
  360: 'Донецький Окружний Адміністративний Суд',
  361: 'Торезький Міський Суд Донецької області',
  362: 'Будьоннівський Районний Суд м. Донецька',
  363: 'Вугледарський Міський Суд Донецької області',
  364: 'Пролетарський Районний Суд м. Донецька',
  365: 'Брусилівський Районний Суд Житомирської області',
  366: 'Володарсько-Волинський Районний Суд Житомирської області',
  367: 'Житомирський Районний Суд Житомирської області',
  368: 'Коростишівський Районний Суд Житомирської області',
  369: 'Малинський Районний Суд Житомирської області',
  370: 'Новоград-Волинський Міськрайонний Суд Житомирської області',
  371: 'Попільнянський Районний Суд Житомирської області',
  372: 'Романівський Районний Суд Житомирської області',
  373: 'Червоноармійський Районний Суд Житомирської області',
  374: 'Житомирський Окружний Адміністративний Суд',
  375: 'Любарський Районний Суд Житомирської області',
  376: 'Господарський Суд Житомирської області',
  377: 'Андрушівський Районний Суд Житомирської області',
  378: 'Ємільчинський Районний Суд Житомирської області',
  379: 'Корольовський Районний Суд м. Житомира',
  380: 'Лугинський Районний Суд Житомирської області',
  381: 'Олевський Районний Суд Житомирської області',
  382: 'Радомишльський Районний Суд Житомирської області',
  383: 'Чуднівський Районний Суд Житомирської області',
  384: 'Баранівський Районний Суд Житомирської області',
  385: 'Богунський Районний Суд м. Житомира',
  386: 'Коростенський Міськрайонний Суд Житомирської області',
  387: 'Народицький Районний Суд Житомирської області',
  388: 'Овруцький Районний Суд Житомирської області',
  389: 'Апеляційний Суд Житомирської області',
  390: 'Житомирський Апеляційний Адміністративний Суд',
  391: 'Бердичівський Міськрайонний Суд Житомирської області',
  392: 'Ружинський Районний Суд Житомирської області',
  393: 'Черняхівський Районний Суд Житомирської області',
  394: 'Міжгірський Районний Суд Закарпатської області',
  395: 'Рахівський Районний Суд Закарпатської області',
  396: 'Свалявський Районний Суд Закарпатської області',
  397: 'Тячівський Районний Суд Закарпатської області',
  398: 'Хустський Районний Суд Закарпатської області',
  399: 'Закарпатський Окружний Адміністративний Суд',
  400: 'Апеляційний Суд Закарпатської області',
  401: 'Воловецький Районний Суд Закарпатської області',
  402: 'Господарський Суд Закарпатської області',
  403: 'Виноградівський Районний Суд Закарпатської області',
  404: 'Іршавський Районний Суд Закарпатської області',
  405: 'Перечинський Районний Суд Закарпатської області',
  406: 'Ужгородський Міськрайонний Суд Закарпатської області',
  407: 'Великоберезнянський Районний Суд Закарпатської області',
  408: 'Берегівський Районний Суд Закарпатської області',
  409: 'Мукачівський Міськрайонний Суд Закарпатської області',
  410: 'Голованівський Районний Суд Кіровоградської області',
  411: 'Долинський Районний Суд Кіровоградської області',
  412: 'Кіровський Районний Суд м. Кіровограда',
  413: 'Новоархангельський Районний Суд Кіровоградської області',
  414: 'Новомиргородський Районний Суд Кіровоградської області',
  415: 'Онуфріївський Районний Суд Кіровоградської області',
  416: 'Світловодський Міськрайонний Суд Кіровоградської області',
  417: 'Ульяновський Районний Суд Кіровоградської області',
  418: 'Кіровоградський Окружний Адміністративний Суд',
  419: 'Господарський Суд Кіровоградської області',
  420: 'Добровеличківський Районний Суд Кіровоградської області',
  421: 'Кіровоградський Районний Суд Кіровоградської області',
  422: 'Ленінський Районний Суд м. Кіровограда',
  423: 'Новгородківський Районний Суд Кіровоградської області',
  424: 'Петрівський Районний Суд Кіровоградської області',
  425: 'Устинівський Районний Суд Кіровоградської області',
  426: 'Знам’янський Міськрайонний Суд Кіровоградської області',
  427: 'Гайворонський Районний Суд Кіровоградської області',
  428: 'Компаніївський Районний Суд Кіровоградської області',
  429: 'Олександрівський Районний Суд Кіровоградської області',
  430: 'Апеляційний Суд Кіровоградської області',
  431: 'Маловисківський Районний Суд Кіровоградської області',
  432: 'Бобринецький Районний Суд Кіровоградської області',
  433: 'Вільшанський Районний Суд Кіровоградської області',
  434: 'Новоукраїнський Районний Суд Кіровоградської області',
  435: 'Олександрійський Міськрайонний Суд Кіровоградської області',
  436: 'Антрацитівський Міськрайонний Суд Луганської області',
  437: 'Артемівський Районний Суд м. Луганська',
  438: 'Жовтневий Районний Суд м. Луганська',
  439: "Кам'янобрідський Районний Суд м. Луганська",
  440: 'Краснодонський Міськрайонний Суд Луганської області',
  441: 'Ленінський Районний Суд м. Луганськ',
  442: 'Лутугинський Районний Суд Луганської області',
  443: 'Міловський Районний Суд Луганської області',
  444: 'Первомайський Міський Суд Луганської області',
  445: 'Ровеньківський Міський Суд Луганської області',
  446: 'Сєверодонецький Міський Суд Луганської області',
  447: "Слов'яносербський Районний Суд Луганської області",
  448: 'Стахановський Міський Суд Луганської області',
  449: 'Луганський Окружний Адміністративний Суд',
  450: 'Апеляційний Суд Луганської області',
  451: 'Господарський Суд Луганської області',
  452: 'Алчевський Міський Суд Луганської області',
  453: 'Біловодський Районний Суд Луганської області',
  454: 'Брянківський Міський Суд Луганської області',
  455: 'Кіровський Міський Суд Луганської області',
  456: 'Краснолуцький Міський Суд Луганської області',
  457: 'Лисичанський Міський Суд Луганської області',
  458: 'Марківський Районний Суд Луганської області',
  459: 'Новопсковський Районний Суд Луганської області',
  460: 'Рубіжанський Міський Суд Луганської області',
  461: 'Троїцький Районний Суд Луганської області',
  462: 'Кремінський Районний Суд Луганської області',
  463: 'Новоайдарський Районний Суд Луганської області',
  464: 'Станично-Луганський Районний Суд Луганської області',
  465: 'Старобільський Районний Суд Луганської області',
  466: 'Перевальський Районний Суд Луганської області',
  467: 'Білокуракинський Районний Суд Луганської області',
  468: 'Попаснянський Районний Суд Луганської області',
  469: 'Сватівський Районний Суд Луганської області',
  470: 'Свердловський Міський Суд Луганської області',
  471: 'Баштанський Районний Суд Миколаївської області',
  472: 'Березнегуватський Районний Суд Миколаївської області',
  473: 'Врадіївський Районний Суд Миколаївської області',
  474: 'Доманівський Районний Суд Миколаївської області',
  475: 'Єланецький Районний Суд Миколаївської області',
  476: 'Казанківський Районний Суд Миколаївської області',
  477: 'Корабельний Районний Суд м. Миколаєва',
  478: 'Кривоозерський Районний Суд Миколаївської області',
  479: 'Миколаївський Районний Суд Миколаївської області',
  480: 'Новоодеський Районний Суд Миколаївської області',
  481: 'Снігурівський Районний Суд Миколаївської області',
  482: 'Центральний Районний Суд м. Миколаєва',
  483: 'Апеляційний Суд Миколаївської області',
  484: 'Вознесенський Міськрайонний Суд Миколаївської області',
  485: 'Новобузький Районний Суд Миколаївської області',
  486: 'Веселинівський Районний Суд Миколаївської області',
  487: 'Ленінський Районний Суд м. Миколаєва',
  488: 'Очаківський Міськрайонний Суд Миколаївської області',
  489: 'Южноукраїнський Міський Суд Миколаївської області',
  490: 'Заводський Районний Суд м. Миколаєва',
  491: 'Арбузинський Районний Суд Миколаївської області',
  492: 'Березанський Районний Суд Миколаївської області',
  493: 'Миколаївський Окружний Адміністративний Суд',
  494: 'Жовтневий Районний Суд Миколаївської області',
  495: 'Братський Районний Суд Миколаївської області',
  496: 'Первомайський Міськрайонний Суд Миколаївської області',
  497: 'Господарський Суд Миколаївської області',
  498: 'Великобагачанський Районний Суд Полтавської області',
  499: 'Гребінківський Районний Суд Полтавської області',
  500: 'Зіньківський Районний Суд Полтавської області',
  501: 'Кобеляцький Районний Суд Полтавської області',
  502: 'Комсомольський Міський Суд Полтавської області',
  503: 'Лохвицький Районний Суд Полтавської області',
  504: 'Лубенський Міськрайонний Суд Полтавської області',
  505: 'Октябрський Районний Суд м. Полтави',
  506: 'Полтавський Районний Суд Полтавської області',
  507: 'Хорольський Районний Суд Полтавської області',
  508: 'Чутівський Районний Суд Полтавської області',
  509: 'Апеляційний Суд Полтавської області',
  510: 'Автозаводський Районний Суд м. Кременчука',
  511: 'Глобинський Районний Суд Полтавської області',
  512: 'Диканський Районний Суд Полтавської області',
  513: 'Київський Районний Суд м. Полтави',
  514: 'Кременчуцький Районний Суд Полтавської області',
  515: 'Ленінський Районний Суд м. Полтави',
  516: 'Машівський Районний Суд Полтавської області',
  517: 'Новосанжарський Районний Суд Полтавської області',
  518: 'Пирятинський Районний Суд Полтавської області',
  519: 'Семенівський Районний Суд Полтавської області',
  520: 'Чорнухинський Районний Суд Полтавської області',
  521: 'Полтавський Окружний Адміністративний Суд',
  522: 'Господарський Суд Полтавської області',
  523: 'Гадяцький Районний Суд Полтавської області',
  524: 'Карлівський Районний Суд Полтавської області',
  525: 'Крюківський Районний Суд м. Кременчука',
  526: 'Миргородський Міськрайонний Суд Полтавської області',
  527: 'Решетилівський Районний Суд Полтавської області',
  528: 'Шишацький Районний Суд Полтавської області',
  529: 'Козельщинський Районний Суд Полтавської області',
  530: 'Котелевський Районний Суд Полтавської області',
  531: 'Оржицький Районний Суд Полтавської області',
  532: 'Березнівський Районний Суд Рівненської області',
  533: 'Дубровицький Районний Суд Рівненської області',
  534: 'Здолбунівський Районний Суд Рівненської області',
  535: 'Корецький Районний Суд Рівненської області',
  536: 'Острозький Районний Суд Рівненської області',
  537: 'Радивилівський Районний Суд Рівненської області',
  538: 'Рівненський Районний Суд Рівненської області',
  539: 'Рівненський Окружний Адміністративний Суд',
  540: 'Апеляційний Суд Рівненської області',
  541: 'Володимирецький Районний Суд Рівненської області',
  542: 'Костопільський Районний Суд Рівненської області',
  543: 'Рівненський Міський Суд Рівненської області',
  544: 'Сарненський Районний Суд Рівненської області',
  545: 'Гощанський Районний Суд Рівненської області',
  546: 'Дубенський Міськрайонний Суд Рівненської області',
  547: 'Зарічненський Районний Суд Рівненської області',
  548: 'Кузнецовський Міський Суд Рівненської області',
  549: 'Рокитнівський Районний Суд Рівненської області',
  550: 'Господарський Суд Рівненської області',
  551: 'Демидівський Районний Суд Рівненської області',
  552: 'Млинівський Районний Суд Рівненської області',
  553: 'Рівненський Апеляційний Господарський Суд',
  554: 'Конотопський Міськрайонний Суд Сумської області',
  555: 'Краснопільський Районний Суд Сумської області',
  556: 'Кролевецький Районний Суд Сумської області',
  557: 'Липоводолинський Районний Суд Сумської області',
  558: 'Недригайлівський Районний Суд Сумської області',
  559: 'Путивльський Районний Суд Сумської області',
  560: 'Сумський Районний Суд Сумської області',
  561: 'Тростянецький Районний Суд Сумської області',
  562: 'Ямпільський Районний Суд Сумської області',
  563: 'Сумський Окружний Адміністративний Суд',
  564: 'Буринський Районний Суд Сумської області',
  565: 'Великописарівський Районний Суд Сумської області',
  566: 'Зарічний Районний Суд м. Сум',
  567: 'Глухівський Міськрайонний Суд Сумської області',
  568: 'Господарський Суд Сумської області',
  569: 'Білопільський Районний Суд Сумської області',
  570: 'Ковпаківський Районний Суд м. Суми',
  571: 'Лебединський Районний Суд Сумської області',
  572: 'Роменський Міськрайонний Суд Сумської області',
  573: 'Шосткинський Міськрайонний Суд Сумської області',
  574: 'Охтирський Міськрайонний Суд Сумської області',
  575: 'Апеляційний Суд Сумської області',
  576: 'Середино-Будський Районний Суд Сумської області',
  577: 'Бережанський Районний Суд Тернопільської області',
  578: 'Борщівський Районний Суд Тернопільської області',
  579: 'Збаразький Районний Суд Тернопільської області',
  580: 'Кременецький Районний Суд Тернопільської області',
  581: 'Підволочиський Районний Суд Тернопільської області',
  582: 'Тернопільський Міськрайонний Суд Тернопільської області',
  583: 'Тернопільський Окружний Адміністративний Суд',
  584: 'Гусятинський Районний Суд Тернопільської області',
  585: 'Зборівський Районний Суд Тернопільської області',
  586: 'Підгаєцький Районний Суд Тернопільської області',
  587: 'Чортківський Районний Суд Тернопільської області',
  588: 'Бучацький Районний Суд Тернопільської області',
  589: 'Лановецький Районний Суд Тернопільської області',
  590: 'Монастириський Районний Суд Тернопільської області',
  591: 'Апеляційний Суд Тернопільської області',
  592: 'Господарський Суд Тернопільської області',
  593: 'Теребовлянський Районний Суд Тернопільської області',
  594: 'Козівський Районний Суд Тернопільської області',
  595: 'Заліщицький Районний Суд Тернопільської області',
  596: 'Шумський Районний Суд Тернопільської області',
  597: 'Бериславський Районний Суд Херсонської області',
  598: 'Верхньорогачицький Районний Суд Херсонської області',
  599: 'Високопільський Районний Суд Херсонської області',
  600: 'Голопристанський Районний Суд Херсонської області',
  601: 'Іванівський Районний Суд Херсонської області',
  602: 'Каховський Міськрайонний Суд Херсонської області',
  603: 'Нововоронцовський Районний Суд Херсонської області',
  604: 'Новотроїцький Районний Суд Херсонської області',
  605: 'Цюрупинський Районний Суд Херсонської області',
  606: 'Херсонський Окружний Адміністративний Суд',
  607: 'Апеляційний Суд Херсонської області',
  608: 'Господарський Суд Херсонської області',
  609: 'Великоолександрівський Районний Суд Херсонської області',
  610: 'Дніпровський Районний Суд м. Херсона',
  611: 'Комсомольський Районний Суд м. Херсона',
  612: 'Нижньосірогозький Районний Суд Херсонської області',
  613: 'Скадовський Районний Суд Херсонської області',
  614: 'Білозерський Районний Суд Херсонської області',
  615: 'Каланчацький Районний Суд Херсонської області',
  616: 'Новокаховський Міський Суд Херсонської області',
  617: 'Херсонський Міський Суд Херсонської області',
  618: 'Горностаївський Районний Суд Херсонської області',
  619: 'Суворовський Районний Суд м. Херсона',
  620: 'Генічеський Районний Суд Херсонської області',
  621: 'Чаплинський Районний Суд Херсонської області',
  622: 'Великолепетиський Районний Суд Херсонської області',
  623: "Кам'янський Районний Суд Черкаської області",
  624: 'Драбівський Районний Суд Черкаської області',
  625: 'Звенигородський Районний Суд Черкаської області',
  626: 'Катеринопільський Районний Суд Черкаської області',
  627: 'Маньківський Районний Суд Черкаської області',
  628: 'Придніпровський Районний Суд м. Черкас',
  629: 'Смілянський Міськрайонний Суд Черкаської області',
  630: 'Уманський Міськрайонний Суд Черкаської області',
  631: 'Черкаський Районний Суд Черкаської області',
  632: 'Чигиринський Районний Суд Черкаської області',
  633: 'Чорнобаївський Районний Суд Черкаської області',
  634: 'Шполянський Районний Суд Черкаської області',
  635: 'Господарський Суд Черкаської області',
  636: 'Апеляційний Суд Черкаської області',
  637: 'Канівський Міськрайонний Суд Черкаської області',
  638: 'Городищенський Районний Суд Черкаської області',
  639: 'Корсунь-Шевченківський Районний Суд Черкаської області',
  640: 'Христинівський Районний Суд Черкаської області',
  641: 'Черкаський Окружний Адміністративний Суд',
  642: 'Ватутінський Міський Суд Черкаської області',
  643: 'Жашківський Районний Суд Черкаської області',
  644: 'Золотоніський Міськрайонний Суд Черкаської області',
  645: 'Лисянський Районний Суд Черкаської області',
  646: 'Монастирищенський Районний Суд Черкаської області',
  647: 'Соснівський Районний Суд м. Черкаси',
  648: 'Тальнівський Районний Суд Черкаської області',
};

const QUESTIONNAIRE_RUN_AFTER = {
  HOTLINE_END: 'questionnaire_hotline_end',
  ARCHIVE_END: 'questionnaire_archive_end',
};

const URIST_ACTIVITY = {
  0: {
    value: 0,
    label: 'Натисніть щоб почати робочий день',
  },
  1: {
    value: 1,
    label: 'На робочому місці',
  },
  2: {
    value: 2,
    label: 'Не на робочому місці',
  },
};

const URIST_ACTIVITY_REASONS = {
  1: {
    value: 1,
    label: 'Відправка пошти',
  },
  2: {
    value: 2,
    label: 'Отримання пошти',
  },
  3: {
    value: 3,
    label: 'Відправка грошей в ГО',
  },
  4: {
    value: 4,
    label: 'Банк',
  },
  5: {
    value: 5,
    label: 'Госп. забезпечення',
  },
  6: {
    value: 6,
    label: 'WС',
  },
  7: {
    value: 7,
    label: 'Обід',
  },
  8: {
    value: 8,
    label: 'Спілкування з клієнтом',
  },
  9: {
    value: 9,
    label: 'Запізнення',
  },
  10: {
    value: 10,
    label: 'Повітряна тривога',
  },
  11: {
    value: 11,
    label: 'Відпустка',
  },
  12: {
    value: 12,
    label: 'Проблеми зі здоровʼям',
  },
  13: {
    value: 13,
    label: 'Свято',
  },
  14: {
    value: 14,
    label: 'Мій день народження',
  },
  15: {
    value: 15,
    label: 'Навчання',
  },
  16: {
    value: 16,
    label: 'Посадка на рейс',
  },
  17: {
    value: 17,
    label: 'Надзвичайна ситуація',
  },
  18: {
    value: 18,
    label: 'Особисті справи',
  },
  19: {
    value: 19,
    label: 'Переїзд на інший офіс',
  },
  20: {
    value: 20,
    label: 'Неробочий час',
  },
  21: {
    value: 21,
    label: 'Інша причина',
  },
};

const OVERTIME_ACTIVITY_REASONS = [URIST_ACTIVITY_REASONS[20].value];

const RESPONSIBILITY_TEAMS = [
  {
    index: 1,
    label: '9:00 - 12:00 Консультанти',
    fils: ['0', '8', '10', '11', '7', '14', '28', '26', '33', '34', '36', '44'],
  },
  {
    index: 2,
    label: '12:00 - 15:00 Консультанти',
    fils: ['2', '4', '5', '15', '19', '1', '32', '38', '43', '40'],
  },
  {
    index: 3,
    label: '15:00 - 18:00 Консультанти',
    fils: ['16', '9', '6', '31', '27', '46', '45', '23', '21', '30', '35'],
  },
  {
    index: 4,
    label: '9:00 - 12:00 Підтримка',
    fils: ['1', '2', '4', '31', '27', '45', '46', '23', '30', '32', '38', '40', '35'],
  },
  {
    index: 5,
    label: '12:00 - 15:00 Підтримка',
    fils: ['16', '9', '6', '10', '11', '7', '21', '33', '34'],
  },
  {
    index: 6,
    label: '15:00 - 18:00 Підтримка',
    fils: ['0', '5', '8', '15', '19', '14', '28', '26', '36', '43', '44'],
  },
];

const ASSIGNMENT_STATUS_OPTIONS = [
  {
    label: 'Відбувся',
    value: '1',
  },
  {
    label: 'Не відбувся',
    value: '0',
  },
];

const HARVEST_STATUS_OPTIONS = [
  {
    label: 'Оплачено',
    value: '1',
  },
  {
    label: 'Не оплачено',
    value: '0',
  },
];

const INCOME_TYPES = [
  'Постійно є гроші',
  'СПД (ФОП, ЮО)',
  'Не регулярний дохід',
  'Немає доходу',
  'Не каже',
  'Зарплата',
  'Стипендія',
  'Пасивний дохід',
  'Допомога',
  'Аванс',
  'Премія',
  'Пенсія',
];

const EXPENSE_CATEGORIES = [
  {
    value: 0,
    label: 'Пошта',
    subcategories: TYPES_OF_MAIL,
  },
  {
    value: 1,
    label: 'Вихід адвоката',
    subcategories: [],
  },
  {
    value: 2,
    label: 'Госп. забезпечення',
    subcategories: [
      { value: 0, label: 'Поповнення телефону' },
      { value: 1, label: 'Заправка картриджу' },
      { value: 2, label: 'Папір' },
      { value: 3, label: 'Конверти' },
      { value: 4, label: 'Папки для справ' },
      { value: 5, label: 'Інша канцелярія' },
      { value: 6, label: 'Чай та до чаю' },
      { value: 7, label: 'Посуд' },
      { value: 8, label: 'Будівельні матеріали' },
      { value: 9, label: 'Антураж' },
      { value: 10, label: 'Бейджики' },
      { value: 11, label: 'Витратні матеріали' },
      { value: 12, label: 'Аромадіфузор' },
      { value: 13, label: 'Печатки' },
      { value: 14, label: 'Інше госп. заб.' },
    ],
  },
  {
    value: 3,
    label: 'Меблі та техніка',
    subcategories: [
      { value: 0, label: 'Стіл керівника' },
      { value: 1, label: 'Крісло керівника' },
      { value: 2, label: 'Шафа' },
      { value: 3, label: 'Стілець' },
      { value: 4, label: 'Диван' },
      { value: 5, label: 'Тумба' },
      { value: 6, label: 'Журнальний стіл' },
      { value: 7, label: 'Вішалка' },
      { value: 8, label: 'Інші меблі' },
      { value: 9, label: 'Принтер' },
      { value: 10, label: 'Телефон' },
      { value: 11, label: 'СІМ карта' },
      { value: 12, label: 'Інша техніка' },
    ],
  },
  {
    value: 4,
    label: 'Утримання офісу',
    subcategories: [
      { value: 0, label: 'Оренда' },
      { value: 1, label: 'Комунальні послуги' },
    ],
  },
  {
    value: 5,
    label: 'Послуги',
    subcategories: [
      { value: 0, label: 'Доставка' },
      { value: 1, label: 'Перевезення' },
      { value: 2, label: 'Ремонтні роботи' },
      { value: 3, label: 'Охорона' },
      { value: 4, label: 'Прибирання' },
      { value: 5, label: 'Інтернет' },
      { value: 6, label: 'Вантажник' },
      { value: 7, label: 'Телекомунікація' },
      { value: 8, label: 'Перекладач' },
      { value: 9, label: 'Інші послуги' },
    ],
  },
  {
    value: 6,
    label: 'Держ. платежі',
    subcategories: [
      { value: 0, label: 'Судовий збір' },
      { value: 1, label: 'Податки' },
      { value: 2, label: 'Інші держ. платежі' },
    ],
  },
  {
    value: 7,
    label: 'Зарплата',
    subcategories: [
      { value: 0, label: 'Офіційна ЗП' },
      { value: 1, label: 'Не оф. ЗП' },
      { value: 2, label: 'Відкат' },
      { value: 3, label: 'Премія до ЗП' },
      { value: 4, label: 'Премія за ЩС' },
      { value: 5, label: 'Премія за ГЛ' },
      { value: 6, label: 'Інша премія' },
      { value: 7, label: 'Відсоток філії' },
      { value: 8, label: 'Відсоток за іншу філію' },
      { value: 9, label: 'ЗП ІТ' },
      { value: 10, label: 'ЗП бухгалтер' },
      { value: 11, label: 'Інша ЗП' },
    ],
  },
  {
    value: 8,
    label: 'Повернення',
    subcategories: [
      { value: 0, label: 'Перекидання на РР' },
      { value: 1, label: 'Повернення гонорару' },
      { value: 2, label: 'Передаст на ГО' },
      { value: 3, label: 'Дотація' },
      { value: 4, label: 'Інше повернення' },
      { value: 5, label: 'Передаст по філії (без %)' },
    ],
  },
  {
    value: 9,
    label: 'Додавання до каси (-)',
    subcategories: [
      { value: 0, label: 'Оборотка' },
      { value: 1, label: 'Повернення податків ЗП' },
      { value: 2, label: 'Кл. іншої філії' },
      { value: 3, label: 'Штраф' },
      { value: 4, label: 'Інше додавання' },
    ],
  },
  {
    value: 10,
    label: 'Реклама',
    adCategories: [
      { value: 0, label: 'Інтернет' },
      { value: 1, label: 'Транспорт' },
      { value: 2, label: 'Зовнішня реклама' },
      { value: 3, label: 'СМС повідомлення' },
      { value: 4, label: 'Національні ЗМІ' },
      { value: 5, label: 'Регіональні ЗМІ' },
      { value: 6, label: 'Група, блог, відома особа' },
      { value: 7, label: 'Сарафанне радіо' },
      { value: 8, label: 'Своя реклама' },
      { value: 9, label: 'Метрополітен' },
      { value: 10, label: "Інтерв'ю" },
      { value: 11, label: 'Інше' },
    ],
    subcategories: [
      { value: 0, label: 'Фейсбук' },
      { value: 1, label: 'Інстаграм' },
      { value: 2, label: 'Гугл пошук' },
      { value: 3, label: 'Ютуб' },
      { value: 4, label: 'Телеграм' },
      { value: 5, label: 'ТікТок' },
      { value: 6, label: 'Твітер' },
      { value: 7, label: 'Вконтакті' },
      { value: 8, label: 'Однокласники' },
      { value: 9, label: 'Інша соц мережа або сайт' },
      { value: 10, label: 'Додаток' },
      { value: 11, label: 'Яндекс' },
      { value: 12, label: 'Поїзд' },
      { value: 13, label: 'Метро вагон' },
      { value: 14, label: 'Трамвай' },
      { value: 15, label: 'Тролейбус' },
      { value: 16, label: 'Маршрутка' },
      { value: 17, label: 'Автобус' },
      { value: 18, label: 'Таксі' },
      { value: 19, label: 'Човен' },
      { value: 20, label: 'Літак' },
      { value: 21, label: 'Наклейка' },
      { value: 22, label: 'Вивіска' },
      { value: 23, label: 'Щит-мимохід' },
      { value: 24, label: 'Банер-розтяжка' },
      { value: 25, label: 'Лайтбокс' },
      { value: 26, label: 'Табличка на вулиці' },
      { value: 27, label: 'Оголошення на вулиці' },
      { value: 28, label: 'Візитівка на вулиці' },
      { value: 29, label: 'Бігборд' },
      { value: 30, label: 'Рекламна дошка' },
      { value: 31, label: 'Зовнішнє зображення на транспорті' },
      { value: 32, label: 'Дорожній знак' },
      { value: 33, label: 'Проектор' },
      { value: 34, label: 'Напис під ногами' },
      { value: 35, label: 'Ледекран' },
      { value: 36, label: 'Палатка' },
      { value: 37, label: 'Промоакція' },
      { value: 38, label: 'Сувеніри, подарунки' },
      { value: 39, label: 'Аудіореклама' },
      { value: 40, label: 'Айбокс, термінал' },
      { value: 41, label: 'На інших товарах' },
      { value: 42, label: 'На платіжках' },
      { value: 43, label: 'Лекція' },
      { value: 44, label: 'Тату' },
      { value: 45, label: 'СМС Інший оператор' },
      { value: 46, label: 'СМС Водофон' },
      { value: 47, label: 'СМС Київстар' },
      { value: 48, label: 'СМС Лайф' },
      { value: 49, label: 'Лист у E-mail' },
      { value: 50, label: 'СМС у Вайбер' },
      { value: 51, label: 'СМС у Телеграм' },
      { value: 52, label: 'Телебачення' },
      { value: 53, label: 'Радіо' },
      { value: 54, label: 'Газета' },
      { value: 55, label: 'Інтернет ЗМІ' },
      { value: 56, label: 'Звернувся повторно' },
      { value: 57, label: 'Порекомендував малознайомий' },
      { value: 58, label: 'Ваш дзвінок' },
      { value: 59, label: 'Дізнався від знайомого' },
      { value: 60, label: 'Відома особа' },
      { value: 61, label: 'Благодійність' },
      { value: 62, label: 'Колаборація' },
      { value: 63, label: 'Агент за гроші' },
    ],
  },
  {
    value: 11,
    label: 'Розвиток',
    subcategories: [
      { value: 0, label: 'Семінар' },
      { value: 1, label: 'Тренінг' },
      { value: 2, label: 'Конференція' },
      { value: 3, label: 'Захід' },
      { value: 4, label: 'Література' },
      { value: 5, label: 'Пошук літератури' },
      { value: 6, label: 'Інтернет стаття' },
      { value: 7, label: 'Друкована стаття' },
      { value: 8, label: 'Опитування' },
      { value: 9, label: 'Книга' },
      { value: 10, label: 'Інше розвиток' },
    ],
  },
  {
    value: 12,
    label: 'Оплата з балансу клієнта',
    subcategories: [],
  },
  {
    value: 13,
    label: 'Архівні цукерки',
    subcategories: [],
  },
];

const PAYMENT_LINK_LIFETIME = {
  MAX: 69120000,
};

const SERVICE_PAYMENT_TYPE = [
  { value: 0, label: 'Оплата гонорару за договором', payforValue: 1 },
  { value: 1, label: 'Щомісячна оплата за договором', payforValue: 2 },
  { value: 2, label: 'Оплата за вихід адвоката', payforValue: 4 },
  { value: 3, label: 'Оформлення юридичної страховки' },
  { value: 4, label: 'Продовження юридичної страховки' },
  { value: 5, label: 'Щомісячна оплата за ведення справи', payforValue: 6 },
  { value: 6, label: 'Оплата за консультацію', payforValue: 5 },
  { value: 7, label: 'Оплата за запитання', payforValue: 7 },
  { value: 8, label: 'Автоматична оплата послуг' },
  { value: 9, label: 'Кешбек за автооплату по договору' },
  { value: 10, label: 'Винагорода за запрошення' },
  { value: 11, label: 'Оплата за тривожну кнопку' },
  { value: 12, label: 'Оплата за страховку', payforValue: 8 },
  { value: 13, label: 'Поповнення балансу' },
  { value: 14, label: 'Подяка юристу' },
  { value: 15, label: 'Покупка клубної карти', payforValue: 9 },
  { value: 16, label: 'Чайові' },
  { value: 17, label: 'Кешбек за оплату по договору наперед' },
  { value: 18, label: 'Оплата заборговоності з балансу' },
  { value: 19, label: 'Повернення 30% з бонусів' },
  { value: 20, label: 'Оплата за судовий збір', payforValue: 3 },
  { value: 21, label: 'Оплата за вебінар' },
  { value: 22, label: 'Повернення коштів' },
  { value: 23, label: 'Оплата за пошук в реестрах', payforValue: 10 },
  { value: 24, label: 'Повернення за консультацію' },
  { value: 25, label: 'Бронювання гонорару' },
];

const SERVICE_PAYMENT_TYPE_FOR_EADVOKAT = [
  {
    value: 0,
    translations: {
      ua: 'Оплата гонорару за договором',
      ru: 'Оплата гонорара по договору',
      en: 'Fee payment under contract',
      de: 'Honorarzahlung gemäß Vertrag',
      fr: 'Paiement des honoraires selon le contrat',
      es: 'Pago de honorarios según el contrato',
      cz: 'Platba odměny dle smlouvy',
    },
    payforValue: 1,
  },
  {
    value: 1,
    translations: {
      ua: 'Щомісячна оплата за договором',
      ru: 'Ежемесячная оплата по договору',
      en: 'Monthly payment under contract',
      de: 'Monatliche Zahlung gemäß Vertrag',
      fr: 'Paiement mensuel selon le contrat',
      es: 'Pago mensual según el contrato',
      cz: 'Měsíční platba dle smlouvy',
    },
    payforValue: 2,
  },
  {
    value: 2,
    translations: {
      ua: 'Оплата за вихід адвоката',
      ru: 'Оплата за выход адвоката',
      en: 'Payment for lawyer appearance',
      de: 'Zahlung für Anwaltsauftritt',
      fr: "Paiement pour la prestation de l'avocat",
      es: 'Pago por aparición del abogado',
      cz: 'Platba za vystoupení právníka',
    },
    payforValue: 4,
  },
  {
    value: 3,
    translations: {
      ua: 'Оформлення юридичної страховки',
      ru: 'Оформление юридической страховки',
      en: 'Legal insurance processing',
      de: 'Rechtsschutzversicherung bearbeiten',
      fr: "Traitement de l'assurance juridique",
      es: 'Procesamiento de seguro legal',
      cz: 'Zpracování právního pojištění',
    },
  },
  {
    value: 4,
    translations: {
      ua: 'Продовження юридичної страховки',
      ru: 'Продление юридической страховки',
      en: 'Legal insurance renewal',
      de: 'Verlängerung der Rechtsschutzversicherung',
      fr: "Renouvellement de l'assurance juridique",
      es: 'Renovación del seguro legal',
      cz: 'Obnovení právního pojištění',
    },
  },
  {
    value: 5,
    translations: {
      ua: 'Щомісячна оплата за ведення справи',
      ru: 'Ежемесячная оплата за ведение дела',
      en: 'Monthly payment for case management',
      de: 'Monatliche Zahlung für die Fallbearbeitung',
      fr: 'Paiement mensuel pour la gestion du dossier',
      es: 'Pago mensual por gestión de casos',
      cz: 'Měsíční platba za správu případu',
    },
    payforValue: 6,
  },
  {
    value: 6,
    translations: {
      ua: 'Оплата за консультацію',
      ru: 'Оплата за консультацию',
      en: 'Payment for consultation',
      de: 'Zahlung für Beratung',
      fr: 'Paiement pour la consultation',
      es: 'Pago por consulta',
      cz: 'Platba za konzultaci',
    },
    payforValue: 5,
  },
  {
    value: 7,
    translations: {
      ua: 'Оплата за запитання',
      ru: 'Оплата за вопрос',
      en: 'Payment for question',
      de: 'Zahlung für Frage',
      fr: 'Paiement pour la question',
      es: 'Pago por pregunta',
      cz: 'Platba za otázku',
    },
    payforValue: 7,
  },
  {
    value: 8,
    translations: {
      ua: 'Автоматична оплата послуг',
      ru: 'Автоматическая оплата услуг',
      en: 'Automatic service payment',
      de: 'Automatische Dienstleistungzahlung',
      fr: 'Paiement automatique des services',
      es: 'Pago automático de servicios',
      cz: 'Automatická platba služeb',
    },
  },
  {
    value: 9,
    translations: {
      ua: 'Кешбек за автооплату по договору',
      ru: 'Кешбек за автооплату по договору',
      en: 'Cashback for automatic payment under contract',
      de: 'Cashback für automatische Zahlungen gemäß Vertrag',
      fr: 'Cashback pour paiement automatique selon le contrat',
      es: 'Reembolso por pago automático según contrato',
      cz: 'Cashback za automatickou platbu dle smlouvy',
    },
  },
  {
    value: 10,
    translations: {
      ua: 'Винагорода за запрошення',
      ru: 'Вознаграждение за приглашение',
      en: 'Reward for invitation',
      de: 'Belohnung für die Einladung',
      fr: "Récompense pour l'invitation",
      es: 'Recompensa por invitación',
      cz: 'Odměna za pozvání',
    },
  },
  {
    value: 11,
    translations: {
      ua: 'Оплата за тривожну кнопку',
      ru: 'Оплата за тревожную кнопку',
      en: 'Payment for panic button',
      de: 'Zahlung für Notrufknopf',
      fr: "Paiement pour bouton d'urgence",
      es: 'Pago por botón de pánico',
      cz: 'Platba za tlačítko paniky',
    },
  },
  {
    value: 12,
    translations: {
      ua: 'Оплата за страховку',
      ru: 'Оплата за страховку',
      en: 'Payment for insurance',
      de: 'Zahlung für Versicherung',
      fr: "Paiement pour l'assurance",
      es: 'Pago por seguro',
      cz: 'Platba za pojištění',
    },
    payforValue: 8,
  },
  {
    value: 13,
    translations: {
      ua: 'Поповнення балансу',
      ru: 'Пополнение баланса',
      en: 'Balance replenishment',
      de: 'Kontogutschrift',
      fr: 'Rapprovisionnement de solde',
      es: 'Reabastecimiento de saldo',
      cz: 'Doplňování zůstatku',
    },
  },
  {
    value: 14,
    translations: {
      ua: 'Подяка юристу',
      ru: 'Благодарность юристу',
      en: 'Thank you to the lawyer',
      de: 'Danke an den Anwalt',
      fr: "Merci à l'avocat",
      es: 'Gracias al abogado',
      cz: 'Poděkování právníkovi',
    },
  },
  {
    value: 15,
    translations: {
      ua: 'Покупка клубної карти',
      ru: 'Покупка клубной карты',
      en: 'Purchase of club card',
      de: 'Kauf der Clubkarte',
      fr: 'Achat de la carte club',
      es: 'Compra de tarjeta de club',
      cz: 'Nákup klubové karty',
    },
    payforValue: 9,
  },
  {
    value: 16,
    translations: {
      ua: 'Чайові',
      ru: 'Чаевые',
      en: 'Tips',
      de: 'Trinkgeld',
      fr: 'Pourboire',
      es: 'Propinas',
      cz: 'Spropitné',
    },
  },
  {
    value: 17,
    translations: {
      ua: 'Кешбек за оплату по договору наперед',
      ru: 'Кешбек за оплату по договору наперед',
      en: 'Cashback for advance payment under contract',
      de: 'Cashback für Vorauszahlung gemäß Vertrag',
      fr: 'Cashback pour paiement anticipé selon le contrat',
      es: 'Reembolso por pago anticipado según contrato',
      cz: 'Cashback za předplacení podle smlouvy',
    },
  },
  {
    value: 18,
    translations: {
      ua: 'Оплата заборговоності з балансу',
      ru: 'Оплата задолженности с баланса',
      en: 'Debt repayment from balance',
      de: 'Schuldenrückzahlung vom Guthaben',
      fr: 'Remboursement de la dette à partir du solde',
      es: 'Pago de deudas desde el saldo',
      cz: 'Splácení dluhu z kreditu',
    },
  },
  {
    value: 19,
    translations: {
      ua: 'Повернення 30% з бонусів',
      ru: 'Возврат 30% с бонусов',
      en: 'Return of 30% from bonuses',
      de: 'Rückgabe von 30% der Boni',
      fr: 'Retour de 30% des bonus',
      es: 'Devolución del 30% de los bonos',
      cz: 'Vrácení 30% z bonusů',
    },
  },
  {
    value: 20,
    translations: {
      ua: 'Оплата за судовий збір',
      ru: 'Оплата за судебный сбор',
      en: 'Payment for court fee',
      de: 'Zahlung für Gerichtsgebühr',
      fr: 'Paiement des frais de justice',
      es: 'Pago de tasas judiciales',
      cz: 'Platba za soudní poplatek',
    },
    payforValue: 3,
  },
  {
    value: 21,
    translations: {
      ua: 'Оплата за вебінар',
      ru: 'Оплата за вебинар',
      en: 'Payment for webinar',
      de: 'Zahlung für Webinar',
      fr: 'Paiement pour le webinaire',
      es: 'Pago por el seminario web',
      cz: 'Platba za webinář',
    },
  },
  {
    value: 22,
    translations: {
      ua: 'Повернення коштів',
      ru: 'Возврат средств',
      en: 'Refund',
      de: 'Rückerstattung',
      fr: 'Remboursement',
      es: 'Reembolso',
      cz: 'Vrácení peněz',
    },
  },
  {
    value: 23,
    translations: {
      ua: 'Оплата за пошук в реєстрах',
      ru: 'Оплата за поиск в реестрах',
      en: 'Payment for registry search',
      de: 'Zahlung für Registerabfrage',
      fr: 'Paiement pour la recherche dans les registres',
      es: 'Pago por búsqueda en registros',
      cz: 'Platba za vyhledávání v registrech',
    },
    payforValue: 10,
  },
  {
    value: 24,
    translations: {
      ua: 'Повернення за консультацію',
      ru: 'Возврат за консультацию',
      en: 'Refund for consultation',
      de: 'Rückerstattung für Beratung',
      fr: 'Remboursement pour la consultation',
      es: 'Reembolso por consulta',
      cz: 'Vrácení za konzultaci',
    },
  },
  {
    value: 25,
    translations: {
      ua: 'Бронювання гонорару',
      en: 'Fee booking',
      de: 'Gebührenbuchung',
      ru: 'Бронирование гонорара',
      fr: 'Réservation d’honoraire',
      es: 'Reserva de honorario',
      cz: 'Rezervace honoráře',
    },
  },
];

const CASHBACK_OPEN_TYPES = [0, 1, 3, 4, 5, 6, 7, 8, 11, 12, 15, 18, 21];

const TENDER_TERMS = [
  { value: 0, label: 'Менше місяця' },
  { value: 1, label: 'До 3 місяців' },
  { value: 2, label: 'До 6 місяців' },
  { value: 3, label: 'До року' },
  { value: 4, label: 'Не зазначати' },
];

const TENDER_TERMS_FOR_EADVOKAT = {
  ua: [
    { value: 0, label: 'Менше місяця' },
    { value: 1, label: 'До 3 місяців' },
    { value: 2, label: 'До 6 місяців' },
    { value: 3, label: 'До року' },
    { value: 4, label: 'Не зазначати' },
  ],
  ru: [
    { value: 0, label: 'Менее месяца' },
    { value: 1, label: 'До 3 месяцев' },
    { value: 2, label: 'До 6 месяцев' },
    { value: 3, label: 'До года' },
    { value: 4, label: 'Не указывать' },
  ],
  de: [
    { value: 0, label: 'Weniger als einen Monat' },
    { value: 1, label: 'Bis zu 3 Monate' },
    { value: 2, label: 'Bis zu 6 Monate' },
    { value: 3, label: 'Bis zu einem Jahr' },
    { value: 4, label: 'Nicht angeben' },
  ],
  en: [
    { value: 0, label: 'Less than a month' },
    { value: 1, label: 'Up to 3 months' },
    { value: 2, label: 'Up to 6 months' },
    { value: 3, label: 'Up to a year' },
    { value: 4, label: 'Not specified' },
  ],
  fr: [
    { value: 0, label: "Moins d'un mois" },
    { value: 1, label: "Jusqu'à 3 mois" },
    { value: 2, label: "Jusqu'à 6 mois" },
    { value: 3, label: "Jusqu'à un an" },
    { value: 4, label: 'Non spécifié' },
  ],
  es: [
    { value: 0, label: 'Menos de un mes' },
    { value: 1, label: 'Hasta 3 meses' },
    { value: 2, label: 'Hasta 6 meses' },
    { value: 3, label: 'Hasta un año' },
    { value: 4, label: 'No especificado' },
  ],
  cz: [
    { value: 0, label: 'Méně než měsíc' },
    { value: 1, label: 'Až 3 měsíce' },
    { value: 2, label: 'Až 6 měsíců' },
    { value: 3, label: 'Až rok' },
    { value: 4, label: 'Není uvedeno' },
  ],
};

const TENDERS_FILTER = {
  WATCHED: 'watched',
  UNWATCHED: 'unwatched',
  ALL: 'all',
  MY: 'my',
  IN_PROCESS: 'in_process',
};

const OPTIONS_TENDERS_FILTER = [
  { label: 'Не переглянуті', value: TENDERS_FILTER.UNWATCHED },
  { label: 'Переглянуті', value: TENDERS_FILTER.WATCHED },
  { label: 'Всі', value: TENDERS_FILTER.ALL },
  { label: 'Мої', value: TENDERS_FILTER.MY },
  { label: 'В роботі', value: TENDERS_FILTER.IN_PROCESS },
];

const CONTRACT_OPTION_CONST = {
  CLIENT_NAME: {
    name: 'CLIENT_NAME',
    label: 'ПІБ',
  },
  CLIENT_SHORT_NAME: {
    name: 'CLIENT_SHORT_NAME',
    label: 'Ініціали клієнта',
  },
  CLIENT_SEX: {
    name: 'CLIENT_SEX',
    label: 'Стать',
  },
  CLIENT_FORM: {
    name: 'CLIENT_FORM',
    label: 'ФО/ФОП/ЮО',
  },
  CLIENT_BIRTHDAY: {
    name: 'CLIENT_BIRTHDAY',
    label: 'День народження',
  },
  CLIENT_FILIA: {
    name: 'CLIENT_FILIA',
    label: 'Філія',
  },
  CLIENT_BIRTHPLACE: {
    name: 'CLIENT_BIRTHPLACE',
    label: 'Місто народження',
  },
  CLIENT_ADDRESS: {
    name: 'CLIENT_ADDRESS',
    label: 'Адреса',
  },
  CLIENT_PASSPORT_SERIES: {
    name: 'CLIENT_PASSPORT_SERIES',
    label: 'Серія та номер',
  },
  CLIENT_PASSPORT_ISSUED_BY: {
    name: 'CLIENT_PASSPORT_ISSUED_BY',
    label: 'Ким виданий',
  },
  CLIENT_PASSPORT_ISSUE_DATE: {
    name: 'CLIENT_PASSPORT_ISSUE_DATE',
    label: 'Дата видачі',
  },
  CLIENT_PASSPORT_VALIDITY_DATE: {
    name: 'CLIENT_PASSPORT_VALIDITY_DATE',
    label: 'Дійсний до',
  },
  CLIENT_IPN: {
    name: 'CLIENT_IPN',
    label: 'ІПН',
  },
  CLIENT_PHONE: {
    name: 'CLIENT_PHONE',
    label: 'Телефон',
  },
  CLIENT_EMAIL: {
    name: 'CLIENT_EMAIL',
    label: 'E-mail',
  },
  CLIENT_PROFESSION: {
    name: 'CLIENT_PROFESSION',
    label: 'Професія',
  },
  CLIENT_SOCIAL: {
    name: 'CLIENT_SOCIAL',
    label: 'Соц статус',
  },
  CLIENT_POSITION: {
    name: 'CLIENT_POSITION',
    label: 'Службове становище',
  },
  CLIENT_HOBBY: {
    name: 'CLIENT_HOBBY',
    label: 'Хобі',
  },
  CONTRACT_RED_BUTTON: {
    name: 'CONTRACT_RED_BUTTON',
    label: 'Тривожна кнопка',
  },
  CONTRACT_OV: {
    name: 'CONTRACT_OV',
    label: 'ОВ',
  },
  CONTRACT_SIGN_DATE: {
    name: 'CONTRACT_SIGN_DATE',
    label: 'Дата підписання',
  },
  CONTRACT_SIGNER: {
    name: 'CONTRACT_SIGNER',
    label: 'Уклав',
  },
  CONTRACT_SIGNER_SHORT_NAME: {
    name: 'CONTRACT_SIGNER_SHORT_NAME',
    label: 'Уклав (ініціали)',
  },
  CONTRACT_1P_DATE: {
    name: 'CONTRACT_1P_DATE',
    label: 'Дата 1п',
  },
  CONTRACT_1P_SUM: {
    name: 'CONTRACT_1P_SUM',
    label: 'Сума (1п?)',
  },
  CONTRACT_TYPE: {
    name: 'CONTRACT_TYPE',
    label: 'Вид правовідносин',
  },
  CONTRACT_TYPE_WHAT: {
    name: 'CONTRACT_TYPE_WHAT',
    label: 'Вид правовідносин Що саме',
  },
  DOCUMENT_TO_NAME: {
    name: 'DOCUMENT_TO_NAME',
    label: 'Назва установи МФО',
  },
  MFO_INFO: {
    name: 'MFO_INFO',
    label: 'Адреса МФО',
  },
  NOW_DATE: {
    name: 'NOW_DATE',
    label: 'Дата створення шаблону',
  },
  DOCUMENT_TO_NAME: {
    name: 'DOCUMENT_TO_NAME',
    label: 'Повна назва установи МФО',
  },
  MFO_INFO: {
    name: 'MFO_INFO',
    label: 'Реквізити МФО',
  },
  INSURANCE_MINIMAL_PRICE_6: {
    name: 'INSURANCE_MINIMAL_PRICE_6',
    label: 'Мінімальна сума страхового договору на 6 місяців',
  },
  INSURANCE_MINIMAL_PRICE_12: {
    name: 'INSURANCE_MINIMAL_PRICE_12',
    label: 'Мінімальна сума страхового договору на рік',
  },
  INSURANCE_MINIMAL_PRICE_36: {
    name: 'INSURANCE_MINIMAL_PRICE_36',
    label: 'Мінімальна сума страхового договору на 3 роки',
  },
  INSURANCE_DESCRIPTION: {
    name: 'INSURANCE_DESCRIPTION',
    label: 'Опис страховки',
  },
  INSURANCE_SERVICES: {
    name: 'INSURANCE_SERVICES',
    label: 'Послуги',
  },
  INSURANCE_DATE_RECEIVING: {
    name: 'INSURANCE_DATE_RECEIVING',
    label: 'Дата початку страховки',
  },
  INSURANCE_DATE_ACTIVATION: {
    name: 'INSURANCE_DATE_ACTIVATION',
    label: 'Дата активації страховки',
  },
};

const PAY_QUICKCHAT = [
  {
    index: 0,
    value: 1,
    amount: 5,
    discount: 0,
    discountPrice: 0,
  },
  {
    index: 1,
    value: 5,
    amount: 20,
    discount: 20,
    discountPrice: 5,
  },
  {
    index: 2,
    value: 10,
    amount: 30,
    discount: 40,
    discountPrice: 20,
  },
  {
    index: 3,
    value: 40,
    amount: 80,
    discount: 60,
    discountPrice: 120,
  },
  {
    index: 4,
    value: 100,
    amount: 100,
    discount: 80,
    discountPrice: 400,
  },
];

const CONTRACT_AUTOPAY_CASHBACK = 0.05;

const RED_BUTTON = {
  value: 'red_btn',
  label: 'Тривожна кнопка',
};

const RED_BUTTON_PRICE = 5000;

const RED_BUTTON_MONTHLY_PRICE = 991;

const TEST_FIL = 999;

const WINDOWS_OC_APP_SUGGESTION_LINK = 'https://play.google.com/store/apps/details?id=com.zahist.client';

const MAC_OC_APP_SUGGESTION_LINK = 'https://apps.apple.com/us/app/%D1%94%D0%90%D0%B4%D0%B2%D0%BE%D0%BA%D0%B0%D1%82/id6463437697';

const CLIENT_SUGGESTION_BONUS = 50;

const APP_TYPE = {
  CRM: 'crm',
  CLIENT: 'client',
};

const TEMPLATE_DOCUMENT_TYPES = {
  0: 'Контракти',
  1: 'Страховки',
};

const CONFLICT_STATUS = {
  active: 'ACTIVE',
  completed: 'COMPLETED',
};

const CLUB_CARDS_TYPES = {
  SILVER: 'SILVER',
  GOLD: 'GOLD',
  PLATINUM: 'PLATINUM',
};
const CLUB_CARDS_PERIODS = {
  1: 0,
  3: 1,
  6: 2,
  12: 3,
  36: 4,
};

const CLUB_CARDS_SERVICES = {
  0: {
    LABEL: 'Швидке питання',
    SILVER: 5,
    GOLD: 30,
    PLATINUM: 'Infinity',
    LINK: 'CLIENT_QUICK_CHAT',
  },
  1: {
    LABEL: 'Консультація',
    SILVER: 0,
    GOLD: 3,
    PLATINUM: 10,
    LINK: 'HOTLINE',
  },
  2: {
    LABEL: 'Подарувати консультацію знайомому',
    SILVER: 2,
    GOLD: 4,
    PLATINUM: 15,
    LINK: 'HOTLINE',
  },
  3: {
    LABEL: 'Зустріч з адвокатом по справі',
    SILVER: 2,
    GOLD: 5,
    PLATINUM: 'Infinity',
    LINK: 'CONTRACTS',
  },
  4: {
    LABEL: '"Преміум" консультація',
    SILVER: 0,
    GOLD: 1,
    PLATINUM: 5,
    LINK: 'HOTLINE',
  },
  5: {
    LABEL: 'Послуга "Тривожна кнопка"',
    SILVER: 0,
    GOLD: 0,
    PLATINUM: 1,
    LINK: 'RED_BUTTON',
  },
  6: {
    LABEL: 'Участь адвоката у переговорах',
    SILVER: 0,
    GOLD: 1,
    PLATINUM: 3,
    LINK: 'CONTRACTS',
  },
  7: {
    LABEL: 'Претензійна робота',
    SILVER: 0,
    GOLD: 2,
    PLATINUM: 5,
    LINK: 'CONTRACTS',
  },
  8: {
    LABEL: 'Підготовка документу',
    SILVER: 0,
    GOLD: 3,
    PLATINUM: 10,
    LINK: 'CONTRACTS',
  },
  9: {
    LABEL: 'Письмова консультація',
    SILVER: 0,
    GOLD: 1,
    PLATINUM: 4,
    LINK: 'HOTLINE',
  },
  10: {
    LABEL: 'Вихід адвоката в суд',
    SILVER: 0,
    GOLD: 1,
    PLATINUM: 3,
    LINK: 'CONTRACTS',
  },
  // 11: {
  //   LABEL: 'АІ асистент',
  //   SILVER: 1,
  //   GOLD: 1,
  //   PLATINUM: 1,
  //   LINK: 'ASISTENT',
  // },
  // 12: {
  //   LABEL: 'Особистий консультант 8 годин',
  //   SILVER: 0,
  //   GOLD: 1,
  //   PLATINUM: 0,
  //   LINK: 'ASISTENT',
  // },
  // 13: {
  //   LABEL: 'Особистий консультант 24 години',
  //   SILVER: 0,
  //   GOLD: 0,
  //   PLATINUM: 1,
  //   LINK: 'ASISTENT',
  // },
};

const CLUB_CARD_PRICES = {
  0: {
    GOLD: {
      LABEL: '3 000',
      AMOUNT: 3000,
    },
    PLATINUM: {
      LABEL: '10 000',
      AMOUNT: 10000,
    },
    PERIOD: { LABEL: '1 місяць', MONTHS: 1 },
  },
  1: {
    GOLD: {
      LABEL: '2 500',
      AMOUNT: 2500,
    },
    PLATINUM: {
      LABEL: '9 000',
      AMOUNT: 9000,
    },
    PERIOD: { LABEL: '3 місяці', MONTHS: 3 },
  },
  2: {
    GOLD: {
      LABEL: '2 000',
      AMOUNT: 2000,
    },
    PLATINUM: {
      LABEL: '8 000',
      AMOUNT: 8000,
    },
    PERIOD: { LABEL: '6 місяців', MONTHS: 6 },
  },
  3: {
    GOLD: {
      LABEL: '1 500',
      AMOUNT: 1500,
    },
    PLATINUM: {
      LABEL: '7 000',
      AMOUNT: 7000,
    },
    PERIOD: { LABEL: '1 рік', MONTHS: 12 },
  },
  4: {
    GOLD: {
      LABEL: '1 000',
      AMOUNT: 1000,
    },
    PLATINUM: {
      LABEL: '6 000',
      AMOUNT: 6000,
    },
    PERIOD: { LABEL: '3 роки', MONTHS: 36 },
  },
};

const CLUB_CARD_ADVANTAGES = {
  SILVER: ['Стандартне обслуговування', 'Доступ до штучного інтелекту єАдвокат'],
  GOLD: ['VIP обслуговування', 'Доступ до штучного інтелекту єАдвокат', 'Персональний адвокат 8 годин, 5 днів на тиждень'],
  PLATINUM: [
    'Premium обслуговування',
    'Доступ до штучного інтелекту єАдвокат',
    'Персональний адвокат 24 години, 7 днів на тиждень',
    'Терміновий виїзд адвоката на адресу по всій Україні',
  ],
};

const CHAT_TYPES = {
  lawyerChat: {
    key: 'PRIVATE_CHAT',
    label: 'Особисті',
  },
  groupChat: {
    key: 'GROUP_CHAT',
    label: 'Групові',
  },
  chatWithClient: {
    key: 'CHAT_WITH_CLIENT',
    label: 'З клієнтами',
  },
  contractChat: {
    key: 'CONTRACT_CHAT',
    label: 'По договору',
  },
  redButtonChat: {
    key: 'RED_BUTTON_CHAT',
    label: 'Термінові',
  },
  accountantChat: {
    key: 'ACCOUNTANT_CHAT',
    label: 'Бух чат',
  },
  personalConsultantChat: {
    key: 'PERSONAL_CONSULTANT_CHAT',
    label: 'Консультанти',
  },
};

const LANGUAGE_OPTIONS = [
  { value: 1, label: 'Англійська' },
  { value: 2, label: 'Французька' },
  { value: 3, label: 'Німецька' },
  { value: 4, label: 'Іспанська' },
  { value: 5, label: 'Італійська' },
  { value: 6, label: 'Грузинська' },
  { value: 7, label: 'Молдовська' },
  { value: 8, label: 'Голландська' },
  { value: 9, label: 'Польська' },
  { value: 10, label: 'Шведська' },
  { value: 11, label: 'Чеська' },
  { value: 12, label: 'Угорська' },
  { value: 13, label: 'Фінська' },
  { value: 14, label: 'Словацька' },
  { value: 15, label: 'Датська' },
  { value: 16, label: 'Норвезька' },
  { value: 17, label: 'Ірландська' },
  { value: 18, label: 'Естонська' },
  { value: 19, label: 'Латиська' },
  { value: 20, label: 'Литовська' },
  { value: 21, label: 'Арабська' },
  { value: 22, label: 'Китайська' },
  { value: 23, label: 'Японська' },
  { value: 24, label: 'Корейська' },
  { value: 25, label: 'Португальська' },
  { value: 26, label: 'Туркменська' },
  { value: 27, label: 'Турецька' },
  { value: 28, label: 'Казахська' },
  { value: 29, label: 'Татарська' },
  { value: 30, label: 'Киргизька' },
  { value: 31, label: 'Узбецька' },
  { value: 99, label: 'Не володію жодною іноземною мовою' },
];

const PAYROLL_TYPES = {
  cash: 'cashPercent',
  card: 'cardPercent',
  fop: 'fopPercent',
};

const PEREDAST_PERCENT = 1;

const USER_FUNCTIONS = {
  consultation: 'Запис на консультацію',
  myConsultations: 'Мої консультації',
  chats: 'Чати',
  quickQuestion: 'Швидке питання',
  myCases: 'Мої справи',
  tenders: 'Тендери',
  insuranceCard: 'Юридична страховка',
  balance: 'Баланс',
  cashback: '5% кешбек',
  partnerCard: 'Партнерська карта',
  clubCard: 'Клубна карта',
  reviews: 'Відгуки',
  topUpBalance: 'Поповнити баланс',
  scanQRCode: 'Сканувати QR-код',
};

const CURRENCIES = {
  UAH: { value: 'UAH', symbol: '₴', label: 'грн', code: 980 },
  EUR: { value: 'EUR', symbol: '€', label: 'євро', code: 978 },
  CZK: { value: 'CZK', symbol: 'Kč', label: 'чеська крона', code: 203 },
};

const USER_TOKEN_TYPE = {
  crmUser: 'CRM_USER',
  client: 'CLIENT',
};

const MONOBANK_ORDER_CODE = 'd21da1c47f3c45fca10a10c32518bde1';

const PAYMENT_STATUS = {
  SUCCESS: 'success',
  PROCESSING: 'processing',
  FAILURE: 'failure',
  REVERSED: 'reversed',
  CREATED: 'created',
  NEW: 'new',
};

const WEBVIEW_MESSAGE_TYPES = {
  BIOMETRIC_INITIAL: 'getBiometricInitialValue',
  TOGGLE_BIOMETRIC: 'toggleBiometric',
  USER_AUTH_DATA: 'userAuthData',
};

const COUNTRY_PHONE_CODES = {
  UA: '38',
  DE: '49',
  CZ: '420',
};

const WEBINAR_OWN_STATUSES = {
  NOT_PAYED: 0,
  PAYED: 1,
};

const WEBINAR_STATUSES = {
  PLANNED: 0,
  STARTED: 1,
  FINISHED: 2,
};

const NO_REGISTER_REASONS = {
  1: 'Я Ваш клієнт та телефонував з іншого питання',
  2: 'Не сподобалося спілкування з оператором',
  3: 'Не зрозумілі умови прийому',
  4: 'Мені відмовили у допомозі',
  5: 'Не влаштувала вартість консультації',
  6: 'Не підходить час прийому',
  7: 'Відсутність офісу в моєму регіоні',
  8: 'Я передумав',
  9: 'Я дізнавався інформацію для знайомого',
  10: 'Я хотів задати швидке питання, без запису',
  11: 'Інша причина',
};

const MALE_ASSISTANTS = [
  {
    index: 0,
    name: 'Сидоренко Ірина Петрівна',
    key: 'OPENAI_ASSISTANT_M_0_24_ID',
    avatar: 'oleksandr9633_httpss_mj_runY35oGWhWpqY_make_a_photo_as_simila_f2c7b792.png',
  },
  {
    index: 1,
    name: 'Авриш Елеонора Йосипівна',
    key: 'OPENAI_ASSISTANT_M_25_45_ID',
    avatar: 'oleksandr9633_httpss_mj_runPPlCXqPsvZ0_make_a_photo_as_simila_43d7ff36.png',
  },
  {
    index: 2,
    name: 'Сьоміна Христина Сергіївна',
    key: 'OPENAI_ASSISTANT_M_46_65_ID',
    avatar: 'oleksandr9633_httpss_mj_runLdSPf_yhiiQ_make_a_photo_as_simila_fbf01279.png',
  },
  {
    index: 3,
    name: 'Приходько Евгенія Олексіївна',
    key: 'OPENAI_ASSISTANT_M_66_ID',
    avatar: 'oleksandr9633_httpss_mj_runm4mg4Vk26Hk_make_a_photo_as_simila_3d976e27.png',
  },
];

const FEMALE_ASSISTANTS = [
  {
    index: 4,
    name: 'Прокопенко Сергій Олександрович',
    key: 'OPENAI_ASSISTANT_F_0_24_ID',
    avatar: 'oleksandr9633_httpss_mj_runnCa_dcdy6vo_make_a_photo_as_simila_a8b4adc2.png',
  },
  {
    index: 5,
    name: 'Бардаш Володимир Венедиктович',
    key: 'OPENAI_ASSISTANT_F_25_45_ID',
    avatar: 'oleksandr9633_httpss_mj_runRg7vCDkH8YY_make_a_photo_as_simila_7ce57c7b.png',
  },
  {
    index: 6,
    name: 'Яремчук Максим Олександрович',
    key: 'OPENAI_ASSISTANT_F_46_65_ID',
    avatar: 'oleksandr9633_httpss_mj_runvL9hiMwsnWs_make_a_photo_as_simila_823a0e20.png',
  },
  {
    index: 7,
    name: 'Боголюб Микола Іванович',
    key: 'OPENAI_ASSISTANT_F_66_ID',
    avatar: 'oleksandr9633_httpss_mj_runk0OBO7LKs98_make_a_photo_as_simila_4b461d31.png',
  },
];

const REGISTER_PRICE = 10;

const CLIENT_CATEGORIES = {
  MISSED_CALL: { value: 10, label: 'Недозвон' },
  NOT_MAKE_APPOINTMENT: { value: 20, label: 'Не записався' },
  MAKE_APPOINTMENT: { value: 30, label: 'Є запис, не прозвітований або клієнт не прийшов' },
  NOT_SIGN: { value: 40, label: 'Не укладено' },
  SIGN_NOT_PAY: { value: 50, label: 'Укладено та не оплачено' },
  SING_AND_PAY: { value: 60, label: 'Укладено та оплачено' },
};

const CASHBACK_30_START_DATE = '2024-08-29';

const HOTLINE_CALLBACK_STATUS = [
  // { value: 1, label: 'Запис' },
  { value: 2, label: 'Відмова' },
  { value: 3, label: 'Не бере' },
  { value: 4, label: 'Вже записаний' },
];

const HOTLINE_CALLBACK_RESULT = [
  { value: 1, label: 'Наш клієнт' },
  { value: 2, label: 'Помилився номером' },
  { value: 3, label: 'Не для себе' },
  { value: 4, label: 'Вартість консультації' },
  { value: 5, label: 'Не підходить час' },
  { value: 6, label: 'Хотів тільки запитать' },
  { value: 7, label: 'Я Вам передзвоню' },
  { value: 8, label: 'Відсутній офіс в регіоні' },
  { value: 10, label: 'Обірвався дзвінок' },
  { value: 9, label: 'Інша причина' },
];

const SALES_TYPE = {
  1: { label: 'Введіть свій номер щоб отримати знижку', amount: 0.1 },
  2: { label: 'СМС анкетування', amount: 0.1 },
};

const PERCENTS = {
  REDUCE_FONDY: 1.3,
};

const PEREDAST_TYPES = [2, 5];

const HOTLINE_CALL_REPORT_FIELDS_CHECK = ['status', 'refusalReason', 'pravoType', 'clientName', 'se', 'state'];

const AUTO_SENDING_CONTRACT_TEMPLATES_TAG = 'анк123';

const CURRENCY_CODE_MAP = {
  USD: 840,
  EUR: 978,
  UAH: 980,
  CZK: 203,
  PLN: 985,
};

const CALL_NOT_LINKED_REASONS = [
  'Дзвінок на робочий номер',
  'Клієнт без запису',
  'Запис з соц мереж',
  'Запис з месенжерів',
  'Перенесення запису',
  'Технічні проблеми',
  'Власна причина',
];

const FIRST_LOGIN_BONUS_VALUE = 300;

const POINT_DATA_TYPES = {
  CALL: { value: 'CALL', points: 1, label: 'Дзвінок' },
  CHAT: { value: 'CHAT', points: 1, label: 'Чат' },
  HOTLINE: { value: 'HOTLINE', points: 4, label: 'Консультація' },
  RED_BUTTON: { value: 'BUTTON', points: 4, label: 'Тривожна кнопка' },
};

const HOTLINE_PAY_LINK = '/hotline/hotlinePay';

const PAY_FOR_CONTRACT = '2';
const PAY_FOR_COURT_FEE = 3;
const PAYFOR_DEPOSIT = 51;

const REWARD_TYPES = { TALANTS: 'talants', DAY_OFF: 'day_off', FAIL: 'fail', BOB: 'BOB', WHEEL_SPIN: 'wheel_spin' };
const CRM_BALANCE_TRANSACTION_TYPES = {
  SEND: 'transfer_send',
  RECEIVE: 'transfer_receive',
  GIFT_SEND: 'gift_send',
  GIFT_RECEIVE: 'gift_receive',
  BIRTHDAY: 'birthday',
  LIBRARY_UPLOAD: 'library_upload',
  HOTLINES_10: 'hotlines_10',
  CONTRACT_FEEDBACK: 'contract_feedback',
  OVERTIME: 'overtime',
};

const CRM_BALANCE_TRANSACTION_TYPE_TRANSLATIONS = {
  transfer_send: 'Переказ відправлено',
  transfer_receive: 'Переказ отримано',
  transfer: 'Переказ',
  talants: 'Таланти',
  day_off: 'Вихідний',
  fail: 'Невдача',
  BOB: 'БОБ',
  wheel_spin: 'Прокрутки колеса',
  gift_receive: 'Подарунок отримано',
  gift_send: 'Подарунок відправлено',
  birthday: 'День народження',
  library_upload: 'Завантаження в бібліотеку',
  hotlines_10: '10 консультацій',
  contract_feedback: 'Відгук на договір',
  overtime: 'Овертайм',
};

const REWARDS_AMOUNT = {
  BIRTHDAY: 20,
  LIBRARY_UPLOAD: 1,
  HOTLINES_10: 1,
  CONTRACT_FEEDBACK: 3,
  OVERTIME: 1, // for 30 min
};
const CRM_BALANCE_TRANSACTIONS_WITH_TALANTS = [...Object.values(CRM_BALANCE_TRANSACTION_TYPES), 'transfer', 'talants'];

const VOTING_CONTRACT_RESULT = [
  { value: 1, label: 'Негативний' },
  { value: 2, label: 'Частково позитивний' },
  { value: 3, label: 'Позитивний' },
  { value: 4, label: 'Відсутній' },
];

const VOTING_CLIENT_RESULT = [
  { value: 1, label: 'п/п, Пішов погано' },
  { value: 2, label: 'п/c, Пішов середнє' },
  { value: 3, label: 'п/д, Пішов добре' },
];

module.exports = {
  ACCESS_TOKEN,
  APP_TYPE,
  ARCHIVE_FEELS,
  ASSIGNMENT_PAYMENT_STATUS,
  ASSIGNMENT_SERVICE,
  ASSIGNMENT_STATUS_OPTIONS,
  ASSIGNMENT_STATUS,
  ASSIGNMENTS_PLACE_TYPE,
  ASSIGNMENTS_SERVICES_TYPE,
  AUTO_SENDING_CONTRACT_TEMPLATES_TAG,
  BILL_STATUSES,
  CALL_NOT_LINKED_REASONS,
  CASHBACK_30_START_DATE,
  CASHBACK_OPEN_TYPES,
  CATEGORIES,
  CHARACTERISTIC,
  CHAT_TYPES,
  CLIENT_CATEGORIES,
  CLIENT_COLOR,
  CLIENT_HOTLINES_FILTER,
  CLIENT_SUGGESTION_BONUS,
  CLIENT_TOKEN,
  CLUB_CARD_PRICES,
  CLUB_CARDS_PERIODS,
  CLUB_CARDS_SERVICES,
  CLUB_CARDS_TYPES,
  CONFLICT_STATUS,
  CONSULTATION_GROUP,
  CONTRACT_AUTOPAY_CASHBACK,
  CONTRACT_OPTION_CONST,
  COUNTRY_PHONE_CODES,
  CURRENCIES,
  CURRENCY_CODE_MAP,
  DAILY_PARTNER_INVITE_LIMIT,
  DATE_OPTIONS,
  DATE_PERIOD_REACH,
  DECIMAL_RATING_TO_CLIENT,
  DECLINE_REASONS,
  DEFAULT_USER,
  EXACTLY_WHAT_LIBRARY,
  EXPENSE_CATEGORIES,
  FEMALE_ASSISTANTS,
  PAY_PURPOSES,
  PAY_PURPOSES_FOR_EADVOKAT,
  PAY_TYPES,
  BILL_STATUSES,
  USER_ACTIONS,
  TITLE_DB_FOR_CAPS_LOCK,
  TEXT_VSMS_TO_REACH_HOTLINE,
  PSYCHOLOGIST_WORK_TIME,
  WORK_TIME,
  WORK_DAYS,
  UKRAINE_STATES,
  FIL_OLD,
  FIL_ADRESS,
  FIL_ADRESS_DESCRIBE,
  FIL_HEAD,
  FIL_MAP,
  FIL_OLD,
  FIL_VIDEO,
  FIL_ZONES,
  FILIAL_START_BALANCE_DATE,
  FILE_TYPE_LIBRARY,
  FIRST_LOGIN_BONUS_VALUE,
  FONDY_PAY_TYPE,
  FORM_OF_BUSINESS,
  HARVEST_STATUS_OPTIONS,
  HOBBIES,
  HOTLINE_ADV_WHERE_MORE,
  HOTLINE_ADV_WHERE,
  HOTLINE_CALL_REPORT_FIELDS_CHECK,
  HOTLINE_CALLBACK_RESULT,
  HOTLINE_CALLBACK_STATUS,
  HOTLINE_MEET,
  HOTLINE_RESULT_REACH_FILTER,
  HOTLINE_RESULT,
  HOTLINE_STATUS,
  INCOME_TYPES,
  INSURANCE_BALANCE_MULTIPLIER,
  INSURANCE_CAR_ACCIDENT_BONUSES,
  INSURANCE_CAR_ACCIDENT,
  INSURANCE_MIN_PRICE,
  INSURANCE_PAYMENTS,
  INSURANCE_PERIOD,
  INSURANCE_PERIOD_FOR_EADVOKAT,
  INSURANCE_CAR_ACCIDENT_BONUSES,
  JOB_SATISFACTION_TO_CLIENT,
  JOB_SATISFACTION_TO_CLIENT_FOR_EADVOKAT,
  JOBS,
  JOURNAL_BADGE_COLORS,
  LANGUAGE_OPTIONS,
  LAW_BLOCKS,
  LEVEL_STANDARD_4_OPTIONS,
  LOG_EVENT_TYPE,
  LOG_TYPE,
  LOGICAL_ARCHIVE_CANDY_CHOISE_3_OPTIONS,
  LOGICAL_ARCHIVE_CANDY_CHOISE_3_OPTIONS_FOR_EADVOKAT,
  LOGICAL_STANDARD_CHOISE_2_OPTIONS,
  LOGICAL_STANDARD_CHOISE_4_OPTIONS,
  LOGICAL_STANDARD_CHOISE_4_OPTIONS_FOR_EADVOKAT,
  LOGICAL_STANDARD_QUALITY_LEVEL_5_OPTIONS,
  LOGICAL_STANDARD_QUALITY_LEVEL_5_OPTIONS_FOR_EADVOKAT,
  LOGICAL_STARTED_ON_TIME_CHOISE_3_OPTIONS,
  LOGICAL_STARTED_ON_TIME_CHOISE_3_OPTIONS_FOR_EADVOKAT,
  LOGICAL_WOULD_YOU_AGREE_CHOISE_3_OPTIONS,
  LOGICAL_WOULD_YOU_AGREE_CHOISE_3_OPTIONS_FOR_EADVOKAT,
  MAC_OC_APP_SUGGESTION_LINK,
  MALE_ASSISTANTS,
  MEET_STATUS,
  MONOBANK_ORDER_CODE,
  MONTHS_CHANGE_OF_WORDS,
  MONTHS_SMALL,
  MONTHS,
  NAME_ZONE_FILES,
  NO_REGISTER_REASONS,
  NOTIFICATION,
  OPTIONS_TENDERS_FILTER,
  TENDERS_FILTER,
  OPTIONS_WITH_DISABLED_VIA_CLIENT_HOTLINES_FILTER,
  PAID_CONSULTATION_GROUP,
  PARTNER_CLIENT_REWARD,
  PARTNER_PAYMENT_CARD_LENGTH,
  PASSPORT_TYPE,
  PAY_PURPOSES,
  PAY_PURPOSES_FOR_EADVOKAT,
  PAY_QUICKCHAT,
  PAY_TYPES,
  PAYMENT_FORMAT,
  PAYMENT_LINK_LIFETIME,
  PAYMENT_STATUS,
  PAYROLL_TYPES,
  PERCENTS,
  PEREDAST_PERCENT,
  PEREDAST_TYPES,
  PHONE_OFFICE_VIEW_CLIENT_SHORT,
  PHONE_OFFICE_VIEW_CLIENT,
  PHONE_OFFICE_VIEW_CRM_SHORT,
  PHONE_OFFICE_VIEW_CRM,
  POSLUGI,
  POSLUGI_FOR_EADVOKAT,
  POSLUGI_NOMINATIVE_CASE,
  POSLUGI_NOMINATIVE_CASE_MOBILE_SIZE,
  POSLUGI_NOMINATIVE_CASE_REACH_FILTER,
  POSLUGI_TIME,
  PRAVO_FOR_CLIENT,
  PRAVO_TYPES_LIBRARY,
  PRAVO_TYPES,
  PRAVO,
  PROPOSED_AMOUNT_PERIOD_REACH,
  PSYCHOLOGIST_WORK_TIME,
  QUESTIONNAIRE_RUN_AFTER,
  RED_BUTTON_MONTHLY_PRICE,
  RED_BUTTON_PRICE,
  RED_BUTTON,
  REGIONS,
  REGISTER_PRICE,
  RELATIONS,
  REPEATS,
  RESPONSIBILITY_TEAMS,
  REVIEWS_OF_THE_BUSINESS_CARD_SITE,
  RIBBON_VALUE_REACH,
  ROLES,
  SALES_TYPE,
  SERVICE_PAYMENT_TYPE,
  SERVICES_FOR_CLINETS_APP,
  SERVIS_OPTIONS,
  SOC_STATUS,
  SUBCATEGORIES,
  TELEGRAM_CHAT_OFFICE_VIEW_CRM,
  TEMPLATE_DOCUMENT_TYPES,
  TENDER_TERMS,
  TENDER_TERMS_FOR_EADVOKAT,
  TEST_FIL,
  TEXT_VSMS_TO_REACH_HOTLINE,
  TITLE_DB_FOR_CAPS_LOCK,
  TOWNS,
  TYPE_AND_FORM_OF_THE_DOCUMENT_LIBRARY,
  TYPES_OF_BANK_ACCOUNTS,
  TYPES_OF_MAIL,
  UKRAINE_STATES,
  UNUSED_PRAVO,
  URIST_ACTIVITY_REASONS,
  OVERTIME_ACTIVITY_REASONS,
  URIST_ACTIVITY,
  USER_ACTIONS,
  USER_FUNCTIONS,
  USER_TOKEN_TYPE,
  VIBER_CHAT_OFFICE_VIEW_CRM,
  WEBINAR_OWN_STATUSES,
  WEBINAR_STATUSES,
  WEBVIEW_MESSAGE_TYPES,
  WHY_NOT_USE_APP,
  WINDOWS_OC_APP_SUGGESTION_LINK,
  WORK_STATES,
  WORK_TIME,
  POINT_DATA_TYPES,
  HOTLINE_PAY_LINK,
  FONDY_PAY_FOR_TO_COUNT,
  NOT_INCLUDED_IN_PAYMENT_MANAGEMENT,
  PRAVO_TYPES_FOR_EADVOKAT,
  LOGICAL_STANDARD_CHOISE_2_OPTIONS_FOR_EADVOKAT,
  SERVICE_PAYMENT_TYPE_FOR_EADVOKAT,
  CLUB_CARD_ADVANTAGES,
  PAY_FOR_CONTRACT,
  PAYFOR_DEPOSIT,
  PAY_FOR_COURT_FEE,
  REWARD_TYPES,
  CRM_BALANCE_TRANSACTION_TYPES,
  CRM_BALANCE_TRANSACTION_TYPE_TRANSLATIONS,
  REWARDS_AMOUNT,
  CRM_BALANCE_TRANSACTIONS_WITH_TALANTS,
  VOTING_CONTRACT_RESULT,
  VOTING_CLIENT_RESULT,
};
